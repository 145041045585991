import React from 'react'
import PropTypes from 'prop-types'

import {
  Grid, Button, Text, SubmitButton, Stack, IconList, Input,
  useSearchState,
} from '@level'

const SignUpDialog = ({
  onCancel,
  sent,
}) => {
  const [{ email: defaultEmail }] = useSearchState()

  return (
    <Grid columns={2} templateColumns="320px 1fr">
      <Stack gap={6} space={9} className="try-dialog-colored-panel">
        <div>
          <Button className="try-dialog-dismiss" onClick={onCancel} label="dismiss" icon={{ name: 'x-light', size: 4 }} theme="wrapper" />
        </div>
        <Text tag="h1">Ooh, nice template.</Text>
        <Text tag="p" size={3}>You can save this template and more to Enveloop for <b>free</b>. Plus, get access to even more features</Text>
        <IconList
          space={[6, 0]}
          items={[
            'Send Messages with a single API',
            'Reduce deployments by getting email code out of your app',
            'Empower others on your team (or even your clients) to handle communication',
            'Integrate with leading providers including SendGrid, MailGun, Twillio, and more',
          ]}
        />
      </Stack>
      <Stack space={9} gap={8} valign="center">
        <Stack gap={6}>
          { process.env.GOOGLE_OAUTH_URL
            ? (
              <Button
                icon="google"
                to={process.env.GOOGLE_OAUTH_URL}
                text="Sign In With Google"
                type="subtle"
              />
            ) : null}
          { process.env.GITHUB_OAUTH_URL
            ? (
              <Button
                icon="github"
                to={process.env.GITHUB_OAUTH_URL}
                text="Sign In With Github"
                type="subtle"
              />
            ) : null}
          { process.env.APPLE_OAUTH_URL
            ? (
              <Button
                icon="apple"
                to={process.env.APPLE_OAUTH_URL}
                text="Sign In With Apple"
                type="subtle"
              />
            ) : null}
        </Stack>
        <div>
          <div className="or-divider">or</div>
        </div>
        { sent ? (
          <div>Email sent to {sent}</div>
        ) : (
          <Stack gap={5}>
            <Input name="email" placeholder="Email Address" defaultValue={defaultEmail} label={false} />
            <SubmitButton text="Send a secure link" grow />
          </Stack>
        )}
      </Stack>
    </Grid>
  )
}

SignUpDialog.propTypes = {
  onCancel: PropTypes.func.isRequired,
  sent: PropTypes.string,
}

SignUpDialog.defaultProps = {
  sent: undefined,
}

export {
  SignUpDialog,
}
