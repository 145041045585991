import PropTypes from 'prop-types'
import { colors } from '../constants/allColors'

const colorModels = {
  hsl: ['hue', 'saturation', 'luminosity', 'alpha'],
  hwb: ['hue', 'whiteness', 'blackness', 'alpha'],
  rgb: ['red', 'green', 'blue', 'alpha'],
}

const allColorParts = Object.values(colorModels).flat().reduce((acc, item) => (acc.indexOf(item) < 0 ? [...acc, item] : acc), [])

const toString = {
  hwb: ({
    hue, whiteness, blackness, alpha,
  }) => {
    const main = `${hue} ${whiteness}% ${blackness}%`
    return alpha < 1 ? `hwb(${main} / ${alpha})` : `hwb(${main})`
  },
  hsl: ({
    hue, saturation, luminosity, alpha,
  }) => {
    const main = `${hue},${saturation}%,${luminosity}%`
    return alpha < 1 ? `hsla(${main},${alpha})` : `hsl(${main})`
  },
  rgb: ({
    red, green, blue, alpha,
  }) => {
    const main = [red, green, blue].join(',')
    return alpha < 1 ? `rgba(${main},${alpha})` : `rgb(${main})`
  },
  hex: ({ hex }) => hex,
}

const setRoot = (prop, value) => document.documentElement.style.setProperty(`--picker-${prop}`, value)
const getRoot = (prop) => document.documentElement.style.getPropertyValue(`--picker-${prop}`)

// Matches hex, rgba? hsla? hwb and really any xxxa?(xxx xxx xxx xxx) format. Optional alpha capture
const ColorTest = (type) => {
  if (type === 'hex') {
    // Matches 3, 4, 6, and 8 character hex codes (4, 8 have alpha channels)
    return /^#(\b(?:[0-9A-Fa-f]{3}|[0-9A-Fa-f]{4}|[0-9A-Fa-f]{6}|[0-9A-Fa-f]{8})\b)$/
  }
  const num = '([\\d.]+)' // number characters with decimals
  const percent = '([\\d.]+%)' // number characters with decimals
  const sep = '\\s*(?:,|\\s)\\s*' // non number characters
  const alph = '\\d\\.{0,1}\\d*' // number decimal number
  const alphsep = '\\s*(?:,|/)\\s*'
  const main = {
    rgb: [num, num, num],
    hsl: [num, percent, percent],
    hwb: [num, percent, percent],
  }[type]
  return new RegExp(`^${type}a?\\(${main.join(sep)}(?:${alphsep}(${alph}))?\\)$`)
}

const colorPatterns = {
  hsl: ColorTest('hsl'),
  hwb: ColorTest('hwb'),
  rgb: ColorTest('rgb'),
  hex: ColorTest('hex'),
}

const isColor = {
  hex: (str) => (str && str.match(ColorTest('hex'))?.slice(1)) || null,
  hsl: (str) => (str && str.match(ColorTest('hsl'))?.slice(1)) || null,
  hwb: (str) => (str && str.match(ColorTest('hwb'))?.slice(1)) || null,
  rgb: (str) => (str && str.match(ColorTest('rgb'))?.slice(1)) || null,
}

const colorArray = (color, model = color.slice(0, 3)) => {
  const is = isColor[model](color)
  return is?.map((n) => ((typeof n === 'undefined') ? 1 : Number.parseFloat(n, 10)))
}

const colorParts = (color, model = color?.slice(0, 3)) => {
  if (!color) return null
  if (model === 'hex' || color.startsWith('#')) return { hex: color }
  const arr = colorArray(color, model)
  return colorModels[model].reduce((acc, part, index) => ({ ...acc, [part]: arr[index] }), {})
}

const rgbaToHex = ({
  red, green, blue, alpha,
}) => {
  const rgb = [red, green, blue]
  if (typeof alpha !== 'undefined' && alpha < 1) rgb.push(alpha)
  return `#${rgb.map(
    (n, i) => (i === 3 ? Math.round(parseFloat(n) * 255) : parseFloat(n)).toString(16).padStart(2, '0').replace('NaN', ''),
  ).join('')}`
}

const toHslvwb = ({
  red, green, blue, alpha, ...rest
}) => {
  const r = red / 255
  const g = green / 255
  const b = blue / 255

  const max = Math.max(r, g, b)
  const min = Math.min(r, g, b)

  // Calculate Value (V) - the maximum of R, G, B
  let v = max
  const diff = max - min
  let h; let w; let wb; let s
  let l = (max + min) / 2
  // Calculate Saturation (S)
  let ss = max === 0 ? 0 : (max - min) / max

  if (max === min) {
    h = 0
    s = 0 // achromatic
    w = max
    wb = 1 - max
  } else {
    w = min
    wb = 1 - max
    s = l > 0.5 ? diff / (2 - max - min) : diff / (max + min)

    if (max === r) {
      h = (g - b) / diff + (g < b ? 6 : 0)
    } else if (max === g) {
      h = (b - r) / diff + 2
    } else if (max === b) {
      h = (r - g) / diff + 4
    }

    h /= 6
  }

  h = Math.round(h * 360)
  s = Math.round(s * 1000) / 10
  ss = Math.round(ss * 1000) / 10
  l = Math.round(l * 1000) / 10
  w = Math.round(w * 1000) / 10
  wb = Math.round(wb * 1000) / 10
  v = 100 - Math.round(v * 1000) / 10

  const obj = {
    hue: h,
    saturation: s,
    hsvSaturation: ss,
    luminosity: l,
    value: v,
    whiteness: w,
    blackness: wb,
    alpha,
  }

  const hsl = toString.hsl(obj)
  const hwb = toString.hwb(obj)

  return {
    ...rest, hsl, hwb, ...obj,
  }
}

const toRgb = (str) => {
  try {
    let color = str
    if (!isColor.rgb(str)) {
      const el = document.createElement('div')
      el.style.color = str
      color = window.getComputedStyle(document.body.appendChild(el)).color
      document.body.removeChild(el)
    }
    const rgba = colorParts(color)
    const rgb = toString.rgb(rgba)
    const hex = rgbaToHex(rgba)

    return { ...rgba, rgb, hex }
  } catch (e) {
    throw new Error(`Invalid Color Error: Browser does not recognize \`${str}\` as a valid color.`)
  }
}

const hexToHslvwb = (color) => toHslvwb(toRgb(color))

const colorModel = (str) => str && Object.keys(isColor).find((type) => isColor[type](str))

const validColor = (str) => typeof str === 'string' && !!colorModel(str)

const toColor = (props) => {
  if (!props) return null
  if (typeof props !== 'object') return colors[props]

  return Object.keys(props).reduce((obj, key) => (
    { ...obj, [key]: colors[props[key]] }), {})
}

toColor.propTypes = {
  color: PropTypes.oneOf(['inherit', ...Object.keys(colors)]),
}

const Color = (color, modelProp) => {
  const setColor = (c, { model = c.model, ...adj }) => {
    // Setting individual attributes of a color
    const str = toString[model]({ ...c, ...adj })
    return Color(str, model)
  }
  const model = modelProp || colorModel(color)
  if (!model) return null

  const rgb = toRgb(color)
  const hslvwb = toHslvwb(rgb)
  const current = colorParts(color, model === 'hex' ? 'rgb' : model)
  const colorObj = {
    model,
    ...hslvwb,
    ...rgb,
    // ensure that conversions don't override initial model with rounding errors
    ...current,
    [model]: color,
  }

  return ({
    ...colorObj,
    set: (args) => setColor(colorObj, args),
    setAlpha: (alpha) => setColor(colorObj, { alpha, model: 'rgb' }),
    lighten: (val) => setColor(colorObj, { luminosity: colorObj.luminosity + val, model: 'hsl' }),
    darken: (val) => setColor(colorObj, { luminosity: colorObj.luminosity - val, model: 'hsl' }),
    toString: (m) => (m
      ? toString[model](color)
      : colorObj[colorObj.model]),
  })
}

const propValidBetween = (min, max) => (props, propName, componentName) => {
  if (props[propName] < min || max < props[propName]) {
    return new Error(`Invalid Prop: "${propName}" supplied to ${componentName} : must be between 0 and 360, was ${props[propName]}`)
  }
  return true
}

Color.propTypes = {
  hue: propValidBetween(0, 360),
  sat: propValidBetween(0, 100),
  lum: propValidBetween(0, 100),
  alpha: propValidBetween(0, 1),
  red: propValidBetween(0, 255),
  green: propValidBetween(0, 255),
  blue: propValidBetween(0, 255),
  hex: ({ hex }, propName, componentName) => {
    if (!isColor.hex(hex)) return new Error(`Invalid Prop: "hex" supplied to ${componentName} : ${hex} is not a valid hex color`)
    return true
  },
  str: ({ str }, propName, componentName) => {
    if (!validColor(str)) return new Error(`Invalid Prop: "str" supplied to ${componentName} : ${str} is not a valid color`)
    return true
  },
}

const getBrowserColor = (str) => {
  try {
    const el = document.createElement('div')
    el.style.color = str
    const { color } = window.getComputedStyle(document.body.appendChild(el))
    document.body.removeChild(el)
    return color
  } catch (e) {
    return null
  }
}

// Expects a hex color
const getContrastFromHex = (color, options = {}) => {
  const { dark = '#000000', light = '#FFFFFF' } = options
  let hexColor = color.replace('#', '')
  if (hexColor.length === 3) {
    hexColor = hexColor.split('')
      .map((c) => c + c).join('')
  }
  const r = parseInt(hexColor.substr(0, 2), 16)
  const g = parseInt(hexColor.substr(2, 2), 16)
  const b = parseInt(hexColor.substr(4, 2), 16)
  const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000
  return (yiq >= 128) ? dark : light
}

const getContrast = (colorStr, options) => {
  const color = toColor(colorStr) || colorStr
  if (isColor.hex(color)) {
    return getContrastFromHex(color, options)
  }
  const hex = Color(color)?.hex
  return hex ? getContrastFromHex(hex, options) : null
}

const isGrayscale = (color) => (
  color.saturation === 0
  || color.luminosity === 0
  || color.luminosity === 100
)

const sortGrayscale = (a, b) => {
  if (!isGrayscale(a) && isGrayscale(b)) return -1
  if (isGrayscale(a) && !isGrayscale(b)) return 1
  return b.luminosity - a.luminosity
}

// Sort primarily by hue, then luminosity, then saturation
const sortByColor = (a, b) => {
  if (isGrayscale(b) || isGrayscale(a)) return sortGrayscale(a, b)
  if (a.hue !== b.hue) return a.hue - b.hue
  if (a.luminosity !== b.luminosity) return a.luminosity - b.luminosity
  return a.saturation - b.saturation
}

const sortByHex = (a, b) => (
  sortByColor(hexToHslvwb(a), hexToHslvwb(b))
)

const sortColors = (colorArr = []) => {
  if (colorArr.length === 1) return colorArr.map((v) => v.value || v)
  const c = colorArr.map((v) => hexToHslvwb(v?.value || v))
  return c.sort(sortByColor).map(({ hex }) => hex)
}

const validateColor = ({ color, elementRef }) => {
  // Set a litmus that id different from the color
  const litmus = color === 'red' ? 'blue' : 'red'
  let element = elementRef?.current
  if (!element) {
    element = document.createElement('span')
    document.body.append(element)
  }
  element.style.color = litmus
  element.style.color = color

  // Element's style.color will be reverted to litmus or set to '' if an invalid color is given
  const valid = (
    element.style.color !== litmus
    && element.style.color !== ''
    && window.getComputedStyle(element).color
  )
  // If a ref wasn't passed, remove the created element
  if (!elementRef?.current) element.remove()
  return valid
}

export {
  getContrast,
  Color,
  toColor,
  getBrowserColor,
  validateColor,
  validColor,
  sortColors,
  sortByColor,
  sortByHex,
  toRgb,
  toHslvwb,
  hexToHslvwb,
  isColor,
}
