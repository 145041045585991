import React from 'react'
import PropTypes from 'prop-types'

const TemplateContext = React.createContext({})

const useTemplate = () => React.useContext(TemplateContext)

const isActive = (state) => (props = {}) => {
  const { sectionId, elementId } = props
  const { path } = state
  if (!path) return null

  if (elementId) return path?.elementId === elementId || null
  if (sectionId && path?.sectionId === sectionId) return true
  if (!sectionId && !elementId) return path

  return null
}

const TemplateProvider = ({
  mode = 'render',
  previewVars,
  state,
  config,
  hooks,
  type,
  children,
  ...rest
}) => {
  const value = React.useMemo(() => ({
    ...rest, mode, config, previewVars, state, hooks, type, isActive: isActive(state),
  }), [mode, config, previewVars, state, hooks, type])

  return (
    <TemplateContext.Provider value={value}>
      { children }
    </TemplateContext.Provider>
  )
}

TemplateProvider.propTypes = {
  mode: PropTypes.oneOf(['preview', 'preview-html', 'builder', 'section-builder', 'render']),
  config: PropTypes.shape({
    sections: PropTypes.array,
    variables: PropTypes.object,
    body: PropTypes.any,
  }).isRequired,
  state: PropTypes.object,
  hooks: PropTypes.object,
  type: PropTypes.string.isRequired,
  previewVars: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.node.isRequired,
}

TemplateProvider.defaultProps = {
  previewVars: undefined,
  mode: 'preview',
  state: {},
  hooks: {},
}

export {
  useTemplate,
  TemplateProvider,
}
