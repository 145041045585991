module.exports = {
  badgeSizes: {
    0: 20,
    1: 22,
    2: 24,
    3: 32,
    4: 44,
    5: 64,
    6: 88,
    7: 128,
  },
}
