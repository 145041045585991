import { Text } from './Text'
import { Button } from './Button'
import { Image } from './Image'
import { Heading } from './Heading'
import { Divider } from './Divider'
import { Table } from './Table'

const Elements = {
  Text,
  Image,
  Button,
  Heading,
  Divider,
  Table,
}

export {
  Text,
  Image,
  Button,
  Heading,
  Divider,
  Table,
}

export default Elements
