import React from 'react'
import PropTypes from 'prop-types'

import { FileDrop, DropCard, ImageFilePreview } from '../FileDrop'
import { Button } from '../Button'
import { useFormRef } from '../../hooks/useForm'

import './image-drop-input.scss'

const ImageDropInput = ({ name, src, onChange }) => {
  const { register, resetField } = useFormRef()
  const [file, setFile] = React.useState()

  const onChooseFile = ({ target: { files } }) => {
    if (files.length) {
      setFile(files[0])
      onChange(files[0])
    }
  }

  const onDrop = ({ data }) => {
    if (data.length) {
      setFile(data[0])
      onChange(data[0])
    }
  }

  React.useEffect(() => {
    if (src && file) setFile(null)
  }, [src])

  const clear = () => {
    resetField('dropfile')
    setFile(null)
    onChange(null)
  }

  return (
    <div className="level-image-drop-input">
      <FileDrop type="image" onDrop={onDrop}>
        { file || src ? <Button className="level-image-drop-input-clear" icon="x-circle" size={5} theme="ghost" label="remove" onClick={clear} /> : null }
        <label htmlFor={`${name}-dropfile`} title="browse for an image">
          <input className="visually-hidden" id={`${name}-dropfile`} type="file" name="dropfile" {...register('dropfile', { onChange: onChooseFile })} />
          <DropCard>
            { src ? <ImageFilePreview src={src} file={file} /> : null}
          </DropCard>
        </label>
      </FileDrop>
    </div>
  )
}

ImageDropInput.propTypes = {
  name: PropTypes.string.isRequired,
  src: PropTypes.string,
  onChange: PropTypes.func,
}

ImageDropInput.defaultProps = {
  src: undefined,
  onChange: () => {},
}

export {
  ImageDropInput,
}
