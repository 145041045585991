import React from 'react'
import PropTypes from 'prop-types'

import { SelectMenu } from '../../../../Select'
import { Text } from '../../../../Text'
import { fontFamilies } from '../defaults'
import { titleize } from '../../../../../helpers/text'

const options = Object.entries(fontFamilies).map(([name, val]) => (
  {
    label: <Text style={{ fontFamily: val }}>{ titleize(name) }</Text>,
    value: name,
  }
))

const FontFamilyInput = ({ value, ...props }) => (
  <SelectMenu {...props} options={options} defaultValue={value} />
)

FontFamilyInput.propTypes = {
  value: PropTypes.string,
}

FontFamilyInput.defaultProps = {
  value: null,
}

export {
  FontFamilyInput,
}
