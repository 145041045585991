import React from 'react'
import PropTypes from 'prop-types'

import { DialogOverlay, DialogContent as ReachDialogContent } from '@reach/dialog'
import '@reach/dialog/styles.css'

import { useMessages } from '../../hooks/useMessages'
import { Block } from '../Block'
import { SubmitButton, Button } from '../Button'
import { ButtonShelf } from '../ButtonShelf'
import { Stack } from '../Stack'
import { Divider } from '../Divider'
import { Text } from '../Text'
import { Form } from '../Form'
import { Transition } from '../Transition'

import './dialog.scss'

const getDialogContentHeight = ({ contentRef, rootRef, footerRef }) => {
  const dialogEl = rootRef.current
  const contentEl = contentRef.current
  const footerEl = footerRef.current

  const { top: rootTop } = dialogEl.getBoundingClientRect()
  const { top: contentTop } = contentEl.getBoundingClientRect()
  const footerHeight = footerEl ? footerEl.getBoundingClientRect().height : 0
  const maxSize = `calc(80vh - (${contentTop}px - ${rootTop}px) - ${footerHeight}px)`
  return maxSize
}

const DialogActions = ({
  destructive,
  onCancel,
  onConfirm,
  confirmText,
  focusRef,
  cancelText,
}) => {
  const hasConfirm = !!onConfirm
  const confirmButton = (
    <SubmitButton
      theme={destructive ? 'primary-destructive' : 'primary'}
      text={confirmText}
      ref={focusRef}
    />
  )
  return (
    <ButtonShelf>
      { hasConfirm ? confirmButton : null }
      <Button
        theme={hasConfirm ? 'default' : 'primary'}
        text={cancelText}
        onClick={onCancel}
        ref={!hasConfirm ? focusRef : null}
      />
    </ButtonShelf>
  )
}

DialogActions.propTypes = {
  onCancel: PropTypes.func.isRequired,
  focusRef: PropTypes.object,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  onConfirm: PropTypes.func,
  destructive: PropTypes.bool,
}

DialogActions.defaultProps = {
  confirmText: 'Confirm',
  cancelText: undefined,
  onConfirm: undefined,
  focusRef: undefined,
  destructive: false,
}

const DialogContent = ({
  title,
  space,
  dialogContainerRef,
  actions,
  ...props
}) => {
  const content = (typeof props.content === 'function') ? <props.content /> : props.content
  const footerRef = React.useRef()
  const contentRef = React.useRef()
  const DialogActionsEl = actions || DialogActions

  React.useEffect(() => {
    const rootEl = dialogContainerRef.current
    const setMaxHeight = () => {
      contentRef.current.style.maxHeight = getDialogContentHeight({
        rootRef: dialogContainerRef, contentRef, footerRef,
      })
    }
    setMaxHeight()
    rootEl?.addEventListener('transitionend', setMaxHeight)
    return () => {
      if (rootEl) {
        rootEl.removeEventListener('transitionend', setMaxHeight)
      }
    }
  }, [])

  return (
    <React.Fragment>
      { title ? <Text tag="h2" className="level-dialog-header">{ title }</Text> : null }
      { title ? <Divider /> : null }
      <div ref={contentRef} style={{ overflowY: 'auto' }}>
        { content ? (
          <Stack space={space} gap={5}>
            { content }
          </Stack>
        ) : null }
      </div>
      <div className="level-dialog-actions" ref={footerRef}>
        <DialogActionsEl {...props} />
      </div>
    </React.Fragment>
  )
}

DialogContent.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  width: PropTypes.string,
  space: Block.propTypes.space,
  dialogContainerRef: PropTypes.object.isRequired,
  actions: PropTypes.func,
}

DialogContent.defaultProps = {
  content: undefined,
  width: '500px',
  space: 8,
  actions: undefined,
}

const DialogBox = ({
  visible,
  onConfirm,
  onCancel,
  width,
  height,
  form,
  element,
  style,
  debounceSubmit,
  ...props
}) => {
  const focusRef = React.useRef()
  const onSubmit = ({ data, ...rest }) => (
    onConfirm({ data: { ...form.data, ...data }, hooks: form.hooks, ...rest })
  )
  const dialogContainerRef = React.useRef()
  const DialogContentEl = element || DialogContent
  return (
    <Transition in={visible} timeout={0}>
      <DialogOverlay
        onDismiss={onCancel}
        initialFocusRef={focusRef}
        className="level-dialog-overlay"
      >
        <ReachDialogContent
          className="level-dialog"
          style={{
            width,
            height,
            maxWidth: '90vw',
            // This is important as it lets us compute the maxHeight of the content
            maxHeight: '80vh',
            ...style,
          }}
          aria-label="confirmation dialog"
          ref={dialogContainerRef}
        >
          <Form focus="first" {...form} onSubmit={onSubmit} debounceSubmit={debounceSubmit}>
            <DialogContentEl
              {...{
                ...props,
                cancelText: onConfirm ? 'Cancel' : 'Got it',
                dialogContainerRef,
                focusRef,
                onConfirm,
                onCancel,
              }}
            />
          </Form>
        </ReachDialogContent>
      </DialogOverlay>
    </Transition>
  )
}

DialogBox.propTypes = {
  visible: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
  form: PropTypes.shape({
    data: PropTypes.object,
    hooks: PropTypes.object,
    ...Form.propTypes,
  }),
  debounceSubmit: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  style: PropTypes.object,
  width: PropTypes.string,
  element: PropTypes.func,
  height: PropTypes.string,
}

DialogBox.defaultProps = {
  form: {},
  style: {},
  width: '500px',
  onConfirm: undefined,
  element: undefined,
  height: undefined,
  debounceSubmit: false,
}

const Dialog = () => {
  const { subscribe, clear } = useMessages({ channel: 'dialog' })
  const dialog = subscribe() || null
  const [visible, setVisible] = React.useState(false)

  const {
    onConfirm,
    onCancel,
    onClose,
    onNext,
  } = dialog || {}

  React.useEffect(() => {
    if (dialog) setVisible(true)
  }, [dialog])

  const close = (options = {}) => {
    const { timeout, ...props } = options
    return new Promise((resolve) => {
      setVisible(false)
      window.setTimeout(async () => {
        clear()
        if (typeof onClose === 'function') await onClose(props)
        resolve()
      }, timeout || 500)
    })
  }

  const cancel = async ({ timeout, ...props }) => {
    if (typeof onCancel === 'function') await onCancel(props)
    return close({ cancel: props, timeout })
  }

  const confirm = onConfirm || onNext ? async ({ timeout, ...props }) => {
    try {
      if (typeof onConfirm === 'function') await onConfirm(props)
      if (typeof onNext === 'function') {
        await onNext(props)
      } else {
        setVisible(false)
        close({ confirm: props, timeout })
      }
    } catch (e) { (() => null)() }
  } : null

  return dialog ? (
    <DialogBox
      {...dialog}
      visible={visible}
      onCancel={cancel}
      onConfirm={confirm}
      confirm={confirm}
      close={close}
    />
  ) : null
}

export {
  Dialog,
}
