import React from 'react'
import PropTypes from 'prop-types'

import { useDropZone } from '../../hooks/useDropZone'
import { useDrag, useDragAndDrop, DragAndDropProvider } from '../../hooks/useDrag'
import { changeArrayPosition } from '../../helpers'
import { Stack } from '../Stack'

import './draglist.scss'

const DragItem = ({ position, listId, children }) => {
  const { setDragData } = useDragAndDrop()
  const { ref: dragRef, dragging } = useDrag({ data: { from: position, fromListId: listId } })

  const onDrop = React.useCallback(({ data }) => {
    const dropData = JSON.parse(data)
    // Delay execution to allow onDragEnd to trigger before updating item order
    setTimeout(() => setDragData({
      to: position, toListId: listId, ...dropData,
    }), 0)
  }, [position])

  const { ref: dropZoneRef, dragOver } = useDropZone({ onDrop })

  return (
    <div
      className="level-drag-container"
      data-dragging-over={dragOver}
      data-dragging={dragging}
      ref={dropZoneRef}
    >
      <div className="level-drag-effect" />
      <div
        className="level-drag-item"
        ref={dragRef}
      >
        { children }
      </div>
    </div>
  )
}

DragItem.propTypes = {
  position: PropTypes.number.isRequired,
  listId: PropTypes.any,
  children: PropTypes.node.isRequired,
}

DragItem.defaultProps = {
  listId: undefined,
}

const orderChildren = ({ children, from, to }) => (
  changeArrayPosition({
    array: React.Children.toArray(children),
    from,
    to,
  })
)

const DragListStack = ({
  onChange, children, className, theme, id, ...rest
}) => {
  const { dragData, setDragData } = useDragAndDrop()
  const [orderedChildren, setOrderedChildren] = React.useState(React.Children.toArray(children))

  React.useEffect(() => {
    if (dragData) {
      const {
        to, from, toListId, fromListId,
      } = dragData

      // Only do things if this is the list that is dropped on
      if (toListId === id) {
        const sameList = toListId === fromListId

        // Allow same index from different lists
        if ((sameList && to !== from) || !sameList) {
          if (onChange) onChange(dragData)
          // Don't do reordering if same indexes
          // Also if from different lists, we can't simply swap children
          if (sameList && to !== from) {
            setOrderedChildren((kids) => orderChildren({ children: kids, from, to }))
          }
          // Reset on drop
          setDragData(null)
        }
      }
    }
  }, [dragData, onChange])

  // Currently we're memoizing children to orderedChildren.
  // Let's be sure we let children changes happen.
  React.useEffect(() => {
    setOrderedChildren(React.Children.toArray(children))
  }, [children])

  return orderedChildren?.length ? (
    <Stack
      className={`level-drag-list ${className || ''}`}
      data-theme={theme}
      data-drag-list-id={id}
      {...rest}
    >
      { orderedChildren.map((child, index) => (
        <DragItem position={index} listId={id} key={child.props.id || index}>{child}</DragItem>
      ))}
    </Stack>
  ) : null
}

DragListStack.propTypes = {
  onChange: PropTypes.func,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  theme: PropTypes.string,
  id: PropTypes.any,
}

DragListStack.defaultProps = {
  onChange: undefined,
  className: undefined,
  theme: undefined,
  id: undefined,
}

const DragList = (props) => {
  const nested = useDragAndDrop()

  if (nested) return <DragListStack {...props} />
  return (
    <DragAndDropProvider><DragListStack {...props} /></DragAndDropProvider>
  )
}

export {
  DragList,
}
