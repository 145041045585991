import React from 'react'

import {
  Input, Button,
  useFormRef,
} from '@level'

import { useRandomScramble, useRandomSlug } from '@app/hooks'

const RandomInput = ({
  name, ...rest
}) => {
  const { setValue } = useFormRef()
  const random = useRandomSlug()

  useRandomScramble({
    word: random.name,
    setWord: (w) => {
      setValue(name, w, { shouldDirty: true })
    },
  })

  return (
    <div className="level-random-input" style={{ position: 'relative' }}>
      <Input name={name} {...rest} />
      <Button
        style={{ position: 'absolute', top: '1.9em', right: '0px' }}
        icon={{ name: 'arrow-rotate-left', color: 'neutral-300' }}
        label="generate another"
        theme="ghost"
        onClick={() => random.generate()}
      />
    </div>
  )
}

RandomInput.propTypes = Input.propTypes
RandomInput.defaultProps = Input.defaultProps

export {
  RandomInput,
}
