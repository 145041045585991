import React from 'react'
import PropTypes from 'prop-types'
import {
  Notice, Input, Select, sentenceJoin,
  allTruthy, capitalize, useFormRef,
} from '@level'

import {
  useVerifyProvider,
  allowedFromSmsOptions,
} from '@app/hooks'

const configValues = (config) => (
  Object.keys(config || {}).filter(
    (k) => k !== 'defaultFrom',
  ).map((k) => config[k])
)

const allowedFromEmailDescription = ({ provider, allowedFrom, defaultFrom }) => {
  const { domains, emails } = allowedFrom || {}

  const allowed = []
  let description = null

  if (domains?.length) allowed.push(sentenceJoin(domains.map((d) => `*@${d}`)))
  if (emails?.length) allowed.push(sentenceJoin(emails))

  if (allowed.length) {
    description = `${provider} accepts: ${sentenceJoin(allowed, 'as well as')}`
    if (defaultFrom) description = description.replace(defaultFrom, `${defaultFrom} (default)`)
  }

  return description
}

const SmsDefaultFromInput = ({
  name, allowedFrom, defaultFrom, valid, configured, isLoading,
}) => {
  const { numbers } = allowedFrom || {}

  const options = React.useMemo(() => (
    allowedFromSmsOptions({ allowedFrom, defaultFrom, isLoading })
      || [{ label: '+1 XXX XXX-XXXX', value: defaultFrom || '' }]
  ), [valid, numbers?.length, isLoading])

  if (valid && !numbers?.length) {
    return (
      <Notice icon="exclamation-triangle" space={4}>Configure a Phone number with your SMS Provider </Notice>
    )
  }

  return (
    <Select
      name={name}
      label="Default From Number"
      options={options}
      disabled={!valid || !configured}
    />
  )
}

SmsDefaultFromInput.propTypes = {
  name: PropTypes.string.isRequired,
  defaultFrom: PropTypes.string,
  allowedFrom: PropTypes.object,
  valid: PropTypes.bool,
  configured: PropTypes.bool,
  isLoading: PropTypes.bool,
}

SmsDefaultFromInput.defaultProps = {
  defaultFrom: undefined,
  allowedFrom: undefined,
  valid: undefined,
  configured: undefined,
  isLoading: undefined,
}

const EmailDefaultFromInput = ({
  name, defaultFrom, allowedFrom, valid,
}) => (
  <Input
    type="text"
    placeholder="Mailman <mailman@myapp.com>"
    label="Default From Email"
    descriptionAfter={valid ? allowedFromEmailDescription({ allowedFrom, defaultFrom }) : null}
    name={name}
  />
)

EmailDefaultFromInput.propTypes = {
  name: PropTypes.string.isRequired,
  defaultFrom: PropTypes.string,
  allowedFrom: PropTypes.object,
  valid: PropTypes.bool,
}

EmailDefaultFromInput.defaultProps = {
  defaultFrom: undefined,
  allowedFrom: undefined,
  valid: undefined,
}

// Watch provider form updating provider name and config with changes
const useFormConfig = ({ type }) => {
  const [formConfig, setFormConfig] = React.useState({})
  const formRef = useFormRef()
  const { watch, getValues } = formRef
  const root = capitalize(type)

  // Grab initial provider config state
  React.useEffect(() => {
    // timeout allows form defualts to register before checking
    setTimeout(() => {
      const [provider, config] = getValues([`${root}.provider`, `${root}.config`])
      setFormConfig({ provider, config })
    }, 0)
  }, [])

  // Extract provider and config from form changes,
  // Default to initial values to prevent nulls if watch hasn't fired
  // Why not update state with watch? It adds extra unnecessary rerenders
  const [
    provider = formConfig.provider,
    config = formConfig.config,
  ] = watch({ name: [`${root}.provider`, `${root}.config`] })

  return { provider, config }
}

const ProviderDefaultFromInput = ({ type }) => {
  const { provider, config } = useFormConfig({ type })
  const {
    debouncedVerify, valid, allowedFrom, isLoading,
  } = useVerifyProvider()
  const configs = configValues(config)
  const formValues = configs.join('-')
  const configured = allTruthy([provider, type, ...configs])

  const FromInput = {
    smsProvider: SmsDefaultFromInput,
    mailProvider: EmailDefaultFromInput,
  }[type]

  React.useEffect(() => {
    if (configured && config) {
      debouncedVerify({ type, provider, config })
    }
  }, [provider, configured, formValues])

  // Wait for form to be ready
  if (!config) return null

  if (FromInput) {
    return (
      <FromInput
        name={`${capitalize(type)}.config.defaultFrom`}
        defaultFrom={config?.defaultFrom}
        allowedFrom={allowedFrom}
        valid={valid}
        configured={allTruthy([provider, type, ...configs])}
        isLoading={isLoading}
      />
    )
  }

  return null
}

ProviderDefaultFromInput.propTypes = {
  type: PropTypes.string.isRequired,
}

export {
  ProviderDefaultFromInput,
}
