import React from 'react'
import PropTypes from 'prop-types'

import {
  Stack, Text, Block, Loading, Button,
  Grid, sortBy, titleize,
} from '@level'

import { MessageIcon } from '../icon'
import { formatMessageDate } from './helpers'

const MessageModeButton = ({
  name,
  meta,
  active,
  onClick,
}) => {
  const icon = name === 'preview'
    ? <MessageIcon name="table-layout" />
    : <MessageIcon name={name} />
  return (
    <Button
      className="message-mode-button"
      data-theme="menu"
      data-active={active || null}
      onClick={onClick}
    >
      <Grid gap={4} templateColumns="auto 1fr auto" valign="center">
        {icon}
        <Stack style={{ textAlign: 'left' }} gap={2}>
          <Text size={2}>{titleize(name)}</Text>
          <Text size={0} color="neutral-200">{ meta }</Text>
        </Stack>
        <MessageIcon name="angle-right" color="neutral-100" />
      </Grid>
    </Button>
  )
}

MessageModeButton.propTypes = {
  name: PropTypes.string.isRequired,
  meta: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
}

const MessageSidebar = ({
  message,
  mode,
  setMode,
  tableMessage,
}) => (
  <Stack gap={6} space={6} className="message-sidebar">
    <MessageModeButton
      name="preview"
      active={mode === 'preview'}
      meta={tableMessage.template?.slug || 'message body'}
      onClick={() => setMode('preview')}
    />
    <Block space={[0, 4]}><Text tag="h4">Timeline</Text></Block>
    { message?.computedMessageEvents ? message.computedMessageEvents.sort(sortBy('at')).map(({ event, at, id }) => (
      <MessageModeButton
        key={id}
        active={mode === id}
        onClick={() => setMode(id)}
        name={event}
        meta={formatMessageDate(at)}
      />
    )) : <div className="message-preview-loading"><Loading size={5} /></div> }
  </Stack>
)

MessageSidebar.propTypes = {
  message: PropTypes.shape({
    computedMessageEvents: PropTypes.array,
  }),
  tableMessage: PropTypes.shape({
    template: PropTypes.shape({
      slug: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  mode: PropTypes.string.isRequired,
  setMode: PropTypes.func.isRequired,
}
MessageSidebar.defaultProps = {
  message: undefined,
}

export {
  MessageSidebar,
}
