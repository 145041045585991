import React from 'react'
import PropTypes from 'prop-types'

import { useProfile, useTeam } from '@app/hooks'
import {
  Loading, SelectNav, Avatar, Shelf, IconBadge, Text,
} from '@level'

import { useNewTeamDialog } from './new'

const TeamBadge = ({ team: { name, avatar }, avatarSize, textProps }) => (
  <Shelf valign="center" gap={4} nowrap>
    <Avatar size={avatarSize} name={name} src={avatar?.url} email={name} gradient />
    <Text {...textProps}>{name}</Text>
  </Shelf>
)

TeamBadge.propTypes = {
  team: PropTypes.object.isRequired,
  avatarSize: PropTypes.number,
  textProps: PropTypes.object,
}

TeamBadge.defaultProps = {
  avatarSize: 2,
  textProps: {},
}

const TeamSwitcher = () => {
  const { Teams } = useProfile()
  const { Team: currentTeam } = useTeam()
  const newTeamDialog = useNewTeamDialog()
  const items = React.useMemo(() => (
    Teams.map((Team) => ({
      matchParam: Team.slug,
      to: `/teams/${Team.slug}/`,
      text: Team.name,
      children: <TeamBadge team={Team} />,
    })).concat({
      onSelect: newTeamDialog,
      text: 'Add a Team',
      divider: true,
      children: (
        <Shelf valign="center" gap={4}>
          <IconBadge name="plus" fill="blue-50" color="blue-400" />
          Add a Team
        </Shelf>
      ),
    })
  ), [Teams, currentTeam?.slug])

  return Teams ? (
    <SelectNav
      items={items}
      param="teamId"
      noneSelected="Select a Team"
    />
  ) : <Loading />
}

export {
  TeamSwitcher,
  TeamBadge,
}
