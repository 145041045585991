import React from 'react'
import { RadioPanel } from '../../../../RadioPanel'

const columnOptions = [
  { title: 'One Column', value: '100%', icon: 'column-one' },
  { title: 'Two Columns', value: '50%:50%', icon: 'column-two' },
  { title: 'Two Columns, 1:2', value: '33%:67%', icon: 'column-two-left' },
  { title: 'Two Columns, 2:1', value: '67%:33%', icon: 'column-two-right' },
]

const ColumnLayoutInput = ({
  ...rest
}) => (
  <RadioPanel
    items={columnOptions}
    {...rest}
  />
)

export {
  ColumnLayoutInput,
}
