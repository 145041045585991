import React from 'react'
import PropTypes from 'prop-types'

import { Button } from '../Button'
import { PopoverButton } from '../Popover'

const PopoverPill = ({
  onClear,
  content,
  param,
  value,
  ...rest
}) => (
  <div className="level-popover-pill" data-with-clear={onClear ? true : null}>
    <PopoverButton
      className="level-popover-pill-button"
      theme="wrapper"
      content={content}
      text={param ? (
        <div>
          <span className="level-popover-param">{param}</span>: {value}
        </div>
      ) : null}
      {...rest}
    />
    { onClear ? <Button className="clear-filter" theme="wrapper" icon={{ name: 'x-circle', color: 'neutral-300' }} onClick={onClear} label="clear" /> : null }
  </div>
)

PopoverPill.propTypes = {
  content: PropTypes.node.isRequired,
  onClear: PropTypes.func,
  param: PropTypes.string,
  value: PropTypes.string,
}

PopoverPill.defaultProps = {
  onClear: undefined,
  param: undefined,
  value: undefined,
}

export {
  PopoverPill,
}
