import React from 'react'
import PropTypes from 'prop-types'
import { iconNames } from '../Icon'
import { Text } from '../Text'
import { Stack } from '../Stack'

const IconList = ({
  listTag,
  className,
  items,
  icon,
  ...props
}) => (
  <Stack
    gap={5}
    space={0}
    tag={listTag}
    className={`${className} level-icon-list`}
    {...props}
    style={{
      margin: 0,
      listStyle: 'none',
    }}
  >
    { items.map((text) => (
      <Text as="li" valign="top" key={text} iconGap={5} icon={icon}>{ text }</Text>
    ))}
  </Stack>
)

IconList.propTypes = {
  listTag: PropTypes.string,
  items: PropTypes.array.isRequired,
  icon: PropTypes.oneOf(iconNames),
  className: PropTypes.string,
}

IconList.defaultProps = {
  listTag: 'ul',
  icon: 'check',
  className: '',
}

export {
  IconList,
}
