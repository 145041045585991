import React from 'react'
import PropTypes from 'prop-types'
import { spaceToSize } from '../../helpers'

const CardSection = ({
  space, style: styleProp, className, children,
}) => {
  const style = { ...styleProp, padding: spaceToSize(space) }
  return (
    <div className={`${className} level-card-section `} style={style}>{ children }</div>
  )
}

CardSection.propTypes = {
  style: PropTypes.object,
  space: spaceToSize.propTypes.space,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
}

CardSection.defaultProps = {
  space: 0,
  className: '',
  style: {},
}

export {
  CardSection,
}
