import React from 'react'
import Bugsnag from '@bugsnag/browser'
import BugsnagPluginReact from '@bugsnag/plugin-react'

if (process.env.BUGSNAG_API_KEY) {
  Bugsnag.start({
    apiKey: process.env.BUGSNAG_API_KEY,
    appVersion: process.env.GIT_VERSION,
    plugins: [new BugsnagPluginReact()],
    enabledReleaseStages: ['production', 'staging'],
    enabledBreadcrumbTypes: ['error'],
    releaseStage: process.env.NODE_ENV,
  })
}

const BugsnagContext = React.createContext(Bugsnag)
const useBugsnag = () => React.useContext(BugsnagContext)
const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React)

export {
  BugsnagContext,
  ErrorBoundary,
  useBugsnag,
  Bugsnag,
}
