import React from 'react'
import { Portal } from '@reach/portal'
import { Stack } from '../Stack'
import { useKeyUp, useToast } from '../../hooks'

import './toast.scss'

import { ToastMessage } from './Message'

const ToastIndex = () => {
  const [toasts, setToasts] = React.useState([])
  const { subscribe, clear } = useToast()
  const newToast = subscribe()

  // When escape is clicked, walk backwards through the toasts
  // and dismiss them one at a time
  useKeyUp({
    key: 'Escape',
    stopPropagation: !!toasts.length,
    onPress: () => {
      let count = 1
      setToasts((all) => all.reverse().map((t) => {
        if (!t.escaped && count) {
          count -= 1
          return { ...t, escaped: true }
        }
        return t
      }).reverse())
    },
  })

  React.useEffect(() => {
    if (newToast) {
      // Add latest toast to the rendering index
      setToasts((t) => [newToast].concat(t))
      // Pop the toast from the published list
      clear()
    }
  }, [newToast])

  // Match a toasts uuid to remove it from the index
  const remove = React.useCallback((uuid) => {
    setToasts((all) => all.filter((t) => t.uuid !== uuid))
  }, [])

  if (toasts.length === 0) return null

  return (
    <Portal>
      <Stack className="level-toast-index" gap={5}>
        { toasts.map(({ onDismiss, ...toast }, index) => (
          <ToastMessage
            key={toast.uuid}
            remove={() => {
              if (onDismiss) onDismiss()
              remove(toast.uuid)
            }}
            index={index}
            {...toast}
          />
        )) }
      </Stack>
    </Portal>
  )
}

export {
  ToastIndex,
  ToastMessage,
}
