import React from 'react'
import PropTypes from 'prop-types'

import { AsUserBanner } from '@app/hooks'
import {
  Form, SubmitButton, Layout, Stack,
} from '@level'

import { RandomInput } from '@app/components/RandomInput'
import { WelcomeCard } from './WelcomeCard'

const WelcomeTeam = ({ onSubmit, welcomeData }) => {
  const [teamName, setTeamName] = React.useState()

  return (
    <Layout
      root={<AsUserBanner />}
      className="level-layout-welcome"
    >
      <WelcomeCard
        title="Create Your First Team"
        description="A Team is a home for your company's projects and people. Invite others to join you and create additional teams as needed."
        teamName={teamName}
        welcomeData={welcomeData}
      >
        <Form
          onSubmit={onSubmit}
          onChange={(data) => setTeamName(data.name)}
        >
          <Stack gap={10}>
            <RandomInput name="name" placeholder="e.g. Pawnee Parks Dept." label="Team Name" required />
            <SubmitButton text="Next" grow />
          </Stack>
        </Form>
      </WelcomeCard>
    </Layout>
  )
}

WelcomeTeam.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  random: PropTypes.string,
  welcomeData: PropTypes.object.isRequired,
}

WelcomeTeam.defaultProps = {
  random: null,
}

export {
  WelcomeTeam,
}
