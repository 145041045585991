import React from 'react'
import PropTypes from 'prop-types'
import { Link } from '../../../Link'
import { MenuButton } from '../../../MenuButton'
import { Text } from '../../../Text'
import { Shelf } from '../../../Shelf'
import { Icon } from '../../../Icon'
import {
  EmailTable, jsxToString, renderMustache, uuid, previewThemeVariables,
} from '../../../../helpers'
import { useChannel } from '../../../../hooks/useChannel'
import { SectionColumns } from './Column'
import { isActive } from '../Element'
import { useTemplate } from '../../useTemplate'
import { useSortable, SortableItem, useDndContext } from '../../../Draggable'

const sectionPropTypes = {
  variables: PropTypes.shape({
    columns: PropTypes.array.isRequired,
    config: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
  mode: PropTypes.string,
  templateVariables: PropTypes.object,
  sharedSectionId: PropTypes.string,
}

const sectionVar = (props) => {
  const { config, mode, start } = props

  if (mode.match(/preview/)) {
    return start ? config.variableStart : config.variableEnd
  }
  if (mode.match(/render/)) {
    return `{{{ ${start ? 'config.variableStart' : 'config.variableEnd'} }}}`
  }

  return null
}

const SectionTag = ({
  sharedSectionId, variables, active,
}) => {
  const { hooks, mode } = useTemplate()
  const { setActivatorNodeRef, listeners } = useSortable({ id: variables.id })
  const menuActions = React.useMemo(() => {
    const actions = []

    if (mode === 'section-builder') {
      actions.push({
        text: 'Change Name',
        onSelect: () => hooks.renameSharedSection(),
        icon: { name: 'pen-field' },
      })
      actions.push({
        text: 'Delete',
        destructive: true,
        divider: true,
        onSelect: () => hooks.deleteSharedSection(),
        icon: { name: 'trash' },
      })
    } else {
      if (!sharedSectionId) {
        if (hooks.addSection) {
          actions.push({
            text: 'Duplicate',
            onSelect: () => hooks.addSection({ section: { variables } }),
            icon: { name: 'copy' },
          })
        }
        if (hooks.copySection) {
          actions.push({
            text: 'Copy',
            onSelect: () => hooks.copySection({ variables, sharedSectionId }),
            icon: { name: 'arrow-up-from-bracket' },
          })
        }
        if (hooks.convertToSharedSection) {
          actions.push({
            text: 'Create Shared Section',
            onSelect: () => hooks.convertToSharedSection({ variables }),
            icon: { name: 'star', color: 'yellow-500' },
          })
        }
      }
      if (hooks.confirmDeleteSection) {
        actions.push({
          text: 'Delete',
          destructive: true,
          divider: !sharedSectionId,
          onSelect: () => hooks.confirmDeleteSection({ section: { variables } }),
          icon: { name: 'trash' },
        })
      }
    }
    return actions
  }, [hooks.copySection, hooks.addSection, hooks.confirmDeleteSection])

  const sectionBuilder = mode.startsWith('section')
  const sectionText = (() => {
    if (sectionBuilder || sharedSectionId) return `Shared Section: ${variables.config.name}`
    return 'Section'
  })()

  return (
    <div className="template-link-tag">
      <Shelf valign="center" style={{ height: '100%' }}>
        <Shelf
          className="level-template-drag-button"
          gap={4}
          valign="center"
          ref={!sectionBuilder ? setActivatorNodeRef : undefined}
          {...(!sectionBuilder ? listeners : undefined)}
        >
          { !sectionBuilder ? <Icon name="arrows-up-down-left-right" /> : undefined }
          { active === 'section' ? (
            <Text>{sectionText}</Text>
          ) : null }
        </Shelf>
        { active === 'section' ? <MenuButton label="Section actions" items={menuActions} size={2} icon={{ color: 'white', name: 'three-dots-horizontal' }} theme="wrapper" /> : null }
      </Shelf>
    </div>
  )
}

// If a section does not have padding, add padding while drag is active
// This ensures that drag events will fire on sections
const useSectionDragPadding = ({ id, padding }) => {
  const { hooks: { dragging } = {} } = useTemplate()
  const draggingCurrent = !!dragging?.match(id)
  if (dragging?.match(/section/)
    && !draggingCurrent
    && padding === '0px 0px 0px 0px') { return '1px' }
  return padding
}

const SectionTd = ({
  mode, variables, sharedSectionId, active, children, dragging,
}) => {
  const empty = variables.columns.flatMap(({ elements }) => elements).length < 1
  const template = useTemplate()

  const _theme = {
    ...(variables._theme || {}),
    ...(template?.config?.variables._theme || {}),
  }

  let style = variables.config
  if (mode.match(/builder/) || mode.match(/preview/)) {
    style = previewThemeVariables({ style: variables.config, _theme })
  }
  const padding = useSectionDragPadding({ id: variables.id, padding: style.padding })

  return (
    <td
      className="enveloop-section"
      style={{ ...style, padding }}
      data-mode={mode !== 'render' ? mode : undefined}
      data-empty-section={empty || undefined}
      data-active={active}
      data-shared-section-id={sharedSectionId}
      data-dragging={dragging ? 'section' : undefined}
      data-drag-clone={mode.match(/clone-section/) || undefined}
    >
      { children }
    </td>
  )
}

const useDataSection = (props) => {
  const { previewVars } = useTemplate()
  const cloneRef = React.useRef(uuid())
  const ref = React.useRef()
  const { variables: { config } } = props
  const content = <SectionTd {...props} />
  // Use the DOM to inject mustache rendered elements after the current element ref
  React.useEffect(() => {
    if (ref.current) {
      const isSet = previewVars && !!previewVars[config.variableStart.replace(/[}{#]/g, '')]
      const cloneClass = `clone-${cloneRef.current}`
      // Remove any existing clones from a previous render
      document.querySelectorAll(`.${cloneClass}`).forEach((clone) => clone.remove())

      // Use the element ref as a DOM marker to render new content parsed with mustache vars
      ref.current.insertAdjacentHTML(
        'afterend',
        renderMustache(
          // Add a classname so we can find this later to remove
          `${isSet ? config.variableStart : ''}<tr class="${cloneClass}">${jsxToString(content)}</tr>${isSet ? config.variableEnd : ''}`,
          previewVars,
        ),
      )
    }
  }, [previewVars])
  return { ref }
}

const renderDataSection = (props) => {
  const { config, mode } = props
  return ['iterable', 'conditional'].includes(config.type)
    && (mode.match(/preview/) || mode.match(/render/))
}

const DataSection = (props) => {
  const { ref } = useDataSection(props)
  const { config, mode } = props

  if (mode.match(/preview/)) {
    return (<tr ref={ref} style={{ display: 'none' }} />)
  }

  if (mode.match(/render/)) {
    return (
      <>
        { sectionVar({ mode, config, start: true })}
        <tr><SectionTd {...props} /></tr>
        { sectionVar({ mode, config })}
      </>
    )
  }
  return undefined
}

DataSection.propTypes = {
  ...sectionPropTypes,
  children: PropTypes.node.isRequired,
}

// In builder mode, this wraps the section so you can navigate by clicking sections
const SectionWrapperLink = ({
  children,
  ...props
}) => {
  const { variables, sharedSectionId } = props
  const { id, config } = variables

  let active
  const activePath = isActive()
  const isSharedSection = props.mode === 'section-builder'
  const { isDragging } = useSortable({ id: variables.id })

  if (activePath?.sectionId === id || isSharedSection) {
    if (activePath?.elementId) active = 'element'
    else active = 'section'
  }

  return (
    <SectionTd {...props} active={active} dragging={isDragging}>
      { props.mode.startsWith('section') && active !== 'section' ? undefined : <SectionTag active={active} {...props} />}
      <Link
        to={`./section/${id}`}
        data-name={`${props.sharedSectionId ? 'Shared ' : ''}Section: ${config.name}`}
        data-active={active}
        data-shared-section-id={props.sharedSectionId || undefined}
        label={sharedSectionId ? 'Select Section' : 'Edit Section'}
        className="enveloop-section-link"
      />
      { children }
    </SectionTd>
  )
}

SectionWrapperLink.propTypes = {
  ...sectionPropTypes,
  children: PropTypes.node.isRequired,
}

const SortableSection = (props) => {
  const dndContext = useDndContext()
  // prevent elements from trying to drop into sections
  if (dndContext.active?.id?.match(/element/)) {
    return <tr><SectionWrapperLink {...props} /></tr>
  }
  return (
    <SortableItem as="tr" id={props.variables.id} handle>
      <SectionWrapperLink {...props} />
    </SortableItem>
  )
}

const SectionRow = (props) => {
  if (!props.mode.match(/builder/)) {
    return <tr><SectionTd {...props} /></tr>
  }
  if (props.mode === 'section-builder') {
    return <tr><SectionWrapperLink {...props} /></tr>
  }
  return <SortableSection {...props} />
}

// All sections are wrapped with this component
const Section = ({
  variables, mode, sharedSectionId, templateVariables,
}) => {
  const { columns } = variables
  const SectionWrapper = (renderDataSection({ mode, config: variables.config }))
    ? DataSection
    : SectionRow

  const columnProps = {
    mode,
    section: variables,
    sharedSectionId,
    templateVariables,
  }

  return (
    <SectionWrapper variables={variables} mode={mode} sharedSectionId={sharedSectionId}>
      <EmailTable>
        <tbody>
          <SectionColumns {...columnProps} columns={columns} />
        </tbody>
      </EmailTable>
    </SectionWrapper>
  )
}

Section.getMustacheTypes = ({ config }) => {
  const types = {
    iterable: [],
    conditional: [],
  }
  types[config.type]?.push(config.variableStart.replace(/[}{#]/g, ''))

  return types
}

Section.propTypes = sectionPropTypes
Section.defaultProps = {
  mode: undefined,
  templateVariables: undefined,
  sharedSectionId: undefined,
}

export {
  Section,
}
