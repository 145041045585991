import React from 'react'
import { useDragPosition, usePointerPosition } from '../../hooks/usePointerPosition'
import { toRgb, Color } from '../../helpers/color'
import { useThrottle } from '../../hooks/useThrottle'
import { Stack } from '../Stack'
import { Button } from '../Button'
import { Grid } from '../Grid'
import { Swatch } from '../Swatch'

import './color-picker.scss'

const SVtoSL = (ss, val) => {
  const v = val / 100
  const s = ss / 100
  const l = v - (v * s) / 2
  const m = Math.min(l, 1 - l)
  return [m ? (((v - l) / m) * 100) : 0, l * 100]
}

const trackBg = ({
  type = '',
  steps = 0,
  props = [],
}) => {
  const grad = []
  const propVal = (prop, i) => (typeof prop === 'function' ? prop(i) : prop)
  for (let i = 0; i < steps; i += 1) {
    const vals = props.map((val) => propVal(val, i)).join(' ')
    grad.push(`${type}(${vals})`)
  }
  return `linear-gradient(to right, ${grad.join(', ')})`
}

// TODO: This updates slowly because events fire too late
// need to memoize on position and use reduce to set state
const ColorPicker = ({
  onChange: onChangeProp,
  color: colorProp,
  width = 200,
  height = 80,
  handleSize = 12,
}) => {
  const pickerRef = React.useRef()
  const frameRef = React.useRef()
  const handleRef = React.useRef()
  const bgRef = React.useRef()
  const hueRef = React.useRef()
  const [color, setColor] = React.useState(Color(colorProp || 'hsl(0 100% 50%)'))
  const hex = React.useRef()

  const hslBg = React.useCallback(({
    hue = 'var(--picker-hue)',
    sat = 'calc(var(--picker-saturation) * 1%)',
    lig = 'calc(var(--picker-luminosity) * 1%)',
    ...props
  }) => trackBg({ type: 'hsl', props: [hue, sat, lig], ...props }), [])

  const hueTrack = () => hslBg({
    hue: (v) => v, sat: '100%', lig: '50%', steps: 360,
  })

  const setVar = (prop, value) => {
    if (['number', 'string'].includes(typeof value)) pickerRef.current?.style.setProperty(`--picker-${prop}`, value)
  }
  const getVar = (prop) => pickerRef.current?.style.getPropertyValue(`--picker-${prop}`)
  const setVars = (vars = {}) => {
    Object.entries(vars).map((v) => setVar(...v))
  }
  const updateHandle = React.useCallback(() => {
    const xPercent = getVar('hsvSaturation')
    const yPercent = getVar('value')
    const handle = handleRef.current
    if (handle) {
      const offset = handleSize / 2
      const handleX = ((xPercent / 100) * width) - offset
      const handleY = (((yPercent) / 100) * height) - (offset)
      handle.style.transform = `translate(${handleX}px, ${handleY}px)`
      return { translate: `translate(${handleX}px, ${handleY}px)` }
    }
    return {}
  }, [])

  const onChange = useThrottle(() => {
    hex.current = toRgb(`hsl(${getVar('hue')} ${getVar('saturation')}% ${getVar('luminosity')}%)`).hex
    onChangeProp?.(hex.current)
  }, [onChangeProp], 60)

  const onMove = React.useCallback((position) => {
    const { xPercent: hsvSaturation, yPercent: value } = position
    const [saturation, luminosity] = SVtoSL(hsvSaturation, 100 - value)
    setVars({
      saturation, luminosity, hsvSaturation, value,
    })
    updateHandle()
    onChange()
  }, [onChange])

  const updateHue = React.useCallback(({ target }) => {
    setVars({ hue: target.value })
    updateHandle()
    onChange()
  }, [])

  useDragPosition({ onMove, ref: frameRef })
  const { isMouseDown } = usePointerPosition({ ref: pickerRef })

  React.useEffect(() => {
    if (!isMouseDown.current) {
      hex.current = colorProp
      const newColor = Color(colorProp || 'hsl(0 100% 100%)')
      const {
        hue,
        saturation,
        luminosity,
        hsvSaturation,
        value,
      } = newColor
      setVars({
        hue,
        saturation,
        luminosity,
        hsvSaturation,
        value,
      })
      hueRef.current.value = hue
      setColor(newColor)
      updateHandle()
    }
  }, [colorProp])

  return (
    <div ref={pickerRef}>
      <Stack gap={4}>
        <div style={{
          padding: handleSize / 2,
          width: width + handleSize,
          height: height + handleSize,
        }}
        >
          <div
            className="level-color-picker-frame"
            ref={frameRef}
          >
            <div
              id="level-hsv-handle"
              className="level-color-picker-handle"
              style={{
                width: handleSize,
                height: handleSize,
              }}
              data-none={!hex.current}
              ref={handleRef}
            />
            <div
              ref={bgRef}
              style={{
                backgroundColor: 'hsl(var(--picker-hue) 100% 50%)',
                inset: -(handleSize / 2),
              }}
              className="level-color-picker-bg"
            />
          </div>
        </div>

        <Grid columns={2} templateColumns="min-content 1fr" gap={4} valign="center" style={{ width: width + handleSize }}>
          <Button theme="wrapper" onClick={() => onChangeProp?.('')}>
            <Swatch color="none" size={20} style={{ display: 'flex' }} className="level-swatch-button" data-selected={!colorProp} />
          </Button>
          <div className="level-color-picker-slider-track">
            <input
              className="level-color-picker-slider"
              style={{ background: hueTrack() }}
              type="range"
              min={0}
              max={360}
              name="hue"
              onChange={updateHue}
              defaultValue={color?.hue}
              ref={hueRef}
            />
          </div>
        </Grid>
      </Stack>
    </div>
  )
}

export {
  ColorPicker,
}
