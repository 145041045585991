import React from 'react'
import PropTypes from 'prop-types'

import { DropZone } from '../DropZone'
import { useToast } from '../../hooks/useToast'
import { passthroughCallback } from '../../helpers'

const fileTypes = {
  image: /image/,
}

const FileDrop = ({ onDrop: onDropFn, type, ...props }) => {
  const { sendError } = useToast()
  const onDrop = React.useCallback(async ({ data, event }) => {
    if (data?.length) {
      const [file] = data
      if (type && !file.type.match(fileTypes[type] || type)) {
        sendError({ title: 'File Drop Failed', message: `File of ${file.type} is not supported.` })
        return null
      }

      return passthroughCallback(onDropFn, { data, event })
    }
    return null
  }, [onDropFn])

  return <DropZone type="file" onDrop={onDrop} {...props} />
}

FileDrop.propTypes = {
  onDrop: PropTypes.func,
  type: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
}

FileDrop.defaultProps = {
  onDrop: null,
  type: null,
}

export {
  FileDrop,
}
