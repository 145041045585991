import { useToast, titleize } from '@level'
// import { useBugsnag } from './useBugsnag'

const unknownError = { type: 'error', message: { title: 'Unspecified Error', message: 'We are so sorry.', replace: false } }

const getServerErrorMetadata = ({ code, name }) => {
  if (code === 'jwt_invalid') {
    return { message: { title: 'Session has expired', message: 'Please sign in again.', replace: false } }
  }

  if (code === 'offline') {
    return { message: { type: 'warning', title: 'Network Disconnected', message: 'Try again when you have a more stable internet connection.' } }
  }

  if (name === 'AbortError') {
    return { message: process.env.NODE_ENV === 'production' ? false : { type: 'warning', title: 'Request Aborted', message: 'This request was aborted.' } }
  }
  return unknownError
}

const getGraphQlErrorMetadata = (props) => {
  if (process.env.NODE_ENV !== 'production') console.error(props)
  const { message, name, extensions } = props
  const { code, title = code ? titleize(code.toLowerCase()) : 'Unspecified Error' } = extensions || {}
  if (code === 'not_valid' || name === 'AbortError') {
    // Let form error hanlding pick this up.
    return { message: false }
  }
  if (code) return { type: code, message: { title, message, replace: false } }
  return unknownError
}

const useHandleErrors = () => {
  const { sendError } = useToast()
  // const Bugsnag = useBugsnag()

  const handleErrors = (er) => {
    if (Array.isArray(er)) {
      er.map(handleErrors)
    } else {
      const meta = er.code ? getServerErrorMetadata(er) : getGraphQlErrorMetadata(er)
      if (meta.type === 'error') {
        // Bugsnag.leaveBreadcrumb('Unspecified Error')
        // Bugsnag.notify(new Error(er.message), (ev) => (
        // ev.addMetadata('Error Object', er)
        // ))
      }
      if (meta.message) sendError(meta.message)
    }
  }

  return handleErrors
}

export {
  useHandleErrors,
}
