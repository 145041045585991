import React from 'react'
import PropTypes from 'prop-types'
import { spaceToSize } from '../../helpers/space'

import './card.scss'

const Card = ({
  space,
  className,
  style,
  theme,
  align,
  children,
  shadow,
  animate,
}) => {
  const s = { padding: spaceToSize(space), ...style }
  return (
    <div
      className={`${className} level-card`}
      style={s}
      data-theme={theme}
      data-align={align}
      data-shadow={shadow}
      data-animate={animate}
    >{ children }
    </div>
  )
}

Card.propTypes = {
  space: spaceToSize.propTypes.space,
  theme: PropTypes.string,
  align: PropTypes.string,
  style: PropTypes.object,
  shadow: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  className: PropTypes.string,
  animate: PropTypes.string,
  children: PropTypes.node.isRequired,
}

Card.defaultProps = {
  className: '',
  style: {},
  space: 6,
  align: 'left',
  theme: undefined,
  animate: undefined,
  shadow: undefined,
}

export {
  Card,
}
