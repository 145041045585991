import React from 'react'
import PropTypes from 'prop-types'

import { ContentEditableInput } from '../ContentEditableInput'

const PhoneText = ({ text, ...props }) => (
  <div className="level-phone-text-wrapper">
    <ContentEditableInput
      className="level-phone-text"
      defaultValue={text}
      {...props}
    />
  </div>
)

PhoneText.propTypes = {
  text: PropTypes.string,
}

PhoneText.defaultProps = {
  text: undefined,
}

export { PhoneText }
