import React from 'react'
import PropTypes from 'prop-types'

import { Table, useTable, Text } from '@level'

const passFail = ({ cell: { renderValue } }) => (
  <Text className={`level-pill-${renderValue()}`}>{renderValue()}</Text>
)

const source = ({ cell: { renderValue } }) => (
  <Text>{renderValue()}</Text>
)
const showDisposition = ({ cell: { renderValue } }) => (
  <Text className={`level-pill-${renderValue() === 'none' ? 'pass' : 'fail'}`}>{renderValue()}</Text>
)

const columnNames = [
  { sourceIp: { cell: source } },
  { sourceDomain: { cell: source } },
  { emails: { header: '# of Emails' } },
  { disposition: { header: 'Disposition', cell: showDisposition } },
  { dkim: { header: 'DKIM Verification', cell: passFail } },
  { spf: { header: 'SPF Verification', cell: passFail } },
]

const ModalTable = ({ data, ...rest }) => {
  const table = useTable({
    data,
    columnNames,
  })
  return data ? (
    <Table
      table={table}
      theme="white"
      className="dmarc-records"
      offsetSpace={7}
      limitHeight
      {...rest}
    />
  ) : null
}

ModalTable.propTypes = {
  data: PropTypes.array,
}

ModalTable.defaultProps = {
  data: undefined,
}

export {
  ModalTable,
}
