import React from 'react'
import PropTypes from 'prop-types'

import {
  Routes,
  Route,
  Navigate,
} from 'react-router-dom'

import {
  ProfileProvider, useAuth,
} from '@app/hooks'

import { BugsnagIdentify } from '@app/components/Bugsnag'

import { NewSession } from './new'
import { Verify } from './verify'
import { SignOut } from './signOut'

const devUser = process.env.DEV_USER

const AuthIndex = () => (
  <Routes>
    <Route index element={<Verify />} />
    <Route path="new" element={<NewSession />} />
    <Route path="signOut" element={<SignOut />} />
  </Routes>
)

const Authed = ({ admin, children }) => {
  const { sessionActive } = useAuth()

  if (sessionActive || devUser) {
    return (
      <ProfileProvider admin={admin}>
        <BugsnagIdentify />
        { children }
      </ProfileProvider>
    )
  }
  return <Navigate to="/auth/new" />
}

Authed.propTypes = {
  admin: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

Authed.defaultProps = {
  admin: false,
}

export {
  AuthIndex,
  Authed,
}

export * from './provider'
