import React from 'react'
import PropTypes from 'prop-types'

import { useLocation, useResolvedPath } from 'react-router-dom'

import { NavList } from '../NavList'
import { Grid } from '../Grid'
import { Button } from '../Button'
import { Link } from '../Link'
import { Transition } from '../Transition'

import './nav-tree.scss'

const NavTree = ({ rootLink, navItems, open: defaultOpen }) => {
  const { pathname } = useLocation()
  const { pathname: resolvedPath } = useResolvedPath(rootLink.to)
  const linkActive = !!pathname.match(new RegExp(`${resolvedPath}\\b`))
  const [open, setOpen] = React.useState(linkActive || defaultOpen)

  React.useEffect(() => { setOpen(linkActive || defaultOpen) }, [linkActive, defaultOpen])

  return (
    <div className="level-nav-tree">
      <Grid
        data-active={linkActive}
        className="level-nav-tree-root"
        templateColumns="1fr min-content"
        valign="center"
      >
        <Link theme="nav-tree-root" {...rootLink} />
        <Button
          size={4}
          icon={{
            size: 1,
            name: open ? 'angle-down' : 'angle-right',
          }}
          theme="ghost"
          label="open nav"
          onClick={() => setOpen((a) => !a)}
        />
      </Grid>
      <Transition in={open} type="fade-in-down">
        { open ? (
          <NavList theme="nav-tree" navItems={navItems} />
        ) : null}
      </Transition>
    </div>
  )
}

NavTree.propTypes = {
  rootLink: PropTypes.object,
  navItems: NavList.propTypes.navItems,
  open: PropTypes.bool,
}

NavTree.defaultProps = {
  rootLink: {},
  navItems: [],
  open: false,
}

export {
  NavTree,
}
