import React from 'react'
import PropTypes from 'prop-types'

import { toColor, cleanPercentage } from '../../helpers'

const Circle = ({ color, pct }) => {
  const r = 13
  const circ = 2 * Math.PI * r
  const strokePct = ((100 - pct) * circ) / 100
  return (
    <circle
      r={r}
      cx="-50%"
      cy="50%"
      fill="transparent"
      stroke={strokePct !== circ ? color : null} // remove color as 0% sets full circumference
      strokeWidth="7px"
      strokeDasharray={circ}
      strokeDashoffset={pct ? strokePct : 0}
    />
  )
}

Circle.propTypes = {
  color: PropTypes.string.isRequired,
  pct: PropTypes.number,
}

Circle.defaultProps = {
  pct: undefined,
}

const PercentageCircle = ({
  percentage,
  lessThan,
  greaterThan,
  className,
  negative,
  ...props
}) => {
  const {
    greaterThanColor = !negative ? 'green-600' : 'red-600',
    lessThanColor = !negative ? 'red-600' : 'green-600',
  } = props
  const pct = cleanPercentage(percentage)
  let fill = toColor('yellow-600')

  if (pct < lessThan) fill = (toColor(`${lessThanColor}`))
  if (pct > greaterThan) fill = (toColor(`${greaterThanColor}`))

  return (
    <svg width={40} height={40} className={`level-percentage-bar ${className || ''}`}>
      <g transform="rotate(-90)">
        <Circle color={toColor('neutral-50')} />
        <Circle color={fill} pct={pct} />
      </g>
    </svg>
  )
}

PercentageCircle.propTypes = {
  percentage: PropTypes.number.isRequired,
  lessThan: PropTypes.number.isRequired,
  greaterThan: PropTypes.number.isRequired,
  className: PropTypes.string,
  negative: PropTypes.bool,
  lessThanColor: PropTypes.string,
  greaterThanColor: PropTypes.string,
}

PercentageCircle.defaultProps = {
  className: undefined,
  negative: undefined,
  lessThanColor: undefined,
  greaterThanColor: undefined,
}

export { PercentageCircle }
