import React from 'react'
import { useWindowResize } from './useWindowResize'
import { useMessages } from './useMessages'
import { spaceToSize, toPadding } from '../helpers'

const getMaxSize = ({
  contentEl, footerEl, rootEl, offsetSpace,
}) => {
  // Get top-most point of this element in the dom
  const { top: contentTop, left: contentLeft } = contentEl.getBoundingClientRect()
  const { innerHeight, innerWidth } = window
  const { right, bottom } = toPadding(spaceToSize(offsetSpace || 0))
  // If a footer exists, get the client height of that footer
  const footerHeight = footerEl ? footerEl.getBoundingClientRect().height : 0
  const rootElLimit = rootEl ? innerHeight - rootEl.getBoundingClientRect().bottom : 0
  // Set the height to the full height of the viewport minus the top offest and the footer height.
  // This will size the content element to push the footer to the bottom.
  return {
    height: `calc(${innerHeight}px - ${contentTop + rootElLimit + footerHeight + bottom}px)`,
    width: `calc(${innerWidth}px - ${contentLeft + right}px)`,
  }
}

const useMaxSize = (props = {}) => {
  const contentRef = props.contentRef || React.useRef()
  const footerRef = props.footerRef || React.useRef()
  const { rootRef } = props
  const size = useWindowResize()
  const { offsetSpace } = props
  const [sizes, setSizes] = React.useState({})
  const { subscribe, publish } = useMessages({ channel: 'layout' })

  const layoutChange = subscribe()
  const resize = React.useCallback(() => {
    const rootEl = rootRef?.current
      || (props.withinParent ? contentRef?.current.parentElement : null)
    const { height, width } = getMaxSize({
      offsetSpace,
      contentEl: contentRef.current,
      footerEl: footerRef?.current,
      rootEl,
    })
    setSizes({
      height,
      width,
    })
  }, [offsetSpace])

  React.useLayoutEffect(() => {
    const rootEl = rootRef?.current
      || (props.withinParent ? contentRef?.current?.parentElement : null)
    if (contentRef.current) { resize() }
    const triggerLayout = () => publish({ refresh: {} })
    if (rootEl) {
      rootEl.addEventListener('transitionend', triggerLayout)
    }
    return () => {
      if (rootEl) {
        rootEl.removeEventListener('transitionend', triggerLayout)
      }
    }
  }, [
    contentRef.current,
    footerRef.current,
    rootRef?.current,
    size,
    props?.refresh,
    layoutChange,
    offsetSpace,
  ])

  return {
    ...sizes,
    ref: contentRef,
    contentRef,
    footerRef,
  }
}

export { useMaxSize }
