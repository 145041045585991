import React from 'react'
import PropTypes from 'prop-types'

import './drop-card.scss'

import { Icon } from '../Icon'
import { Stack } from '../Stack'
import { Text } from '../Text'

const DropCard = ({ children }) => (
  <div className="level-file-drop-card" data-preview={!!children || null}>
    { children || (
      <Stack gap={3} align="center">
        <Icon size={6} name="cloud-arrow-up" color="neutral-300" />
        <Text color="neutral-600">Drag and drop to upload or <Text color="blue-600">choose a file</Text></Text>
      </Stack>
    )}
  </div>
)

DropCard.propTypes = {
  children: PropTypes.node,
}

DropCard.defaultProps = {
  children: undefined,
}

export { DropCard }
