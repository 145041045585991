import React from 'react'
import { uuid } from '../helpers/text'
import { usePubSub } from './usePubSub'

const MessageContext = React.createContext()

const MessageProvider = (props) => {
  const {
    subscribe,
    publish,
  } = usePubSub()

  const value = React.useMemo(() => ({ subscribe, publish }), [subscribe])

  return (
    <MessageContext.Provider value={value} {...props} />
  )
}

const useMessages = ({ channel }) => {
  const { publish: send, subscribe: watch } = React.useContext(MessageContext)

  const publish = React.useCallback((state) => {
    send({ channel, state: { uuid: uuid(), ...state } })
  }, [channel, send])

  const clear = React.useCallback(() => {
    send({ channel, state: null })
  }, [channel, send])

  const subscribe = React.useCallback(() => watch(channel), [watch, channel])

  return React.useMemo(() => ({
    publish, subscribe, clear,
  }), [publish, subscribe, clear])
}

export {
  MessageProvider,
  useMessages,
}
