import React from 'react'
import PropTypes from 'prop-types'
import { useFormRef } from '../../hooks'
import { camelToTitle } from '../../helpers'
import { Stack } from '../Stack'
import { Shelf } from '../Shelf'

import './select.scss'

const deriveLabel = ({ label, name }) => (label === true ? camelToTitle(name) : label)

const Select = React.forwardRef(({
  name,
  options,
  messages,
  label,
  required: Required,
  onChange: onChangeProp,
  submitOnChange,
  inlineLabel,
  ...rest
}, forwardedRef) => {
  const formRef = useFormRef()
  const labelText = deriveLabel({ label, name })
  const required = Required ? (messages.required || `${labelText} is required`) : null
  const inputError = formRef?.formState.errors[name]
  let ref = React.useRef()
  if (forwardedRef) ref = forwardedRef

  // Allow input changes to submit form
  const onChange = submitOnChange && formRef ? (event) => {
    // onChange triggers before the formRef value is updated, so we set it manually
    formRef.setValue(name, event.target.value, { shouldDirty: true })
    onChangeProp?.(event, formRef)
    formRef.submit()
  } : (event) => {
    formRef?.onChangeInput?.({ [name]: event.target.value })
    onChangeProp?.(event, formRef)
  }

  const className = `level-select ${rest.className || ''}`

  const formRefProps = (() => (formRef ? formRef.register(name, { required, onChange }) : {}))()

  React.useEffect(() => {
    // Ensure if options change that the formref value is updated to match the current value
    if (ref.current && formRef?.getValues && formRef.getValues(name) !== ref.current.value) {
      formRef.setValue(name, ref.current.value, { shouldDirty: true })
    }
  }, [options])

  const selectInput = (
    <select
      name={name}
      id={name}
      className={className}
      onChange={formRef ? null : onChange}
      {...formRefProps}
      {...rest}
      ref={(instance) => {
        if (formRefProps.ref) { formRefProps.ref(instance) }
        ref.current = instance
      }}
    >
      { options.map((o) => {
        const opt = (typeof o === 'object') ? o : ({ value: o })
        return (
          <option key={opt.value} value={opt.value}>{opt.label || opt.value}</option>
        )
      })}
    </select>
  )

  if (rest.inlineLabel) {
    return (
      <Stack gap={4}>
        <Shelf gap={4} valign="center">
          { label ? <label className="level-label" htmlFor={name}>{labelText}</label> : null }
          { selectInput }
        </Shelf>
        { inputError ? <div>{inputError.message}</div> : null }
      </Stack>
    )
  }

  return (
    <Stack gap={4}>
      { label ? <label className="level-label" htmlFor={name}>{labelText}</label> : null }
      { selectInput }
      { inputError ? <div className="level-input-error-message">{inputError.message}</div> : null }
    </Stack>
  )
})

Select.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  pattern: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  messages: PropTypes.shape({
    required: PropTypes.string,
  }),
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.any,
      value: PropTypes.any.isRequired,
    })),
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  submitOnChange: PropTypes.bool,
  onChange: PropTypes.func,
  inlineLabel: PropTypes.bool,
}

Select.defaultProps = {
  messages: {},
  required: false,
  label: true,
  placeholder: null,
  pattern: null,
  submitOnChange: false,
  onChange: null,
  inlineLabel: null,
}

export { Select }
export * from './Menu'
export * from './MultiSelect'
