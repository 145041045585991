import React from 'react'
import PropTypes from 'prop-types'

import { Stack } from '../Stack'
import { Grid } from '../Grid'
import { Text, P } from '../Text'

const DataShelf = ({
  title,
  text,
  children,
  ...props
}) => (
  <>
    <Text
      tag="h5"
      className="level-data-stack-title"
      {...props}
    >
      <span>{title}</span>
    </Text>
    <div>
      { children || null }
      { text ? <P>{ text }</P> : null }
    </div>
  </>
)

DataShelf.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  children: PropTypes.node,
}

DataShelf.defaultProps = {
  text: undefined,
  children: undefined,
}

const getWidest = (root) => (
  Array.prototype.map.call(
    root.querySelectorAll('.level-data-stack-title span'),
    (el) => Math.ceil(el.getClientRects()[0].width),
  ).sort((a, b) => b - a)[0]
)

const DataStack = ({
  titleWidth: titleWidthProp,
  children,
  ...props
}) => {
  const ref = React.useRef()
  const [titleWidth, setTitleWidth] = React.useState(titleWidthProp)

  React.useLayoutEffect(() => {
    if (ref.current) {
      // Sets title width to the widest title
      const widest = getWidest(ref.current)
      setTitleWidth(`${widest}px`)
    }
  }, [children, titleWidth, ref.current])

  return (
    <Grid {...props} templateColumns={titleWidth ? `${titleWidth} 1fr` : null} className="level-data-stack" ref={ref}>
      { children }
    </Grid>
  )
}

DataStack.propTypes = {
  titleWidth: PropTypes.string,
  children: PropTypes.node.isRequired,
  gap: Stack.propTypes.gap,
}

DataStack.defaultProps = {
  titleWidth: undefined,
  gap: 6,
}

export {
  DataStack,
  DataShelf,
}
