import React from 'react'
import { useLocalStorage } from 'beautiful-react-hooks'

import {
  Banner, Text, Grid, Input, Button,
  useDialog,
} from '@level'

import { useCreateTemplate } from './new'

const ImportTemplateBanner = () => {
  const [template, setTemplate] = useLocalStorage('template-try', null)
  const { onSubmit } = useCreateTemplate()
  const sendDialog = useDialog()

  const dismiss = () => {
    setTemplate((t) => ({ ...t, import: false }))
  }

  const importTemplate = () => {
    sendDialog({
      onConfirm: ({ data: { previewConfig: config, name, type } }) => {
        onSubmit({ data: { config, name, type } })
      },
      title: 'Import Template',
      confirmText: 'Import',
      content: (
        <Input name="name" defaultValue={template.name} label="Template Name" placeholder="My Template" required />
      ),
      form: {
        focus: 'name',
        data: template,
      },
    })
  }

  return template?.import ? (
    <Banner border="neutral-50" background="blue-25">
      <Grid valign="center" align="split" templateColumns="auto auto">
        <Text>
          You&rsquo;ve been working on a template in our public builder.
          Would you like to import it?
        </Text>
        <Grid gap={4} templateColumns="auto auto">
          <Button size={3} text="Dismiss" onClick={dismiss} />
          <Button theme="primary" size={3} text="Import Template" onClick={importTemplate} />
        </Grid>
      </Grid>
    </Banner>
  ) : null
}

export {
  ImportTemplateBanner,
}
