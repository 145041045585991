import React from 'react'
import PropTypes from 'prop-types'

import {
  Grid, Text, Card, CopyButton,
} from '@level'

const CopyCode = ({ value }) => (
  <Card space={4}>
    <Grid templateColumns="auto min-content" align="split" gap={4} valign="top">
      <Text style={{ fontFamily: 'system-mono' }} forceWrap>{ value }</Text>
      <CopyButton text={value} />
    </Grid>
  </Card>
)

CopyCode.propTypes = {
  value: PropTypes.string,
}

CopyCode.defaultProps = {
  value: 'Empty',
}

export { CopyCode }
