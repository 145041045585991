import React from 'react'
import { useLocalStorage } from 'beautiful-react-hooks'
import { uuid } from '@level'

const useAnonymousId = () => {
  const [id, setId] = useLocalStorage('anonymous-id', null)

  React.useEffect(() => {
    if (!id) setId(uuid)
  }, [id])

  return id
}

export {
  useAnonymousId,
}
