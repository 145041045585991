import { newSectionConfig } from './helpers'
import {
  Image, Divider, Text, Heading, Button, Table,
} from '../Element'

const Blank = newSectionConfig(
  'Blank',
  ({ addColumn }) => { addColumn() },
)

const TextSection = newSectionConfig(
  'Text',
  ({ addColumn }) => {
    const column = addColumn()
    column.addElement(Text)
  },
)

const HeadingSection = newSectionConfig(
  'Heading',
  ({ addColumn }) => {
    const column = addColumn()
    column.addElement(Heading)
  },
)

const DividerSection = newSectionConfig(
  'Divider',
  ({ addColumn }) => {
    const column = addColumn()
    column.addElement(Divider)
  },
)

const ImageSection = newSectionConfig(
  'Image',
  ({ addColumn }) => {
    const column = addColumn()
    column.addElement(Image)
  },
)

const ButtonSection = newSectionConfig(
  'Button',
  ({ addColumn, config }) => {
    config({ align: 'center' })
    const column = addColumn()
    column.addElement(Button)
  },
)

const TableSection = newSectionConfig(
  'Table',
  ({ addColumn }) => {
    const column = addColumn()
    column.addElement(Table)
  },
)

export default {
  Blank,
  HeadingSection,
  TextSection,
  ImageSection,
  DividerSection,
  ButtonSection,
  TableSection,
}
