import React from 'react'
import { Navigate } from 'react-router-dom'
import { sortBy } from '@level'
import { useTeam } from './useTeam'
import { QueryProvider } from './QueryProvider'

const ProjectsContext = React.createContext({})

const useProjects = () => React.useContext(ProjectsContext)

const projectsQuery = ({ Team: { slug } }) => `{
  projects(team: "${slug}") {
    id name slug createdAt
    team { slug }
  } 
}`

const ProjectsProvider = (props) => {
  const { Team } = useTeam()
  const onData = React.useCallback(({ data: { projects = [] } }) => (
    {
      projects: projects.sort(sortBy('name')),
      projectsUrl: (append) => {
        const url = `/teams/${Team.slug}/projects`
        return append ? `${url}/${append}` : url
      },
    }
  ), [Team.slug])

  return Team ? (
    <QueryProvider
      query={projectsQuery({ Team })}
      context={ProjectsContext}
      onData={onData}
      {...props}
    />
  ) : <Navigate to="/not-found" />
}

export {
  ProjectsProvider,
  ProjectsContext,
  useProjects,
  projectsQuery,
}
