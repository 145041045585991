import React from 'react'
import PropTypes from 'prop-types'

import {
  Tab as ReachTab,
  Tabs as ReachTabs,
  TabList as ReachTabList,
  TabPanel as ReachTabPanel,
  TabPanels as ReachTabPanels,
  useTabsContext,
} from '@reach/tabs'
import '@reach/tabs/styles.css'
import './tabs.scss'

import { mergeProps } from '../../helpers'
import { useMaxSize } from '../../hooks/useMaxSize'
import { useMessages } from '../../hooks/useMessages'

const Tab = ({ text, children, ...props }) => (
  <ReachTab className="level-tab" {...props}>
    { text || children }
  </ReachTab>
)
const TabList = (props) => <ReachTabList className="level-tab-list" {...props} />

Tab.propTypes = {
  ...ReachTab.propTypes,
  text: PropTypes.string,
  /* eslint-disable react/require-default-props */
  /* Default props as `undefined` would override inherited props */
}

Tab.defaultProps = {
  text: null,
}
// Passes the panel index as a prop
const TabPanels = ({
  children,
  scroll,
  fixHeight,
  style: styleProp,
  ...props
}) => {
  const { selectedIndex } = useTabsContext()
  const { publish } = useMessages({ channel: 'layout' })

  React.useLayoutEffect(() => {
    publish({ refresh: {} })
  }, [selectedIndex])

  const { height, ref } = useMaxSize({ withinParent: true })
  const style = { ...styleProp }
  if (scroll) {
    style.height = height
    style.overflowY = 'auto'
  }

  return (
    <ReachTabPanels
      style={style}
      ref={scroll ? ref : null}
      {...props}
    >
      { React.Children.map(children, (child, index) => mergeProps(child, { index })) }
    </ReachTabPanels>
  )
}

TabPanels.propTypes = {
  ...ReachTabPanels.propTypes,
  scroll: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  style: PropTypes.object,
}

TabPanels.defaultProps = {
  scroll: false,
  style: {},
}

// Uses the selected index to conditionally render the panel contents
const TabPanel = ({
  index,
  children,
  ...props
}) => {
  const { selectedIndex } = useTabsContext()

  return (
    <ReachTabPanel className="level-tab-panel" {...props}>
      { selectedIndex === index ? children : null}
    </ReachTabPanel>
  )
}

TabPanel.propTypes = {
  ...ReachTabPanel.propTypes,
  /* eslint-disable react/require-default-props */
  /* Default props as `undefined` would override inherited props */
  index: PropTypes.number,
}

const Tabs = ({ theme, ...rest }) => <ReachTabs data-theme={theme} {...rest} />

Tabs.propTypes = {
  theme: PropTypes.string,
}

Tabs.defaultProps = {
  theme: 'default',
}

export {
  Tab,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
}
