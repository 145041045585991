import React from 'react'

import { Routes, Route } from 'react-router-dom'

import { ConfigureSection } from './Section'
import { ElementConfig } from './Element'
import { DrawerMenu } from './DrawerMenu'
import { TemplateConfigDrawer } from './Drawer'
import { useTemplateConfig, TemplateConfigProvider } from './useTemplateConfig'
import { ConfigureTheme } from './theme'

const TemplateConfigIndex = () => {
  const {
    showDrawerMenu, config, theme, updateTemplate,
  } = useTemplateConfig()
  if (showDrawerMenu) {
    return (<DrawerMenu />)
  }

  return (
    <Routes>
      <Route index element={<TemplateConfigDrawer />} />
      <Route path="theme" element={<ConfigureTheme theme={theme} config={config} onSubmit={updateTemplate} />} />
      <Route path="section/:sectionId/*" element={<ConfigureSection />} />
      <Route path="element/:elementId" element={<ElementConfig />} />
    </Routes>
  )
}

export {
  TemplateConfigIndex,
  TemplateConfigProvider,
  useTemplateConfig,
}
export * from './Config'
export { SectionConfigForm } from './Section'
export { ElementConfig } from './Element'
export { DrawerMenu }
