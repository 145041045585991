import React from 'react'
import { useMessages } from '../useMessages'
import { toastDefaults } from './defaults'

// Wraps the useMessages pubsub with some guard-rails for sending toast type messages
const useToast = () => {
  const { publish, subscribe, clear } = useMessages({ channel: 'toast' })

  // This makes it easy to accept props as an object or a string and merge it properly
  const send = React.useCallback(({ type, ...props }) => {
    const defaultProps = { ...toastDefaults[type], type }
    if (typeof props === 'string') publish({ ...defaultProps, message: props })
    else if (typeof props === 'object') publish({ ...defaultProps, ...props })
    else publish(defaultProps)
  }, [])

  // These are helper functions to set type, defaults, and overrides.
  const sendSuccess = React.useCallback((props) => {
    send({ type: 'success', ...props })
  }, [])
  const sendError = React.useCallback((props) => {
    send({ type: 'error', ...props })
  }, [])
  const sendProcessing = React.useCallback((props) => {
    send({ type: 'processing', ...props })
  }, [])
  const sendWarning = React.useCallback((props) => {
    send({ type: 'warning', ...props })
  }, [])
  const sendSystemUpdate = React.useCallback((props) => {
    send({ type: 'systemUpdate', ...props })
  }, [])
  const sendNotice = React.useCallback((props) => {
    send({ type: 'notice', ...props })
  }, [])

  return React.useMemo(() => ({
    clear,
    subscribe,
    sendError,
    sendNotice,
    sendSuccess,
    sendWarning,
    sendProcessing,
    sendSystemUpdate,
  }), [publish, subscribe, clear])
}

export {
  useToast,
  toastDefaults,
}
