import React from 'react'

import { useTemplate, TemplateProvider } from '../useTemplate'
import {
  renderMustache, dotKeysToObject, sortKeys, deepMerge, includeKeys,
} from '../../../helpers'

import {
  Phone, PhoneText, PhoneImages,
} from '../../Phone'

const SMSTemplateBody = () => {
  const {
    avatar, defaultFrom, config, hooks, mode, previewVars,
  } = useTemplate()

  const body = previewVars
    ? renderMustache(config.body, previewVars)
    : config.body

  return mode !== 'preview' ? (
    <Phone
      photo={avatar}
      number={config?.variables?.config?.defaultFrom || defaultFrom}
      changeFrom={hooks.changeFrom}
      onDrop={hooks.onDropImage}
      addImage={hooks.addImage}
    >
      <PhoneImages
        images={config?.variables?.images}
        removeImage={hooks.removeImage}
      />
      <PhoneText
        text={body}
        placeholder="Type text here"
        onChange={hooks.onChangeBody}
      />
    </Phone>
  ) : (
    <div style={{ padding: '10px' }}>
      { config?.variables?.images?.length ? (
        <div style={{
          maxHeight: '100px',
          overflow: 'hidden',
          borderRadius: '8px',
          marginBottom: '8px',
        }}
        >
          <PhoneImages
            images={config?.variables?.images}
            removeImage={hooks.removeImage}
          />
        </div>
      ) : null }
      <PhoneText text={config.body || ''} />
    </div>
  )
}

const SMSTemplate = (props) => {
  const context = useTemplate()

  if (context?.config) {
    return <SMSTemplateBody />
  }

  return (
    <TemplateProvider {...props}>
      <SMSTemplateBody />
    </TemplateProvider>
  )
}

const getSmsTemplateVars = ({ template, previewVars = {} }) => {
  const obj = dotKeysToObject(template?.previewTemplateVariables || [])
  return previewVars
    ? sortKeys(deepMerge(obj, includeKeys(previewVars, obj)))
    : sortKeys(obj)
}

export {
  SMSTemplate,
  getSmsTemplateVars,
}
