import React from 'react'
import PropTypes from 'prop-types'

import './preview.scss'

const Preview = ({
  zoom,
  height,
  style,
  children,
  className,
  borderColor,
  ...rest
}) => (
  <div
    className={`level-preview ${className || ''}`}
    style={{
      overflow: 'hidden',
      height: `${Math.ceil(100 / zoom)}%`,
      width: `${Math.ceil(100 / zoom)}%`,
      transform: `scale(${zoom})`,
      transformOrigin: 'top left',
      ...style,
    }}
    {...rest}
  >{children}
  </div>
)

Preview.propTypes = {
  children: PropTypes.node.isRequired,
  borderColor: PropTypes.string,
  className: PropTypes.string,
  zoom: PropTypes.number,
  style: PropTypes.object,
  height: PropTypes.number,
}

Preview.defaultProps = {
  className: undefined,
  borderColor: undefined,
  height: undefined,
  zoom: 0.50,
  style: {},
}

export {
  Preview,
}
