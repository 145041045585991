import React from 'react'

import { Navigate, useNavigate } from 'react-router-dom'
import { useFormQuery } from '@app/hooks'
import { updateProfileQuery } from './settings'
import { createTeamQuery } from '../teams/new'
import { WelcomeUser } from '../welcome/user'
import { WelcomeTeam } from '../welcome/team'
import { WelcomeProject } from '../welcome/project'
import { WelcomeInvitation } from '../welcome/invitation'
import { useWelcomeProgress } from '../welcome/useWelcomeProgress'
import { useCreateProject } from '../projects/new'

const AccountNew = () => {
  const welcomeData = useWelcomeProgress()
  const navigate = useNavigate()
  const {
    userName, Team, Project, invitations, refresh,
  } = welcomeData

  const { onSubmit: updateProfile } = useFormQuery({
    query: updateProfileQuery,
    successMessage: false,
    onSuccess: () => refresh(),
  })

  const { onSubmit: createTeam } = useFormQuery({
    query: createTeamQuery,
    successMessage: 'Your new team has was created.',
    onSuccess: () => refresh(),
  })

  const newProject = useCreateProject({ Team })

  const createProject = React.useCallback(async ({ data }) => {
    const response = await newProject({ data })
    if (response?.Project) {
      navigate(`/teams/${response.Project.team.slug}/projects/${response.Project.slug}/`)
    }
  }, [Team])

  if (!userName) return <WelcomeUser onSubmit={updateProfile} welcomeData={welcomeData} />
  if (invitations.length) return <WelcomeInvitation welcomeData={welcomeData} />
  if (!Team) return <WelcomeTeam onSubmit={createTeam} welcomeData={welcomeData} />
  if (!Project) {
    return (
      <WelcomeProject onSubmit={createProject} welcomeData={welcomeData} />
    )
  }
  if (Project) return <Navigate to={`/teams/${Project.team.slug}/projects/${Project.slug}/`} />
  return <Navigate to="/" />
}

export {
  AccountNew,
}
