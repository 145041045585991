import React from 'react'
import PropTypes from 'prop-types'
import { RadioPanel } from '../../../../RadioPanel'

const alignType = {
  horizontal: [
    { value: 'left', icon: 'align-left' },
    { value: 'center', icon: 'align-center' },
    { value: 'right', icon: 'align-right' },
    { value: 'justify', icon: 'align-justify' },
    { value: '', title: 'Inherit', icon: 'wand-magic-sparkles' },
  ],
  vertical: [
    { value: 'top', icon: 'objects-align-top' },
    { value: 'middle', icon: 'objects-align-center-vertical' },
    { value: 'bottom', icon: 'objects-align-bottom' },
    { value: '', title: 'Default: Inherit', icon: 'wand-magic-sparkles' },
  ],
}

const AlignInput = ({
  type,
  ...rest
}) => (
  <RadioPanel
    items={alignType[type]}
    {...rest}
  />
)

AlignInput.propTypes = {
  type: PropTypes.oneOf(['horizontal', 'vertical']),
}

AlignInput.defaultProps = {
  type: 'horizontal',
}

export {
  AlignInput,
}
