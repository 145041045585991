import React from 'react'

import { useCheckFilter } from '../../hooks'
import { Form } from '../Form'
import { CheckboxInput } from '../Input'
import { Stack } from '../Stack'
import { filterPropTypes } from './propTypes'

const CheckFilter = (options) => {
  const { inputs, onChange } = useCheckFilter(options)
  return (
    <Form onChangeInput={onChange} className="level-filter-form">
      <Stack gap={5}>
        {inputs.map((input) => (
          <CheckboxInput key={input.key} {...input} />
        ))}
      </Stack>
    </Form>
  )
}

CheckFilter.propTypes = {
  ...filterPropTypes,
}

export { CheckFilter }
