import React from 'react'
import PropTypes from 'prop-types'

import { spaceToSize } from '../../helpers/space'

const CardFooter = ({ space, style: styleProp, children }) => {
  const style = { ...styleProp, padding: spaceToSize(space) }
  return (
    <div className="level-card-footer" style={style}>{ children }</div>
  )
}

CardFooter.propTypes = {
  style: PropTypes.object,
  space: spaceToSize.propTypes.space,
  children: PropTypes.node.isRequired,
}

CardFooter.defaultProps = {
  space: 0,
  style: {},
}

export {
  CardFooter,
}
