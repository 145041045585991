import React from 'react'
import PropTypes from 'prop-types'

import {
  Icon, Text, Stack, Shelf, Column, P,
} from '@level'

import { WelcomeSkeleton } from './skeleton'

import './welcome-card.scss'

const WelcomeCard = ({
  title,
  description,
  children,
  userName,
  teamName,
  projectName,
  welcomeData,
}) => {
  const {
    steps,
    current,
    ...names
  } = welcomeData
  return (
    <Shelf className="welcome-page" nowrap>
      <Column className="welcome-form-wrapper" grow>
        <Stack gap={12}>
          <div className="level-welcome-card">
            <Icon name="enveloop-logomark" width={10} height={null} />
            <Stack gap={10}>
              <Stack gap={4}>
                <Text tag="h1">{ title }</Text>
                { description ? <P>{description}</P> : null}
              </Stack>
              { children }
            </Stack>
          </div>
          <div className="level-welcome-card-steps">
            <Shelf
              tag="ol"
              gap={5}
              aria-label="Sign up steps"
              align="center"
            >
              { steps.map((step, index) => (
                <li
                  key={step}
                  className="level-welcome-card-step"
                  data-current={index <= current ? true : null}
                >
                  <div className="level-welcome-card-step-marker">{step}</div>
                </li>
              ))}
            </Shelf>
          </div>
        </Stack>
      </Column>
      <Column className="welcome-skeleton-wrapper">
        <div className="welcome-skeleton">
          <WelcomeSkeleton
            userName={userName || names.userName}
            teamName={teamName || names.teamName}
            projectName={projectName || names.projectName}
          />
        </div>
      </Column>
    </Shelf>
  )
}

WelcomeCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  userName: PropTypes.string,
  teamName: PropTypes.string,
  projectName: PropTypes.string,
  children: PropTypes.node.isRequired,
  welcomeData: PropTypes.object.isRequired,
}

WelcomeCard.defaultProps = {
  description: undefined,
  userName: undefined,
  teamName: undefined,
  projectName: undefined,
}

export {
  WelcomeCard,
}
