import React from 'react'
import PropTypes from 'prop-types'

import { tagSizes } from '../../constants/themes'
import { typeRequiredUnless } from '../../helpers/react'
import { Shelf, Spacer } from '../Shelf'
import { Text } from '../Text'

import './header.scss'

const Header = ({
  text,
  tag,
  primary,
  children,
  ...rest
}) => (
  <Shelf
    className="level-header"
    valign="center"
    data-primary={primary}
    {...rest}
  >
    { text ? <Text tag={tag}>{text}</Text> : null }
    { text ? <Spacer grow /> : null }
    { children || null }
  </Shelf>
)

Header.propTypes = {
  ...Shelf.propTypes,
  primary: PropTypes.bool,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  tag: PropTypes.oneOf(Object.keys(tagSizes)),
  children: typeRequiredUnless({ type: PropTypes.node, present: 'text' }),
}

Header.defaultProps = {
  primary: null,
  text: null,
  tag: 'h3',
  children: null,
}

export {
  Header,
}
