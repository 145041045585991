import React from 'react'
import { useWindowResize as useWindowResizeFn } from 'beautiful-react-hooks'
import { useThrottle } from './useThrottle'

const useWindowResize = (opts = {}) => {
  const { delay = 12 } = opts
  const [width, setWidth] = React.useState(window.innerWidth)
  const [height, setHeight] = React.useState(window.innerHeight)

  // there's no need to use `useCallback` since the returned function
  // is already memoized
  const onWindowResizeHandler = useThrottle(
    () => {
      setWidth(window.innerWidth)
      setHeight(window.innerHeight)
    },
    [],
    delay,
  )

  useWindowResizeFn(onWindowResizeHandler)

  return React.useMemo(() => ([height, width]), [height, width])
}

export {
  useWindowResize,
}
