import { useWebSocket } from './useWebSocket'
import { useTeam } from './useTeam'

const useTeamChannel = (props) => {
  const { Team: { channel } } = useTeam()
  return useWebSocket({ channel, ...props })
}

export {
  useTeamChannel,
}
