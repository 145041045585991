import React from 'react'
import PropTypes from 'prop-types'

import {
  Stack, Input, CheckSwitch, Button, Text, Spacer, Shelf,
  CheckboxInput, Grid, SubmitButton, Editor, Divider,
  useDialog, capitalize,
} from '@level'

import {
  eventNames,
  eventsConfig,
} from './queries'

import { webhookExample } from './webhookExample'

const EditWebhookActions = ({
  close, deleteWebhook,
  confirmText, cancelText,
}) => (
  <Shelf gap={5} reverse valign="center">
    <SubmitButton text={confirmText} />
    <Button text={cancelText} onClick={close} />
    <Spacer grow />
    { deleteWebhook ? (
      <Button
        theme="destructive"
        onClick={deleteWebhook}
        text="Delete"
      />
    ) : null }
  </Shelf>
)

EditWebhookActions.propTypes = {
  close: PropTypes.func.isRequired,
  confirmText: PropTypes.string.isRequired,
  cancelText: PropTypes.string.isRequired,
  deleteWebhook: PropTypes.func,
}

EditWebhookActions.defaultProps = {
  deleteWebhook: undefined,
}

const useWebhookDialog = ({ webhook, ...rest }) => {
  const sendDialog = useDialog()
  return () => sendDialog({
    content: (
      <Stack gap={7}>
        <Input
          label="Webhook URL"
          name="url"
          placeholder="https://example.com/webhooks/enveloop"
          descriptionAfter="The URL needs to be publicly accessible."
          required
        />
        <CheckSwitch name="includeBody" label="Include Message Body" />
        <Text tag="h5">Available Events</Text>
        <Grid gap={6} columns={3}>
          { eventsConfig.map((name, index) => (
            <CheckboxInput
              key={name}
              name={name}
              label={capitalize(eventNames[index])}
            />
          )) }
        </Grid>
        { !webhook ? (
          <>
            <Divider />
            <Stack gap={6}>
              <Text tag="h5">Example Payload</Text>
              <Editor content={webhookExample()} lang="js" />
            </Stack>
          </>
        ) : null }
      </Stack>
    ),
    actions: EditWebhookActions,
    form: { defaultValues: webhook },
    ...rest,
  })
}

export {
  useWebhookDialog,
}
