import { toParams } from '@level'
import { useProject } from './useProject'
import { useFormQuery } from './useFormQuery'

const sendPreviewQuery = (props) => `mutation { sendPreview(${toParams(props)}) }`

const useSendPreview = ({ template }) => {
  const { Project } = useProject()

  const subject = template.type === 'email' ? `Enveloop Test: Email Template "${template.name}"` : null

  const query = () => sendPreviewQuery({
    slug: template.slug,
    project: Project.slug,
    team: Project.team.slug,
    subject,
    // User defined variables
    templateVariables: template.previewVars,
  })

  return useFormQuery({ query })
}

export {
  useSendPreview,
}
