import PropTypes from 'prop-types'
import { sizes } from '@level/constants/space'

// transform an object's values into spacing lookups
const spaceToSize = (props) => {
  if (Array.isArray(props)) return props.map(spaceToSize).join(' ')
  if (typeof props !== 'object') return sizes[props]

  const obj = {}
  Object.keys(props).forEach((key) => {
    obj[key] = sizes[props[key]]
  })
  return obj
}

// Expect integer values, not numbers as strings like '5'.
const validSizes = Object.keys(sizes).map((n) => {
  const num = parseInt(n, 10)
  return Number.isNaN(num) ? n : num
})

spaceToSize.propTypes = {
  space: PropTypes.oneOfType([
    PropTypes.oneOf(validSizes),
    PropTypes.arrayOf(PropTypes.oneOf(validSizes)),
  ]),
}

const flushStyle = ({ flush, style = {} }) => (
  validSizes.includes(flush) ? ({
    ...style,
    marginLeft: `-${spaceToSize(flush)}`,
    marginRight: `-${spaceToSize(flush)}`,
  }) : style
)

const gapSize = (props) => {
  if (typeof props === 'object') {
    return {
      gap: sizes[props.gap] || props.gap,
      rowGap: sizes[props.rowGap] || props.rowGap || sizes[props.gap] || props.gap,
    }
  }

  return sizes[props] || props
}

gapSize.propTypes = {
  gap: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export {
  spaceToSize,
  gapSize,
  validSizes,
  flushStyle,
}
