import React from 'react'
import PropTypes from 'prop-types'
import * as Ariakit from '@ariakit/react'
import { matchSorter } from 'match-sorter'

import { Block } from '../Block'
import { Stack } from '../Stack'
import { Divider } from '../Divider'
import { HiddenInput } from '../Input'
import { SectionLabel } from '../Text'
import { useFormRef } from '../../hooks/useForm'

const MultiSelect = ({
  name, label, items, match, itemDisplay, noResults, defaultValue, multi,
}) => {
  const [searchValue, setSearchValue] = React.useState('')
  const selectDefault = defaultValue && multi ? (defaultValue || '').split(',') : defaultValue || []

  const matches = React.useMemo(() => (
    (searchValue && matchSorter(items, searchValue, match)) || items
  ), [searchValue])

  const setSearch = (value) => {
    React.startTransition(() => {
      setSearchValue(value)
    })
  }

  const { setValue, submit } = useFormRef()

  const combobox = Ariakit.useComboboxStore({
    defaultOpen: !defaultValue,
    setValue: (value) => {
      setSearch(value)
    },
  })

  const select = Ariakit.useSelectStore({
    defaultValue: selectDefault,
    defaultActiveId: null,
    activeId: null,
  })

  const setInput = () => {
    setTimeout(() => {
      setValue(name, select.getState().value.join(','))
      setSearch('')
      combobox.setValue('')
      submit()
    }, 0)
  }

  return (
    <div className="wrapper">
      <HiddenInput name={name} defaultValue={defaultValue} />
      <Ariakit.ComboboxProvider store={combobox}>
        <Ariakit.SelectProvider store={select}>
          { label ? <Ariakit.SelectLabel>{ label }</Ariakit.SelectLabel> : null }
          <Stack gap={7}>
            <Ariakit.Combobox
              autoSelect
              placeholder="Search..."
              className="level-input level-combobox-input"
              showOnMouseDown={false}
              autoFocus
            />
            <Divider flush={7} />
            <SectionLabel>Templates</SectionLabel>
          </Stack>
          <Block flush={7} space={[5, 5, 0]} className="level-combobox-items" style={{ maxHeight: '40vh', overflowY: 'auto' }}>
            <Ariakit.ComboboxList alwaysVisible>
              { matches.length ? matches.map((value) => {
                const selected = select.getState().value
                const checked = selected.includes(itemDisplay(value))
                return (
                  <div className="level-select-item" data-item-checked={checked || null} key={itemDisplay(value)} data-active-item={checked || null}>
                    <Ariakit.SelectItem
                      value={itemDisplay(value)}
                      className="level-combobox-item"
                      setValueOnClick={() => {
                        setInput(itemDisplay(value))
                        return true
                      }}
                      render={<Ariakit.ComboboxItem />}
                    />
                    <Ariakit.SelectItemCheck checked={checked} />
                  </div>
                )
              }) : (
                <div className="level-combobox-no-results">{ noResults }</div>
              )}
            </Ariakit.ComboboxList>
          </Block>
        </Ariakit.SelectProvider>
      </Ariakit.ComboboxProvider>
    </div>
  )
}

MultiSelect.propTypes = {
  name: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  match: PropTypes.object,
  placeholder: PropTypes.string,
  itemDisplay: PropTypes.func,
  defaultValue: PropTypes.string,
  noResults: PropTypes.any,
  multi: PropTypes.bool,
  label: PropTypes.string,
}

MultiSelect.defaultProps = {
  match: undefined,
  placeholder: 'Search…',
  itemDisplay: (item) => item,
  defaultValue: undefined,
  noResults: 'No results found',
  label: undefined,
  multi: true,
}

export { MultiSelect }
