import React from 'react'
import PropTypes from 'prop-types'
import { useLocation, Routes } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { ScrollPanel } from '../ScrollPanel'

import './slide-routes.scss'

const getDirection = (prev, current) => {
  if (prev < current) return 'forward'
  if (current < prev) return 'backward'
  return 'neutral'
}

const useNavDirection = () => {
  const location = useLocation()
  const { pathname: path } = location
  const key = path.split('/').filter((p) => p).pop()
  // Get the depth of the path (treating /foo/ as equal to /foo)
  const depth = path.replace(/\/$/).split('/').length
  const [state, setState] = React.useState({
    depth, direction: 'neutral', path, key, location,
  })

  React.useEffect(() => {
    // If we're not still at the initial path
    if (state.key !== key) {
      setState({
        location,
        path,
        depth,
        key,
        direction: getDirection(state.depth, depth),
      })
    }
  }, [path])

  return state
}

const SlideRoutes = ({ children }) => {
  const { location, key, direction } = useNavDirection()

  return (
    <TransitionGroup className="slide-routes" data-direction={direction}>
      <CSSTransition key={key} classNames="slide" timeout={direction === 'neutral' ? 0 : 400}>
        <div className="slide-route">
          <ScrollPanel>
            <Routes location={location}>{children}</Routes>
          </ScrollPanel>
        </div>
      </CSSTransition>
    </TransitionGroup>
  )
}

SlideRoutes.propTypes = {
  children: PropTypes.node.isRequired,
}

export {
  SlideRoutes,
}
