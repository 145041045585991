import React from 'react'
import PropTypes from 'prop-types'

import { toColor } from '../../helpers/color'
import { spaceToSize } from '../../helpers/space'
import { useMessages } from '../../hooks/useMessages'

const Banner = ({
  background,
  border,
  color,
  space,
  ...props
}) => {
  const { publish } = useMessages({ channel: 'layout' })

  React.useEffect(() => {
    publish({ refresh: {} })

    return () => {
      publish({ refresh: {} })
    }
  }, [])

  return (
    <div
      className="level-banner"
      style={{
        borderBottom: `1px solid ${toColor(border)}`,
        background: toColor(background),
        color: toColor(color),
        padding: space ? spaceToSize(space) : null,
      }}
      {...props}
    />
  )
}

Banner.propTypes = {
  background: PropTypes.string,
  border: PropTypes.string,
  color: PropTypes.string,
  space: spaceToSize.propTypes.space,
}

Banner.defaultProps = {
  background: 'neutral-50',
  border: 'neutral-100',
  color: 'text',
  space: [4, 6],
}

export {
  Banner,
}
