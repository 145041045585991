import React from 'react'
import PropTypes from 'prop-types'
import { DragContext, SortableContext, useGridCollisionDetection } from '../../Draggable'
import { mergeEvents } from '../../../helpers'

const findSectionColumn = ({ sections, column }) => (
  sections.find((section) => (
    section.variables.columns.find((col) => col.id === column)
  )))

const getChangeData = (sections, { container, index }) => {
  const section = findSectionColumn({ sections, column: container })
  const sectionIndex = sections.findIndex((sec) => sec.variables.id === section.variables.id)
  const columnIndex = section.variables.columns.findIndex(({ id }) => id === container)
  return {
    columnIndex,
    sectionIndex,
    sectionId: section.variables.id,
    elementIndex: index,
    elementId: section.variables.columns[columnIndex].elements?.[index]?.id,
  }
}

const ElementDragContext = ({
  sections, onChangeSections, onChangeElements, onComplete, children, ...props
}) => {
  const containers = sections
    .filter(({ sharedSectionId }) => !sharedSectionId)
    .flatMap(({ variables }) => variables.columns)
  const elementTransformer = (container) => container.elements
    .filter((e) => e?.id)
    .map(({ id }) => id)
  const {
    collisionDetection,
    ...collisionEvents
  } = useGridCollisionDetection({
    containers, // reduce sections to section columns
    containerIdTransformer: (container) => container.id,
    containerDataIdTransformer: elementTransformer,
    onChange: (start, end) => {
      const from = getChangeData(sections, start)
      const to = getChangeData(sections, end)
      onChangeElements({ from, to })
    },
    onDragEnd: (start, end) => {
      const from = getChangeData(sections, start)
      const to = getChangeData(sections, end)
      onChangeElements({ from, to })
    },
    onComplete,
  })

  const onDragEnd = ({ active, over }) => {
    if (active.id.startsWith('section') && active.id !== over.id) {
      const from = sections.findIndex((s) => active.id === s.variables.id)
      const to = sections.findIndex((s) => over.id === s.variables.id)
      if (to !== -1) onChangeSections({ from, to })
    }
  }
  const sortableSections = sections.map((s) => s.variables.id)

  return (
    <DragContext
      sortable
      collisionDetection={collisionDetection}
      {...mergeEvents(collisionEvents, props, { onDragEnd })}
    >
      <SortableContext id="sortable-sections" items={sortableSections}>
        { children }
      </SortableContext>
    </DragContext>
  )
}

ElementDragContext.propTypes = {
  sections: PropTypes.array.isRequired,
  onChangeSections: PropTypes.func.isRequired,
  onChangeElements: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
}

export {
  ElementDragContext,
}
