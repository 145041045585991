import React from 'react'
import PropTypes from 'prop-types'

import { TeamAvatar } from '@app/components'

import {
  Stack, Editor, Block,
  Phone, PhoneText, PhoneImages, Loading,
  Tabs, TabList, Tab, TabPanels, TabPanel, Card,
  Notice,
} from '@level'

import { Event } from './event'

const MessageCode = ({ notice, children }) => (
  <Block space={7}>
    <Stack gap={6}>
      <Notice space={5} theme="info" icon={{ name: 'circle-info', gap: 4, size: 2 }} text={notice} />
      { children }
    </Stack>
  </Block>
)

MessageCode.propTypes = {
  notice: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

const MessageIframe = ({ content }) => (
  <iframe
    style={{
      minHeight: '100%',
      width: '100%',
      border: '0',
      margin: 0,
    }}
    title="message-preview"
    srcDoc={content.replace(/<a/g, '<a target="_blank"')}
  />
)

MessageIframe.propTypes = {
  content: PropTypes.string.isRequired,
}

const EmailMessage = ({ message }) => {
  const { templateVariables, text, body } = message
  const tabs = [{
    label: 'HTML',
    content: <MessageIframe content={body} />,
  }]
  if (text) {
    tabs.push({
      label: 'Plain Text',
      content: <MessageCode notice="Clients without HTML support will show plain text content."><Card space={8} style={{ whiteSpace: 'pre-wrap' }}><code>{text}</code></Card></MessageCode>,
    })
  }
  if (templateVariables) {
    tabs.push({
      label: 'Variables',
      content: <MessageCode notice="These variables were present in the API call to send this message."><Editor content={JSON.stringify(templateVariables, null, 2)} lang="json" /></MessageCode>,
    })
  }

  return (
    <Tabs theme="nav-panel">
      <div className="level-tab-list-wrapper">
        <TabList>
          { tabs.map(({ label }) => (
            <Tab key={label}>{label}</Tab>
          ))}
        </TabList>
      </div>
      <TabPanels>
        { tabs.map(({ label, content }) => (
          <TabPanel key={label}>{content}</TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  )
}

EmailMessage.propTypes = {
  message: PropTypes.shape({
    body: PropTypes.string.isRequired,
    text: PropTypes.string,
    templateVariables: PropTypes.object,
  }).isRequired,
}

const Message = ({ message, mode, isLoading }) => {
  if (isLoading) {
    return <div className="message-preview-loading"><Loading size={5} /></div>
  }

  if (mode === 'preview') {
    // Full message data hasn't come in yet, so render loading
    if (!message?.type) return <Loading />

    if (message?.type === 'email') {
      return <EmailMessage message={message} />
    }
    if (message?.type === 'sms') {
      return (
        <Phone
          photo={<TeamAvatar shape="circle" />}
          number={message.from}
        >
          { message.mediaUrls?.length ? (
            <PhoneImages images={message.mediaUrls} />
          ) : null}
          <PhoneText text={message.body} />
        </Phone>
      )
    }
  }

  const event = message.computedMessageEvents.find(({ id }) => id === mode)
  if (event) {
    return <Event {...event} lang="json" />
  }

  return null
}

Message.propTypes = {
  message: PropTypes.object,
  mode: PropTypes.any,
  isLoading: PropTypes.bool.isRequired,
}

Message.defaultProps = {
  message: undefined,
  mode: undefined,
}

export {
  Message,
}
