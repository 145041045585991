import React from 'react'

import {
  Stack, Text, SettingsGrid, Table, Editor, Button,
  useTable, useDialog,
} from '@level'

import { useProject, QueryProvider } from '@app/hooks'
import { eventWebhooksQuery, EventWebhooksContext, useEventWebhooks } from './queries'
import {
  ToggleEnabled, EventsCell, URLCell, IncludeBodyCell,
} from './_cells'
import { AddWebhook } from './add'
import { webhookExample } from './webhookExample'

const EventWebhooksTable = () => {
  const { Project } = useEventWebhooks()
  const { eventWebhooks } = Project

  const columnNames = React.useMemo(() => [
    { enabled: { header: 'Enabled', cell: ToggleEnabled } },
    { url: { header: 'Webhook URL', width: '60%', cell: URLCell } },
    { includeBody: { header: 'Message', align: 'center', cell: IncludeBodyCell } },
    { events: { type: 'display', width: '30%', cell: EventsCell } },
  ], [])

  const table = useTable({
    columnNames,
    data: eventWebhooks,
  })

  return (
    <Stack gap={6}>
      <Table
        table={table}
        blankSlate={(
          <Stack space={5} gap={5}>
            <Text tag="h4">No Webhooks</Text>
            <Text color="neutral-400">Add a webhook to be notified when messages are delivered, opend, and more.</Text>
          </Stack>
        )}
      />
      <AddWebhook style={{ alignSelf: 'end' }} />
    </Stack>
  )
}

const EventWebhooks = () => {
  const { Project } = useProject()
  const sendDialog = useDialog()

  const showData = () => sendDialog({
    title: 'Webhook example payload',
    content: <Editor content={webhookExample()} lang="js" />,
    space: 0,
  })

  return (
    <SettingsGrid>
      <Stack gap={6}>
        <Text tag="h3">Webhooks</Text>
        <Text>
          Add Webhooks for monitoring and responding to message events.
        </Text>
        <Button onClick={showData} theme="wrapper-link">See example payload</Button>
      </Stack>
      <QueryProvider
        query={eventWebhooksQuery({ Project })}
        context={EventWebhooksContext}
      >
        <EventWebhooksTable />
      </QueryProvider>
    </SettingsGrid>
  )
}

export {
  EventWebhooks,
}
