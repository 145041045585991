// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23474747%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".level-select{font-family:system-ui;-webkit-appearance:none;font-size:13px;width:100%;line-height:1.5em;padding:5px 10px;padding-right:1.8em;max-width:100%;margin:0;appearance:none;border:1px solid #dedfe2;border-radius:2px;background-color:#fff;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-position:right .7em top 50%;background-size:.65em auto,100%}.level-select:focus-visible{border-color:#5a5b5e;outline:3px solid rgba(59,153,252,.7);outline:3px solid -moz-mac-focusring;color:#222}.level-select:hover{cursor:pointer;background-color:rgba(236,240,253,.6);border-color:#afb1b5}.level-select-item{border-radius:.25rem;position:relative}.level-select-item:hover,.level-select-item [data-active-item]+span{color:#fff}.level-select-item .level-combobox-item{padding-left:20px}.level-select-item span:last-child{position:absolute;left:4px;top:.65rem}", "",{"version":3,"sources":["webpack://./level/components/Select/select.scss"],"names":[],"mappings":"AAIA,cACE,qBAAA,CACA,uBAAA,CACA,cAAA,CACA,UAAA,CACA,iBAAA,CACA,gBAAA,CACA,mBAAA,CACA,cAAA,CACA,QAAA,CACA,eAAA,CACA,wBAAA,CACA,iBAAA,CACA,qBAAA,CACA,wDAAA,CACA,2BAAA,CACA,sCAAA,CACA,+BAAA,CAEA,4BACE,oBAAA,CACA,qCAAA,CACA,oCAAA,CACA,UAAA,CAEF,oBACE,cAAA,CACA,qCAAA,CACA,oBAAA,CAIJ,mBACE,oBAAA,CACA,iBAAA,CAEA,oEACE,UAAA,CAEF,wCACE,iBAAA,CAEF,mCACE,iBAAA,CACA,QAAA,CACA,UAAA","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
