import React from 'react'
import PropTypes from 'prop-types'
import { Link } from '../Link'
import './navlist.scss'

// Lets us set a default link props (like icon size) for nav items
const defaultLinkProps = ({ icon: iconProps, ...props }) => {
  if (!iconProps) return props
  const icon = (typeof iconProps === 'string')
    ? { name: iconProps, size: 3 }
    : { size: 3, ...iconProps }

  return ({ ...props, icon })
}

const NavList = ({
  navItems, theme, className, ...props
}) => (navItems?.length ? (
  <ul className={`level-nav-list ${className}`} data-theme={theme} {...props}>
    { navItems.map(({
      text, children, feature, ...rest
    }) => (feature !== false ? (
      <li key={rest.to || text} className="level-nav-list-item">
        <Link theme={theme} {...defaultLinkProps(rest)} text={children || text} />
      </li>
    ) : null))}
  </ul>
) : null)

NavList.propTypes = {
  className: PropTypes.string,
  navItems: PropTypes.array,
  theme: PropTypes.string,
}

NavList.defaultProps = {
  className: '',
  navItems: [],
  theme: null,
}

export {
  NavList,
}
