import React from 'react'
import { useLocalStorage } from 'beautiful-react-hooks'
import { useTeam } from '@app/hooks'

import { TestEmailTemplate } from '../email'
import { TestSMSTemplate } from '../sms'
import {
  TemplateTestContext,
  useTemplate,
} from '../context'

const TemplateTest = () => {
  const { refresh, getData } = useTemplate()
  const { template } = getData()
  const [previewVars, setPreviewVars] = useLocalStorage(`template-${template.id}-editor-vars`, {})
  const { Team } = useTeam()

  // Ensure we've got updated previewTemplateVariables from query
  React.useEffect(() => { refresh() }, [])

  const value = React.useMemo(
    () => ({
      ...template,
      config: template.previewConfig,
      previewVars,
      setPreviewVars,
      hooks: { watermark: Team.plan.watermark },
    }),
    [template.previewConfig, previewVars],
  )

  return (
    <TemplateTestContext.Provider value={value}>
      { template.type === 'email' ? <TestEmailTemplate template={value} /> : null }
      { template.type === 'sms' ? <TestSMSTemplate template={value} /> : null }
    </TemplateTestContext.Provider>
  )
}

export { TemplateTest }
