import React from 'react'
import {
  Routes,
  Route,
  Navigate,
} from 'react-router-dom'

import { NotFound } from '@level'

import { AccountSettings } from './settings'
import { AccountNew } from './new'
import { Authed } from '../auth'
import { AccountLayout } from './layout'

const AccountIndex = () => (
  <Authed>
    <Routes>
      <Route index element={<Navigate to="./settings" replace />} />
      <Route path="new/*" element={<AccountNew />} />
      <Route path="*" element={<AccountLayout />}>
        <Route path="settings" element={<AccountSettings />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  </Authed>
)

export {
  AccountIndex,
}
