import { postFormData } from '@level/helpers'

const uploadFile = async (file, url = `${process.env.API_BASE_URL}/upload`) => {
  const result = await postFormData({
    url,
    data: { file },
  })
  return result.json()
}

export {
  uploadFile,
}
