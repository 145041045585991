import React from 'react'

import { postJson } from '@level/helpers'
import { useToast } from '@level'

const sendLinkUrl = `${process.env.API_BASE_URL}/auth/sendMagicLink`
const veirfySignInTokenUrl = `${process.env.API_BASE_URL}/auth/verify`
const devUser = process.env.DEV_USER

const AuthContext = React.createContext()
const useAuth = () => React.useContext(AuthContext)
const useAuthToken = () => useAuth()?.token

const useSendAuthLink = () => {
  const { sendError } = useToast()
  const [sent, setSent] = React.useState()

  const sendLink = React.useCallback(async (email) => {
    const { data } = await postJson({
      url: sendLinkUrl,
      data: { email },
      handleErrors: (error) => sendError({ message: error }),
    })
    if (data?.status === 200) setSent(email)
  }, [])

  return { sendLink, sent }
}

// Verify sign-in token which comes from a magic link
// { token, tokenType } // Comes from Stytch
// { jwt } comes from us via addon
const useVerifySignInToken = (opts = {}) => {
  const {
    token = opts.jwt,
    tokenType = 'jwt',
  } = opts
  const { setToken } = useAuth()
  const [verified, setVerified] = React.useState()
  const { sendError } = useToast()

  React.useEffect(() => {
    (async () => {
      const verify = (jwt) => {
        if (jwt) setToken(jwt)
        setVerified(!!jwt)
      }

      if (token) {
        try {
          const { data } = await postJson({
            url: veirfySignInTokenUrl,
            data: { token, tokenType },
            handleErrors: (error) => sendError({ message: error }),
          })
          const { jwt } = data

          if (jwt) verify(jwt)
          else verify(false)
        } catch (e) {
          verify(false)
        }
      }
    })()
  }, [token])

  return verified
}

const useAuthedHeaders = () => {
  const token = useAuthToken()
  const { asUser } = useAuth()
  const headers = {}
  if (token) headers.Authorization = `Bearer ${token}`
  if (asUser) headers['Impersonated-User'] = asUser
  if (!token && devUser) headers['Dev-User'] = devUser

  return headers
}

export {
  useAuth,
  useAuthToken,
  useSendAuthLink,
  useVerifySignInToken,
  useAuthedHeaders,
  AuthContext,
}
