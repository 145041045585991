import React from 'react'
import { Navigate } from 'react-router-dom'

import {
  Loading,
  useSearchState,
} from '@level'

import {
  useVerifySignInToken,
} from '@app/hooks'

const Verify = () => {
  const [{
    token, stytch_token_type: tokenType, jwt, redirect_to: redirectTo = '/',
  }] = useSearchState()
  const verified = useVerifySignInToken({ token, tokenType, jwt })

  // Let the app root route the verified user
  if (verified) {
    return <Navigate to={redirectTo} />
  }

  // User with inactive session hit this url somehow
  if (verified === false) {
    return <Navigate to="/auth/new" />
  }

  // Waiting for verification
  return <Loading />
}

export {
  Verify,
}
