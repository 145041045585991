import React from 'react'
import PropTypes from 'prop-types'
import { ScrollPanel } from '../../../ScrollPanel'
import { includeKeys } from '../../../../helpers/object'

import { ConfigForm } from './Form'
import { configDefaults, inputLayouts } from './defaults'

const TemplateStyle = ({
  slug,
  templateConfig,
  updateTemplate,
}) => {
  const onChange = (changes) => {
    updateTemplate({
      style: includeKeys(changes, configDefaults.template.style),
    })
  }
  const style = { ...configDefaults.template.style, ...templateConfig.variables.style }

  return (
    <ScrollPanel>
      <ConfigForm
        id={slug}
        config={includeKeys(style, configDefaults.template.style)}
        configLayout={inputLayouts.template}
        onChange={onChange}
        space={7}
      />
    </ScrollPanel>
  )
}

TemplateStyle.propTypes = {
  slug: PropTypes.string.isRequired,
  templateConfig: PropTypes.object.isRequired,
  updateTemplate: PropTypes.func.isRequired,
}

export {
  TemplateStyle,
}
