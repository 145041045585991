import React from 'react'
import PropTypes from 'prop-types'
import { useMaxSize } from '../../hooks/useMaxSize'
import { spaceToSize } from '../../helpers'

const ScrollPanel = React.forwardRef(({
  as: Tag,
  rootRef,
  withinParent,
  footerRef: footerRefProp,
  limitHeight,
  limitWidth,
  offsetSpace,
  footer,
  style,
  ...props
}, ref) => {
  const footerRef = footerRefProp || React.useRef()
  const { height, width, contentRef } = useMaxSize({
    contentRef: ref, rootRef, footerRef, offsetSpace, withinParent,
  })

  const main = (
    <Tag
      style={{
        height: limitHeight ? null : height,
        maxHeight: height,
        maxWidth: limitWidth ? width : null,
        overflowY: 'auto',
        ...style,
      }}
      ref={contentRef}
      {...props}
    />
  )

  if (!footer) return main
  return (
    <>
      { main }
      <div ref={footerRef}>{ footer }</div>
    </>
  )
})

ScrollPanel.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  style: PropTypes.object,
  footerRef: PropTypes.object,
  limitWidth: PropTypes.bool,
  limitHeight: PropTypes.bool,
  offsetSpace: spaceToSize.propTypes.space,
  rootRef: PropTypes.object,
  footer: PropTypes.any,
  withinParent: PropTypes.bool,
}

ScrollPanel.defaultProps = {
  as: 'div',
  style: {},
  offsetSpace: 0,
  footerRef: undefined,
  limitWidth: undefined,
  limitHeight: undefined,
  rootRef: undefined,
  footer: undefined,
  withinParent: undefined,
}

export {
  ScrollPanel,
}
