import React from 'react'
import PropTypes from 'prop-types'
import { Outlet } from 'react-router-dom'

const Main = ({ children, drawer }) => (
  <div className="level-layout-main" data-drawer={!!drawer}>
    <div className="level-layout-content">
      { children || <Outlet />}
    </div>
    { drawer }
  </div>
)

Main.propTypes = {
  children: PropTypes.node,
  drawer: PropTypes.node,
}

Main.defaultProps = {
  children: null,
  drawer: null,
}

export {
  Main,
}
