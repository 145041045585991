import React from 'react'
import PropTypes from 'prop-types'
import { Stack } from '../Stack'
import { Text } from '../Text'
import { useFormRef, useUuid, useDebounce } from '../../hooks'
import { camelToTitle, formValueEquivalent } from '../../helpers'
import { Button } from '../Button'

// 'fullName' => 'Full Name'
const deriveLabel = ({ label, name }) => (label === true ? camelToTitle(name) : label) || ''

const Label = ({ label, inputId, labelText }) => (
  label ? (
    <label
      className="level-label"
      htmlFor={inputId}
      data-type={typeof labelText !== 'string' ? 'node' : null}
    >{labelText}
    </label>
  ) : null
)

Label.propTypes = {
  labelText: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.node]).isRequired,
  inputId: PropTypes.string.isRequired,
}

const InputWithDescription = ({
  inputTag, description, descriptionAfter, error, labelTag,
}) => {
  const input = description || descriptionAfter ? (
    <Stack gap={4} valign="center">
      { description ? <Text size={1} color="neutral-400">{ description }</Text> : null }
      { inputTag }
      { descriptionAfter ? <Text size={1} color="neutral-400">{ descriptionAfter }</Text> : null }
    </Stack>
  ) : inputTag

  if (!error && !labelTag) return input

  return (
    <Stack gap={4}>
      { labelTag }
      { input }
      { error ? <div className="level-input-error-message">{error}</div> : null }
    </Stack>
  )
}

InputWithDescription.propTypes = {
  inputTag: PropTypes.node.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  descriptionAfter: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  error: PropTypes.string,
  labelTag: PropTypes.node,
}

InputWithDescription.defaultProps = {
  description: undefined,
  descriptionAfter: undefined,
  error: undefined,
  labelTag: undefined,
}

const Input = React.forwardRef(({
  name,
  placeholder,
  messages,
  required: Required,
  pattern: Pattern,
  className,
  validate: validateProp,
  label,
  submitOnBlur,
  submitOnChange,
  size,
  type,
  id,
  onBlur: onBlurProp,
  onClear,
  onChange: onChangeProp,
  tag: Tag,
  description,
  descriptionAfter,
  theme,
  autoComplete,
  errorMessage,
  valueAsNumber,
  valueAsDate,
  setValueAs: setValueAsProp,
  inlineLabel,
  showClear,
  trim,
  ...rest
}, ref) => {
  const uuid = useUuid()
  const labelText = deriveLabel({ label, name })
  const required = Required ? (messages.required || `${labelText} is required`) : null
  const pattern = Pattern ? {
    value: Pattern,
    message: messages.pattern || `${labelText} is not valid`,
  } : null
  const formRef = useFormRef()
  // Allow easy trimming of text content
  const setValueAs = setValueAsProp
      || (trim ? ((value) => (value?.trim ? value.trim() : value)) : null)

  // Wrap custom validation to pass formRef, allowing an input to validate in context
  // with form attributes or the reading of other input values
  const validate = validateProp
    ? (...args) => validateProp(...args, formRef)
    : undefined

  const {
    submit, register, formState, onChangeInput, setValue,
  } = formRef
  const inputId = id || `input-${uuid}`
  const focusedValue = React.useRef()

  const error = formState?.errors[name]?.message || errorMessage
  const debounceSubmit = useDebounce(submit, [], 500)

  // Allow input changes to submit form
  const onChange = (event) => {
    const currentValue = setValueAs ? setValueAs(event.target.value) : event.target.value
    const e = { ...event, target: { ...event.target, value: currentValue } }
    onChangeInput?.({ [name]: currentValue })
    onChangeProp?.(e, formRef)
    if (submitOnChange && submit && !formValueEquivalent(currentValue, focusedValue)) {
      if (type === 'text' || Tag === 'textarea') {
        debounceSubmit()
      } else {
        submit()
      }
      focusedValue.current = event.target.value
    }
  }

  // Allow blur to submit form if input value has changed
  const onBlur = submitOnBlur && submit ? (event) => {
    if (!formValueEquivalent(event.target.value, focusedValue)) {
      submit()
      focusedValue.current = event.target.value
      if (onChangeProp) onChangeProp(event, formRef)
    }
    if (onBlurProp) onBlurProp(event, formRef)
  } : onBlurProp

  const inputRef = register ? register(name, {
    ref,
    required,
    pattern,
    validate,
    onChange,
    onBlur,
    valueAsNumber: ['number', 'range'].includes(type) || valueAsNumber,
    valueAsDate,
    setValueAs,
  }, [onChange, onBlur]) : {} // *, formState.errors[name]*/

  const onFocus = (event) => {
    focusedValue.current = event.target.value
  }

  let inputTag = (
    <Tag
      id={inputId}
      type={type}
      name={name}
      data-size={size}
      placeholder={placeholder || !label ? (placeholder || labelText || '') : ''}
      className={`level-input ${className || ''} ${error ? 'level-input-error' : ''}`}
      data-theme={theme}
      autoComplete={autoComplete === false ? 'none' : autoComplete}
      onFocus={onFocus}
      onChange={!submit ? onChangeProp : null}
      {...inputRef}
      {...rest}
    />
  )

  // An input (of type search or showClear = true) will have a button which clears its value
  // This will also fire an onClear hook if you need to do something when an input clears
  if (type === 'search' || showClear) {
    inputTag = (
      <div className="level-input-clear-wrapper">
        { inputTag }
        <Button
          className="level-input-clear"
          icon="x-light"
          label="clear input"
          theme="ghost"
          onClick={(e) => {
            const { target } = e
            const searchInput = target.closest('button').previousElementSibling
            if (setValue) {
              setValue(name, '', { shouldDirty: true })
            } else { searchInput.value = '' }
            searchInput.focus()
            if (onClear) { onClear({ target, ...formRef }) }
          }}
        />
      </div>
    )
  }

  const labelTag = label === false ? null : <Label {...{ label, inputId, labelText }} />

  if (inlineLabel) {
    return (
      <>
        { labelTag }
        { description === null ? inputTag : (
          <InputWithDescription {...{ inputTag, description, error }} />
        )}
      </>
    )
  }

  return (
    <InputWithDescription {...{
      inputTag,
      description,
      descriptionAfter,
      error,
      labelTag,
    }}
    />
  )
})

Input.propTypes = {
  tag: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  pattern: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  validate: PropTypes.func,
  label: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  messages: PropTypes.shape({
    pattern: PropTypes.string,
    min: PropTypes.string,
    max: PropTypes.string,
    required: PropTypes.string,
  }),
  size: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
  submitOnChange: PropTypes.bool,
  submitOnBlur: PropTypes.bool,
  inlineLabel: PropTypes.bool,
  onBlur: PropTypes.func,
  onClear: PropTypes.func,
  onChange: PropTypes.func,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  descriptionAfter: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  theme: PropTypes.string,
  autoComplete: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  errorMessage: PropTypes.string,
  valueAsNumber: PropTypes.bool,
  valueAsDate: PropTypes.bool,
  setValueAs: PropTypes.func,
  showClear: PropTypes.bool,
  trim: PropTypes.bool,
}

Input.defaultProps = {
  tag: 'input',
  type: 'text',
  id: undefined,
  size: 4,
  label: true,
  messages: {},
  onBlur: undefined,
  onClear: undefined,
  showClear: undefined,
  onChange: undefined,
  validate: undefined,
  required: false,
  placeholder: undefined,
  pattern: undefined,
  className: undefined,
  submitOnChange: false,
  submitOnBlur: false,
  inlineLabel: undefined,
  description: undefined,
  descriptionAfter: undefined,
  theme: undefined,
  autoComplete: undefined,
  errorMessage: undefined,
  valueAsNumber: undefined,
  valueAsDate: undefined,
  setValueAs: undefined,
  trim: undefined,
}

const Textarea = (props) => <Input tag="textarea" rows={6} {...props} />

export {
  deriveLabel,
  Input,
  Textarea,
}
