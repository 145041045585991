import React from 'react'
import PropTypes from 'prop-types'

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useDndContext,
  useSensor,
  useSensors,
} from '@dnd-kit/core'

import {
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable'

const DragContext = ({
  sortable,
  ...props
}) => {
  let sensors = null
  if (sortable) {
    sensors = useSensors(
      useSensor(PointerSensor),
      useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates,
      }),
    )
  }
  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      {...props}
    />
  )
}

DragContext.propTypes = {
  sortable: PropTypes.bool,
}

DragContext.defaultProps = {
  sortable: false,
}

export {
  DragContext,
  useDndContext,
}
