import React from 'react'
import { randomString } from '@level/helpers'
import { useQuery } from './useQuery'

const useRandomScramble = ({ word, setWord }) => {
  const length = React.useRef()
  const result = React.useRef()
  const executions = React.useRef()

  // Update the callback with a new partially random word
  const scramble = () => {
    // Use the current result + a random set of characters with the remaining length
    setWord(result.current + randomString(length.current - result.current.length))
  }

  React.useEffect(() => {
    let interval
    if (word) {
      result.current = ''
      executions.current = 0
      length.current = word.length
      interval = window.setInterval(() => {
        // Time to pluck a new character from the source word
        if (executions.current % 2 === 0) result.current += word[result.current.length]

        // Update the callback with a new partially random word
        scramble()

        // End condition: The word has been constructed
        if (result.current === word) window.clearInterval(interval)
        executions.current += 1
      }, 24)
    }

    return () => { if (interval) window.clearInterval(interval) }
  }, [word])
}

const useRandomSlug = () => {
  const [generate, { data }] = useQuery('randomSlug { name, slug }')

  React.useEffect(() => {
    generate()
  }, [])

  return {
    generate,
    name: data?.randomSlug?.name,
    slug: data?.randomSlug?.slug,
  }
}

export {
  useRandomSlug,
  useRandomScramble,
}
