import React from 'react'

import { useProfile, useAsUser } from '@app/hooks'
import {
  toColor, Shelf, Avatar, Button, Column,
} from '@level'

const AccountNav = () => {
  const { asUser } = useAsUser()
  const { Profile } = useProfile()
  const { name, email } = Profile
  return (
    <Shelf align="split" valign="center">
      <Button to="/account" theme="ghost">
        <Shelf valign="center" gap={4}>
          <Avatar shape="circle" src={Profile.avatar?.url} size={2} name={name} email={email} gradient />
          { name }
        </Shelf>
      </Button>
      <Column style={{ borderLeft: `1px solid ${toColor('neutral-100')}` }}>
        <Button theme="ghost" to={asUser ? '/as/me' : '/auth/signOut'} label="sign out" icon="power-off" />
      </Column>
    </Shelf>
  )
}

export {
  AccountNav,
}
