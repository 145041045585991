import React from 'react'
import { useInterval } from 'beautiful-react-hooks'
import { timeAgoOrDate } from '../helpers/date'

const useLiveDate = ({ date, format = timeAgoOrDate, interval = 60000 }) => {
  const [dateText, setDateText] = React.useState(format(date))

  useInterval(() => {
    setDateText(format(date))
  }, interval)

  React.useEffect(() => {
    setDateText(format(date))
  }, [date])

  return dateText
}

export {
  useLiveDate,
}
