import React from 'react'
import PropTypes from 'prop-types'

import { Form } from '../Form'
import { Input } from '../Input'
import { Shelf } from '../Shelf'
import { Button } from '../Button'
import { useSearchState } from '../../hooks'

const TextFilter = ({ name, placeholder }) => {
  const [params, setParams] = useSearchState()
  const current = params?.[name] || null
  const onSubmit = ({ data: { [name]: value } }) => {
    setParams.merge({ [name]: value })
  }

  return (
    <Form onSubmit={onSubmit} className="level-filter-form">
      <Shelf gap={3}>
        <Input
          style={{ width: '190px' }}
          onClear={({ submit }) => { submit() }}
          type="search"
          name={name}
          label={false}
          placeholder={placeholder}
          defaultValue={current}
        />
        <Button debounceClick type="submit" icon="check" label="submit" />
      </Shelf>
    </Form>
  )
}

TextFilter.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
}

export {
  TextFilter,
}
