import React from 'react'
import PropTypes from 'prop-types'

import { ConfigForm } from '../Form'
import { Divider } from '../../../../Divider'
import { ScrollPanel } from '../../../../ScrollPanel'

import { useTemplateConfig } from '../useTemplateConfig'
import { getElementDefault, getElementConfigLayout } from '../../Element'
import { ElementConfigHeader } from './header'

const ElementConfigForm = ({ element, section, sharedSection }) => {
  const { updateElement } = useTemplateConfig()
  const defaultConfig = getElementDefault(element)
  const configLayout = getElementConfigLayout(element)
  const onChange = (update) => {
    updateElement({ element, update })
  }

  return (
    <>
      <ElementConfigHeader element={element} section={section} />
      <Divider />
      <ScrollPanel>
        <ConfigForm
          id={`${section.variables.id}-${element.id}`}
          config={{ ...defaultConfig, ...element.config }}
          configLayout={configLayout}
          onChangeInput={onChange}
          space={7}
          context={element}
          focusDelay={450}
          reset={element.id}
          theme={section.variables._theme}
          section={section}
          sharedSection={sharedSection}
        />
      </ScrollPanel>
    </>
  )
}

ElementConfigForm.propTypes = {
  element: PropTypes.object.isRequired,
  section: PropTypes.object.isRequired,
  sharedSection: PropTypes.object,
}

ElementConfigForm.defaultProps = {
  sharedSection: undefined,
}

export {
  ElementConfigForm,
}
