import React from 'react'
import PropTypes from 'prop-types'

import { useFormRef, useUuid } from '../../hooks'

import { Stack } from '../Stack'
import { Shelf } from '../Shelf'
import { Grid } from '../Grid'
import { Text } from '../Text'
import { deriveLabel } from './Input'

import './checkinput.scss'

const useCheckInput = (props) => {
  const {
    id,
    name,
    className,
    label,
    size,
    onChange: onChangeProp,
    theme,
    submitOnChange,
    errorMessage,
    type,
    ...rest
  } = props

  const uuid = useUuid()
  const labelText = deriveLabel({ label, name })
  const formRef = useFormRef()
  const {
    submit, register, formState, onChangeInput,
  } = formRef
  const inputId = id || `input-${uuid}`
  const error = formState?.errors[name]?.message || errorMessage

  const [checked, setChecked] = React.useState(rest.defaultChecked)

  React.useEffect(() => {
    const input = document.querySelector(`#${inputId}`)
    if (typeof rest.defaultChecked !== 'undefined') {
      input.checked = rest.defaultChecked
    }
    setChecked(input.checked)
  }, [rest.defaultChecked])

  // Allow input changes to submit form
  const onChange = (event) => {
    setChecked(event.target.checked)
    if (submitOnChange && submit) submit()
    onChangeInput?.({ [name]: event.target.checked })
    onChangeProp?.(event, formRef)
  }

  const inputRef = register ? register(name, { onChange }, [onChange]) : {}

  const inputProps = {
    id: inputId,
    type,
    name,
    'data-size': size,
    onChange,
    className: `level-input visually-hidden ${className || ''} ${error ? 'level-input-error' : ''}`,
    ...inputRef,
    ...rest,
  }

  return {
    ...props,
    inputId,
    checked,
    inputProps,
    label: labelText,
  }
}

const checkPropTypes = {
  type: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.node]),
  size: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  onChange: PropTypes.func,
  theme: PropTypes.string,
  submitOnChange: PropTypes.bool,
  icon: PropTypes.node,
}

const checkDefualtProps = {
  size: 4,
  label: true,
  type: undefined,
  id: undefined,
  onChange: undefined,
  theme: undefined,
  className: undefined,
  errorMessage: undefined,
  submitOnChange: undefined,
  icon: undefined,
}

const CheckInput = (props) => {
  const {
    inputProps, label, checked, error, inputId, theme, type, reverse, icon,
  } = useCheckInput(props)

  const labelNode = label ? (
    <Shelf
      gap={4}
      className="level-label"
      data-type={typeof label !== 'string' ? 'node' : null}
    >{icon}{label}
    </Shelf>
  ) : null

  const columns = `${reverse ? '1fr' : ''} min-content ${reverse ? '' : '1fr'}`

  return (
    <Stack gap={4} data-theme={theme} data-checked={checked}>
      <div className="level-input-wrapper" data-input-type={type}>
        <Grid tag="label" htmlFor={inputId} gap={4} templateColumns={columns}>
          { reverse ? labelNode : null}
          <React.Fragment>
            <input {...inputProps} />
            <div className="level-input-control" />
          </React.Fragment>
          { reverse ? null : labelNode }
        </Grid>
      </div>
      { error ? <div className="level-input-error-message">{error}</div> : null }
    </Stack>
  )
}

CheckInput.propTypes = checkPropTypes
CheckInput.defaultProps = checkDefualtProps

const CheckButton = ({ icon, ...props }) => {
  const {
    inputProps, inputId, label, checked,
  } = useCheckInput(props)

  return (
    <label htmlFor={inputId} className="level-check-button" data-checked={checked}>
      <input {...inputProps} />
      <Text icon={icon} iconGap={4}>{label}</Text>
    </label>
  )
}

CheckButton.propTypes = {
  ...checkPropTypes,
  icon: Text.propTypes.icon,
}

CheckButton.defaultProps = {
  ...checkDefualtProps,
  type: 'checkbox',
  icon: undefined,
}

const CheckboxInput = (props) => <CheckInput type="checkbox" {...props} />
const RadioInput = (props) => <CheckInput type="radio" {...props} />
const CheckSwitch = (props) => <CheckboxInput {...props} className="level-check-switch" />

const RadioButton = (props) => {
  const {
    inputProps, inputId, label, checked,
  } = useCheckInput(props)

  return (
    <label className="level-check-button" htmlFor={inputId} data-checked={checked}>
      <input {...inputProps} type="radio" />
      { label }
    </label>
  )
}

export {
  CheckboxInput,
  CheckSwitch,
  RadioInput,
  CheckButton,
  RadioButton,
}
