import React from 'react'
import { createRoot } from 'react-dom/client'

import {
  BrowserRouter,
} from 'react-router-dom'

import './index.scss'
import '@app/assets/favicons/favicons'
import { BugsnagProvider } from '@app/components/Bugsnag'
import { WebSocketProvider } from '@app/hooks/useWebSocket'
import { AppIndex } from '@app/views/index'
import {
  MessageProvider,
  ToastIndex,
  LoadingProvider,
  LoadingBar,
  Dialog,
} from '@level'

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <BrowserRouter>
    <BugsnagProvider>
      <WebSocketProvider>
        <LoadingProvider>
          <LoadingBar />
          <MessageProvider>
            <Dialog />
            <ToastIndex />
            <AppIndex />
          </MessageProvider>
        </LoadingProvider>
      </WebSocketProvider>
    </BugsnagProvider>
  </BrowserRouter>,
)
