import React from 'react'

import { useAuth } from '@app/hooks'
import { Navigate } from 'react-router-dom'
import { Loading } from '@level'

const SignOut = () => {
  const { signOut, sessionActive } = useAuth()
  const { search } = window.location

  React.useEffect(() => signOut(), [])

  if (sessionActive) return <Loading text="Signing Out" />
  return <Navigate to={`/auth/new${search}`} />
}

export {
  SignOut,
}
