import React from 'react'
import PropTypes from 'prop-types'
import { useVirtualizer } from '@tanstack/react-virtual'

import { ScrollPanel } from './ScrollPanel'
import { useInfiniteFetch } from '../../hooks/useInfiniteFetch'

const itemPropsDefault = (props) => props

const VirtualScrollPanel = ({
  item: Item,
  itemProps,
  items,
  hasMore,
  isLoading,
  loading,
  allLoaded,
  getMore,
  empty,
  estimateSize,
  overscan,
  ...props
}) => {
  const contentRef = React.useRef()
  const virtualizer = useVirtualizer({
    count: hasMore ? items.length + 1 : items.length,
    getScrollElement: () => contentRef.current,
    estimateSize,
    overscan,
    ...props,
  })

  useInfiniteFetch({
    hasMore, getMore, virtualizer, isLoading, items,
  })

  return (
    <ScrollPanel {...props} ref={contentRef}>
      <div
        style={{
          height: `${virtualizer.getTotalSize()}px`,
          width: '100%',
          position: 'relative',
        }}
      >
        { virtualizer.getVirtualItems().map((item) => {
          const isLoader = item.index > items.length - 1
          const loaderView = hasMore ? (loading || 'Loading more…') : (allLoaded || 'Nothing more to load')

          return (
            <div
              key={item.index}
              data-index={item.index}
              ref={virtualizer.measureElement}
              className={item.index % 2 ? 'ListItemOdd' : 'ListItemEven'}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                transform: `translateY(${item.start}px)`,
              }}
            >
              { isLoader ? loaderView : null }
              { !isLoader && Item ? (
                <Item {...itemProps({ data: items[item.index], item, items })} />
              ) : items[item.index] }
            </div>
          )
        })}
        { items?.length === 0 ? empty : null}
      </div>
    </ScrollPanel>
  )
}

VirtualScrollPanel.propTypes = {
  items: PropTypes.array.isRequired,
  hasMore: PropTypes.bool,
  isLoading: PropTypes.bool,
  loading: PropTypes.node,
  allLoaded: PropTypes.node,
  item: PropTypes.func,
  getMore: PropTypes.func,
  itemProps: PropTypes.func,
  empty: PropTypes.node,
  estimateSize: PropTypes.func,
  overscan: PropTypes.number,
}

VirtualScrollPanel.defaultProps = {
  item: undefined,
  hasMore: undefined,
  isLoading: undefined,
  loading: undefined,
  allLoaded: undefined,
  getMore: () => {},
  estimateSize: () => 50,
  overscan: 5,
  itemProps: itemPropsDefault,
  empty: undefined,
}

export { VirtualScrollPanel }
