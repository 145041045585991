import React from 'react'
import PropTypes from 'prop-types'

import { gapSize, spaceToSize } from '../../helpers/space'
import { alignItemsStyle, alignments, valignments } from '../../helpers/alignment'
import './stack.scss'

const Stack = React.forwardRef(({
  gap: rowGap,
  space,
  style,
  tag: Tag,
  align,
  valign,
  ...rest
}, ref) => (
  <Tag
    className="level-stack"
    style={{
      display: 'flex',
      flexDirection: 'column',
      rowGap: gapSize(rowGap),
      padding: space ? spaceToSize(space) : space,
      // We think of align as horizontal alignment setting,
      // but with flexDirection: column the axis of alignment is flipped to vertical.
      // We swap these properties to keep our metla model simple.
      ...alignItemsStyle({ valign: align, align: valign }),
      ...style,
    }}
    {...rest}
    ref={ref}
  />
))

Stack.propTypes = {
  tag: PropTypes.string,
  style: PropTypes.object,
  gap: gapSize.propTypes.gap,
  space: spaceToSize.propTypes.space,
  align: PropTypes.oneOf(valignments),
  valign: PropTypes.oneOf(alignments),
}

Stack.defaultProps = {
  tag: 'div',
  gap: 0,
  space: undefined,
  align: undefined,
  valign: undefined,
  style: {},
}

export { Stack }
