import React from 'react'
import PropTypes from 'prop-types'

import { Text } from '../Text'
import { spaceToSize } from '../../helpers/space'

const Nav = ({
  label, className, children, space,
}) => (
  <nav
    className={`${className || ''} level-nav`}
    style={{ padding: spaceToSize(space) }}
  >
    { label ? (
      <Text tag="h5" nowrap>{label}</Text>
    ) : null }
    { children }
  </nav>
)

Nav.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  space: spaceToSize.propTypes.space,
}

Nav.defaultProps = {
  label: null,
  className: null,
  space: 6,
}

export {
  Nav,
}
