import React from 'react'

import { useFormRef, useUuid } from '../../hooks'
import { camelToTitle, replaceAutoCompleteTriggers } from '../../helpers'
import { Input } from './Input'

const deriveLabel = ({ label, name }) => (label === true ? camelToTitle(name) : label)

const NoAutoCompleteInput = ({
  name,
  label,
  placeholder: placeholderProp,
  ...rest
}) => {
  const uuid = useUuid()
  const formRef = useFormRef()
  const visibleName = `input-${uuid}`
  const labelText = replaceAutoCompleteTriggers(deriveLabel({ label, name }))
  const placeholder = replaceAutoCompleteTriggers(placeholderProp || !label ? (placeholderProp || labelText || '') : '')

  const [inputValue, setInputValue] = React.useState('')

  const onChange = ({ target }) => {
    setInputValue(target.value)
    if (formRef?.formState.errors[name]) formRef.clearErrors(name)
  }

  // If the inputValue changes, update the formRef value
  // (the hidden input which is actually submitted)

  return (
    <div>
      <Input
        {...rest}
        name={visibleName}
        // Obfusticate annoying autocomplete widgets
        label={labelText}
        placeholder={placeholder}
        autoComplete="none"
        spellCheck={false}
        onChange={onChange}
        errorMessage={formRef?.formState.errors[name]?.message}
      />
      <input
        name={name}
        type="hidden"
        value={inputValue}
        {...formRef.register(visibleName)}
      />
    </div>
  )
}

NoAutoCompleteInput.propTypes = {
  ...Input.propTypes,
}

NoAutoCompleteInput.defaultProps = {
  ...Input.defaultProps,
}

const EmailInput = ({ autoComplete, ...props }) => (
  autoComplete === false ? (
    <NoAutoCompleteInput {...props} />
  ) : <Input type="email" {...props} />
)

EmailInput.propTypes = {
  ...Input.propTypes,
}

EmailInput.defaultProps = {
  ...Input.defaultProps,
}

const NameInput = ({ autoComplete, ...props }) => (
  autoComplete === false ? (
    <NoAutoCompleteInput {...props} />
  ) : <Input {...props} />
)

NameInput.propTypes = {
  ...Input.propTypes,
}

NameInput.defaultProps = {
  ...Input.defaultProps,
}

export {
  NameInput,
  EmailInput,
  NoAutoCompleteInput,
}
