import React from 'react'
import PropTypes from 'prop-types'
import { spaceToSize, flushStyle } from '../../helpers/space'
import { ScrollPanel } from '../ScrollPanel'

const Block = React.forwardRef(({
  space, style, as: Tag, flush, maxHeight, ...props
}, ref) => {
  const s = flushStyle({
    flush,
    style: {
      padding: space ? spaceToSize(space) : null,
      ...style,
    },
  })
  if (maxHeight) {
    return (
      <ScrollPanel
        as={Tag}
        {...props}
        style={s}
        data-max-height={maxHeight || null}
        ref={ref}
      />
    )
  }
  return (
    <Tag style={s} {...props} ref={ref} />
  )
})

Block.propTypes = {
  flush: spaceToSize.propTypes.space,
  space: spaceToSize.propTypes.space,
  style: PropTypes.object,
  as: PropTypes.any,
  maxHeight: PropTypes.bool,
}

Block.defaultProps = {
  style: {},
  maxHeight: undefined,
  space: undefined,
  flush: undefined,
  as: 'div',
}

export {
  Block,
}
