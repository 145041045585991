import React from 'react'

import { ScrollPanel } from '../../../../ScrollPanel'
import { Divider } from '../../../../Divider'
import { Stack } from '../../../../Stack'
import { Text } from '../../../../Text'
import { Shelf } from '../../../../Shelf'
import { useDialog } from '../../../../../hooks/useDialog'

import { useTemplateConfig } from '../useTemplateConfig'
import { useSection } from '../helpers'

const SharedSectionConfig = () => {
  const { unlinkSharedSection, SharedSectionPanel } = useTemplateConfig()
  const section = useSection()
  const askUnlink = useDialog()

  const confirmUnlink = () => {
    askUnlink({
      title: 'Unlink Section?',
      content: `This will no longer be linked to the ${section.variables.config.name} shared section. Any changes will not be synced to other templates.`,
      onConfirm: () => unlinkSharedSection(section),
    })
  }

  return (
    <>
      <Stack space={7} gap={4}>
        <Shelf align="split" valign="center">
          <Text tag="h3">
            { section.variables.config.name }
          </Text>
        </Shelf>
      </Stack>
      <Divider />
      <ScrollPanel>
        <SharedSectionPanel section={section} unlink={confirmUnlink} />
      </ScrollPanel>
    </>
  )
}

export {
  SharedSectionConfig,
}
