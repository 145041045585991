import React from 'react'

import { Button } from '../Button'
import { Transition } from '../Transition'
import './magic-loader.scss'

const getRandom = (min, max, unit) => `${Math.floor(Math.random() * (max - min + 1)) + min}${unit || ''}`

const Loader = ({ className, ...props }) => {
  const size = 80
  const count = 20
  const shapeSize = 20
  const deg = 45
  const scale = 0
  const hue = '25deg'
  const step = React.useRef(deg)
  const steps = [320, 45]

  const el = React.useRef()
  const shape = [
    (
      <svg className="magic-triangle" height={shapeSize + 2} width={shapeSize + 2}>
        <polygon
          strokeLinecap="round"
          strokeLinejoin="round"
          points={`${shapeSize / 2},3 3,${shapeSize - 3} ${shapeSize - 3},${shapeSize - 3}`}
        />
      </svg>
    ),
    <div className="magic-square" />,
    <div className="magic-circle" />,
  ]

  React.useEffect(() => {
    const shuffleVars = () => {
      if (step.current < steps.length - 1) { step.current += 1 } else { step.current = 0 }
      if (el.current) {
        el.current.style.setProperty('--deg', `${steps[step.current]}deg`)
        el.current.style.setProperty('--scale', getRandom(9, 13))
        el.current.style.setProperty('--hue', getRandom(10, 60, 'deg'))
      }
    }
    window.setTimeout(shuffleVars, 0)
    const interval = window.setInterval(shuffleVars, 10000)
    return () => {
      window.clearInterval(interval)
    }
  }, [el])

  const shapes = Array(count).fill('').map((_, i) => {
    const transform = `rotate(calc(${i} * var(--deg))) scale(calc(${i}/100 * var(--scale)))`
    const filter = `hue-rotate(calc(${i} * var(--hue)))`
    const key = `shape-${i}` // declare key here to avoid lint warning on using index
    return (
      <div
        style={{
          transform,
          filter,
          position: 'absolute',
          width: '100%',
          transition: 'all 10s',
          transformOrigin: '0 0',
        }}
        key={key}
      >
        {shape[i % 3]}
      </div>
    )
  })

  return (
    <div
      className={`level-magic-loader ${className || ''}`}
      ref={el}
      {...props}
      style={{
        '--deg': `${deg}deg`,
        '--hue': hue,
        '--size': `${shapeSize}px`,
        '--scale': scale,
        ...props.style,
      }}
    >
      <div
        style={{
          WebkitUserSelect: 'none',
          userSelect: 'none',
          position: 'relative',
          display: 'block',
          width: size,
          height: size,
        }}
      >
        { shapes }
      </div>
    </div>
  )
}

const MagicLoader = ({ loading, children }) => (
  <div className="level-magic-loader-wrapper">
    { children }
    <Transition in={loading} type="fade-in" unmountOnExit>
      <Loader />
    </Transition>
  </div>
)

export {
  MagicLoader,
}
