import React from 'react'
import PropTypes from 'prop-types'
import { useFormRef, FormProvider, useKeyDown } from '../../hooks'

// This creates a useForm hook object and provides it as a context object to its children.
const FormTag = ({
  hiddenValues,
  reset,
  focus,
  focusDelay,
  children,
  autoComplete,
  className,
}) => {
  const formRef = useFormRef()
  const {
    formElRef, submit, focus: formFocus,
  } = formRef

  // Submit form when `cmd/ctrl + enter` invoked on textarea
  useKeyDown({
    key: ['cmd Enter', 'ctrl Enter'],
    match: 'textarea',
    onPress: () => submit(),
  })

  // Inititally and after a Form reset, trigger a focus based on parameters
  React.useLayoutEffect(() => {
    formFocus(focus, focusDelay)
  }, [reset, focus, formElRef.current])

  return (
    <form
      data-focus={focus}
      onSubmit={submit}
      autoComplete={!autoComplete === false ? 'none' : null}
      ref={formElRef}
      className={`${className || ''} level-form`}
    >
      { Object.keys(hiddenValues).map((name) => (
        <input
          type="hidden"
          name={name}
          key={name}
          defaultValue={hiddenValues[name]}
          ref={formRef.register(name)}
        />
      ))}
      {children}
    </form>
  )
}

FormTag.propTypes = {
  hiddenValues: PropTypes.object,
  reset: PropTypes.any,
  focus: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  autoComplete: PropTypes.bool,
  focusDelay: PropTypes.number,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

FormTag.defaultProps = {
  hiddenValues: {},
  reset: undefined,
  focus: undefined,
  className: undefined,
  focusDelay: 0,
  autoComplete: false,
}

const Form = (props) => (
  <FormProvider {...props}>
    <FormTag {...props} />
  </FormProvider>
)

Form.propTypes = {
  onSubmit: PropTypes.func,
  defaultValues: PropTypes.object,
  errors: PropTypes.array,
  watch: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  autoComplete: PropTypes.bool,
  debounceSubmit: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
}

Form.defaultProps = {
  onSubmit: null,
  defaultValues: {},
  errors: [],
  watch: undefined,
  autoComplete: false,
  debounceSubmit: false,
}

export {
  Form,
}
