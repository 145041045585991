import React from 'react'
import {
  getColor,
  ElementWrapper,
} from './helpers'

const borderStyles = {
  normal: 'solid',
  double: 'double',
  dashed: 'dashed',
  dotted: 'dotted',
}

const Divider = (props) => {
  const { element: { config } } = props
  const {
    padding,
    borderWidth,
  } = config

  const style = borderStyles[config.borderStyle] || 'solid'

  return (
    <ElementWrapper {...props} style={{ padding }}>
      <table width="100%">
        <tbody>
          <tr>
            <td
              className="enveloop-element-divider"
              style={{
                width: '100%',
                padding: 0,
                borderBottomColor: getColor(props),
                borderBottomStyle: style,
                borderBottomWidth: borderWidth,
              }}
            />
          </tr>
        </tbody>
      </table>
    </ElementWrapper>
  )
}

Divider.style = {}
Divider.type = 'Divider'

Divider.propTypes = {
  ...ElementWrapper.propTypes,
}
Divider.defaultProps = ElementWrapper.defaultProps

Divider.config = {
  color: null,
  borderStyle: 'solid',
  borderWidth: '1px',
  padding: '10px 0 10px 0',
}

export {
  borderStyles,
  Divider,
}
