import React from 'react'
import {
  MenuPopover as ReachMenuPopover,
} from '@reach/menu-button'

import { positionDefault } from '@reach/popover'

const MenuPopover = ({ position, ...rest }) => {
  // Make it easier to tweak a position passing the defaults
  // so the position fn can merge properties if they like
  const positionFn = (...args) => {
    const defaults = positionDefault(...args)
    return position ? position(...args, defaults) : defaults
  }
  return <ReachMenuPopover position={positionFn} {...rest} />
}

MenuPopover.propTypes = ReachMenuPopover.propTypes
MenuPopover.defaultProps = ReachMenuPopover.defaultProps

export {
  MenuPopover,
}
