import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '../Button'
import { Icon } from '../Icon'
import { copyObject } from '../../helpers/object'

const PhoneImage = ({
  src, removeImage, newImage, ...props
}) => {
  const remove = removeImage ? ({ target: el }) => {
    const wrapper = el.closest('.level-phone-image-wrapper')
    wrapper.classList.remove('adding')
    wrapper.classList.add('deleting')
    window.setTimeout(() => removeImage(src), 400)
  } : null

  return (
    <div className={`level-phone-image-wrapper ${newImage ? 'adding' : ''}`} {...props}>
      { removeImage ? (
        <Button
          className="level-phone-image-remove"
          onClick={remove}
          label="Remove"
          size={0}
        >
          <Icon name="x-circle" className="level-phone-image-remove-icon" size={5} />
        </Button>
      ) : null }
      <img className="level-phone-image" src={src} alt="MMS item" />
    </div>
  )
}

PhoneImage.propTypes = {
  src: PropTypes.string.isRequired,
  removeImage: PropTypes.func,
  newImage: PropTypes.bool,
}

PhoneImage.defaultProps = {
  removeImage: undefined,
  newImage: undefined,
}

const galleryStyle = ({ index, count, expanded }) => {
  if (expanded) return null
  const vgap = 5
  const hgap = 30
  return {
    translate: `${(index * hgap) - ((count - 1) * 5)}px ${(count - index) * vgap}px`,
    scale: `${1 - ((count - (index + 1)) * 0.08)}`,
    rotate: `-${((count - (index + 1)) * 2)}deg`,
  }
}

const PhoneImages = ({ images, onDrop, ...props }) => {
  const existing = React.useRef(copyObject(images))
  const [expanded, setExpanded] = React.useState(false)

  React.useEffect(() => {
    existing.current = copyObject(images)
  }, [images])

  React.useEffect(() => {
    if (images.length < 2) {
      setExpanded(false)
    }
  }, [images.length])

  return (
    <div className="level-phone-images-wrapper">
      {/* { images.length > 1 ? ( */}
      {/*   <Button */}
      {/*     className="level-phone-images-toggle-gallery" */}
      {/*     icon="three-dots-horizontal" */}
      {/*     label="Expand image gallery" */}
      {/*     onClick={() => { setExpanded((e) => !e) }} */}
      {/*   /> */}
      {/* ) : null } */}
      <ul
        className="level-phone-images"
        data-expanded={expanded}
        data-count={images.length}
      >
        { images.map((src, index) => (
          <li key={src} style={galleryStyle({ index, count: images.length, expanded })}>
            <PhoneImage
              newImage={!existing.current.includes(src)}
              data-index={index}
              src={src}
              {...props}
            />
          </li>
        ))}
      </ul>
    </div>
  )
}

PhoneImages.propTypes = {
  images: PropTypes.array,
  onDrop: PropTypes.func,
}

PhoneImages.defaultProps = {
  images: [],
  onDrop: undefined,
}

export { PhoneImages }
