import React from 'react'
import PropTypes from 'prop-types'

import { Divider } from '../Divider'
import { Stack } from '../Stack'
import { Grid } from '../Grid'
import { Input, RadioInput } from '../Input'
import { Select } from '../Select'

import {
  isValidDate, camelToTitle,
} from '../../helpers'

const DateRangeInput = ({
  span,
  dateRange,
  onClear,
  onFocus,
  rangeLabel,
  onChangeDatetime,
}) => {
  const rangeOptions = dateRange.map((label) => ({ label, value: label.replace('s', '') }))
  const dateGate = ['of', 'before', 'after'].map((name) => ({ name, value: name }))
  const onChangeRange = (e, formRef) => {
    formRef.setValue('date', 'range')
    if (formRef.getValues('datetime')[0]) {
      formRef.submit()
    }
  }

  const validateDatetime = (value, formRef) => {
    const valid = isValidDate(value)
    if (formRef.getValues('date') === 'range') {
      if (!value) return 'Choose a date'
      return valid || 'Invalid Date Format'
    }
    return true
  }

  return (
    <Stack gap={5} onFocus={onFocus}>
      { span.map((value) => (
        <RadioInput key={value} name="date" value={value} label={camelToTitle(value)} submitOnChange />
      )) }
      <Divider flush={7} />
      <RadioInput name="date" value="range" label={rangeLabel} submitOnChange onFocus={onFocus} />
      <Grid gap={3} templateColumns="1fr auto">
        <Select label={false} name="range" options={rangeOptions} onChange={onChangeRange} />
        <Select label={false} name="direction" options={dateGate} onChange={onChangeRange} />
      </Grid>
      <Input
        name="datetime"
        label={false}
        validate={validateDatetime}
        onClear={onClear}
        onChange={onChangeDatetime}
        placeholder="YYYY-MM-DD HH:mm:ss"
        showClear
      />
    </Stack>
  )
}

DateRangeInput.propTypes = {
  span: PropTypes.array.isRequired,
  dateRange: PropTypes.array.isRequired,
  onClear: PropTypes.func,
  onFocus: PropTypes.func,
  onChangeDatetime: PropTypes.func,
  rangeLabel: PropTypes.string,
}

DateRangeInput.defaultProps = {
  rangeLabel: 'Within',
  onClear: undefined,
  onFocus: undefined,
  onChangeDatetime: undefined,
}

export {
  DateRangeInput,
}
