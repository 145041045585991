import React from 'react'
import PropTypes from 'prop-types'

import { useFormRef, useUuid } from '../../hooks'
import { camelToTitle } from '../../helpers'
import './radio-input-wrapper.scss'

const RadioInputWrapper = ({
  name,
  children,
  label,
  value,
  required,
  onChange,
  onSelect,
  theme,
  className,
  ...rest
}) => {
  const { register, watch, setValue } = useFormRef()
  const initialValue = React.useRef(value)
  const id = `radio-${name}-${useUuid()}`

  const reg = register ? register(name, { required, onChange }) : {}

  const val = watch({ name })

  React.useEffect(() => {
    if (val === initialValue.current && val !== value) {
      setValue(name, value, { shouldDirty: true })
    }
    initialValue.current = value
  }, [value])

  const checked = val === value || (!val && rest.defaultChecked) || null

  React.useEffect(() => {
    if (checked && onSelect) onSelect({ name, value })
  }, [checked])

  return (
    <label
      htmlFor={id}
      className={`level-radio-input-wrapper ${className || ''} ${checked ? 'checked' : ''}`}
      data-theme={theme}
    >
      <input
        type="radio"
        id={id}
        value={value}
        aria-label={label || camelToTitle(value)}
        {...reg}
        {...rest}
      />
      { children }
    </label>
  )
}

RadioInputWrapper.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  children: PropTypes.node,
  value: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  theme: PropTypes.string,
  className: PropTypes.string,
}

RadioInputWrapper.defaultProps = {
  required: false,
  children: undefined,
  value: undefined,
  label: undefined,
  onChange: undefined,
  onSelect: undefined,
  theme: undefined,
  className: undefined,
}
export {
  RadioInputWrapper,
}
