import React from 'react'
import PropTypes from 'prop-types'

import {
  copyObject, Color,
  renderString, renderMarkdown,
} from '../../../../../helpers'

import { isActive, previewStyle } from '../helpers'

import { EditCellInPlace } from '../EditInPlace'

// Converts content to markdown string and then renders string to React node to be injected
const renderCell = (content) => renderString(renderMarkdown(content, { type: 'text' }))

const isLastRow = ({ rowIndex, rowGroup, tableConfig }) => (
  rowIndex === tableConfig[rowGroup].rows.length - 1
)

const cellPosition = ({
  columnIndex, rowIndex, element, rowGroup, colspan,
}) => {
  const { tableConfig } = element.config
  const { columns } = tableConfig
  return {
    firstRow: rowIndex === 0,
    firstColumn: columnIndex === 0,
    lastColumn: colspan === columns.length || columns.length - 1 === columnIndex,
    lastRow: isLastRow({ rowIndex, rowGroup, tableConfig }),
  }
}

const getCellStyle = ({ borderConfig: borders, styleConfig, ...props }) => {
  const {
    lastRow, firstRow, lastColumn, firstColumn,
  } = cellPosition(props)
  const border = `1px solid ${styleConfig.borderColor || 'currentcolor'}`
  const config = copyObject(styleConfig)

  // Inner horizontal boarders
  if (borders.horizontal) {
    if (props.rowGroup === 'header') config.borderBottom = border
    else config.borderTop = border
  }
  // Inner vertical borders
  if (borders.vertical && !firstColumn) config.borderLeft = border
  // Add borders to outer edges
  if (borders.outerHorizontal) {
    if (firstRow) config.borderTop = border
    if (lastRow) config.borderBottom = border
  }
  if (borders.outerVertical) {
    if (firstColumn) config.borderLeft = border
    if (lastColumn) config.borderRight = border
  }
  delete config.borderColor
  return previewStyle(config, props)
}

const BuilderCell = (props) => {
  const {
    row,
    rowIndex,
    columns,
    columnIndex,
    rowGroup,
    styleConfig,
    element: { id, config: { tableConfig } },
  } = props

  const onChange = (value) => {
    const newConfig = copyObject(tableConfig)
    newConfig[rowGroup].rows[rowIndex].columns[columnIndex] = value
    return { tableConfig: newConfig }
  }
  const active = isActive({ elementId: id })
  const Tag = rowGroup === 'header' ? 'th' : 'td'

  const tableColumn = columns[columnIndex]
  let content = row.columns[columnIndex] || ''
  if (!active) content = renderCell(content)

  let placeholder = {
    header: 'Header',
    footer: 'Footer',
    body: 'Text',
  }[rowGroup]

  if (row.type === 'iterable') placeholder = `{{{ var${columnIndex + 1} }}}`

  const cellStyle = getCellStyle({ ...props, columnIndex })
  if (active && row.type !== 'text') {
    delete cellStyle.borderTop
    delete cellStyle.borderBottom
  }

  return (
    <Tag align={tableColumn.align} style={cellStyle}>
      <EditCellInPlace
        {...props}
        text={row.columns[columnIndex] || ''}
        id={`${row.id}-${tableColumn.id}`}
        onChange={onChange}
        configVar={rowGroup}
        color={styleConfig.color}
        placeholder={placeholder}
        wrapper={row.type === 'iterable' ? 'code' : null}
      >
        { content }
      </EditCellInPlace>
    </Tag>
  )
}

BuilderCell.propTypes = {
  row: PropTypes.object.isRequired,
  rowIndex: PropTypes.number.isRequired,
  rowGroup: PropTypes.string.isRequired,
  styleConfig: PropTypes.object.isRequired,
  columnIndex: PropTypes.number.isRequired,
  element: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  mode: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
}

const Cell = (props) => {
  const {
    row,
    columns,
    columnIndex,
    rowGroup,
    rowIndex,
    mode,
    varPath,
  } = props

  if (mode.match(/builder/)) return <BuilderCell {...props} />

  const Tag = rowGroup === 'header' ? 'th' : 'td'
  const cellStyle = getCellStyle({ ...props, columnIndex })
  const tableColumn = columns[columnIndex]

  const content = mode.match(/render/)
    ? `{{{ ${varPath}.tableConfig.${rowGroup}.rows.${rowIndex}.columns.${columnIndex} }}}`
    : renderCell(row.columns[columnIndex])

  return (
    <Tag align={tableColumn.align} style={cellStyle}>{content}</Tag>
  )
}

Cell.propTypes = {
  ...BuilderCell.propTypes,
  color: PropTypes.string,
}

Cell.defaultProps = {
  color: undefined,
}

const DataCellBuilder = (props) => {
  const {
    row,
    rowIndex,
    columns,
    start,
    styleConfig,
    element: { id, config: { tableConfig } },
    rowGroup,
  } = props
  const active = isActive({ elementId: id })
  if (!active) return null

  const cellStyle = getCellStyle({ ...props, columnIndex: 0, colspan: columns.length })
  const lightColor = Color(styleConfig.color)?.setAlpha(0.4).toString()
  let cell = ''

  const onChange = (value) => {
    const newConfig = copyObject(tableConfig)
    newConfig[rowGroup].rows[rowIndex].variable = value
    return { tableConfig: newConfig }
  }
  const placeholder = {
    conditional: 'condition',
    iterable: 'iterable',
  }[row.type]

  // start tag for data variable, e.g. {{#start}}
  if (start) {
    delete cellStyle.borderBottom
    cellStyle.paddingBottom = '4px'
    let varComment = `/* Add a variable name for your ${row.type} */`
    // Change comment based on row type
    if (row.variable) {
      if (row.type === 'iterable') varComment = `/* Renders a row for each item in \`${row.variable}\`, e.g. ${row.variable}: [{},…] */`
      if (row.type === 'conditional') varComment = `/* Show this row if ${row.variable} has a value */`
    }

    cell = (
      <>
        <span style={{ color: lightColor }}>{'{{# '}</span>
        <EditCellInPlace
          {...props}
          text={row.variable}
          id={`row-${row.id}-variable`}
          onChange={onChange}
          placeholder={placeholder}
          color={styleConfig.color}
          configVar={rowGroup}
          wrapper="span"
        >{ row.variable }
        </EditCellInPlace>
        <span style={{ color: lightColor }}>{` }} ${varComment}`}</span>
      </>
    )
  } else {
    cellStyle.color = lightColor
    cellStyle.paddingTop = '4px'
    delete cellStyle.borderTop
    // end tag for data row
    cell = `{{/ ${row.variable || placeholder} }}`
  }

  return (
    <tr>
      <td colSpan={columns.length} style={cellStyle}>
        <code>{ cell }</code>
      </td>
    </tr>
  )
}

DataCellBuilder.propTypes = {
  row: PropTypes.object.isRequired,
  rowIndex: PropTypes.number.isRequired,
  columns: PropTypes.array.isRequired,
  styleConfig: PropTypes.object.isRequired,
  element: PropTypes.object.isRequired,
  rowGroup: PropTypes.string.isRequired,
  start: PropTypes.bool,
}

DataCellBuilder.defaultProps = {
  start: false,
}

const DataCell = (props) => {
  const {
    row, mode, start,
  } = props

  if (row.type === 'text') return null

  if (mode.match(/builder/)) {
    return <DataCellBuilder {...props} />
  }

  if (mode.match(/preview/) && row.variable) {
    if (start) return `{{#${row.variable?.trim()}}}`
    return `{{/${row.variable?.trim()}}}`
  }

  return null
}

DataCell.propTypes = DataCellBuilder.propTypes

export {
  Cell,
  DataCell,
}
