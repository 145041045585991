import React from 'react'
import PropTypes from 'prop-types'

import { useLocalStorage } from 'beautiful-react-hooks'
import { useProject } from '@app/hooks'
import {
  Grid, Button, ScrollPanel, Template, TemplateConfigEmailSettings,
  TemplatePasteBar, Breadcrumbs, Shelf, useTemplateState,
} from '@level'

const sizeOptions = [
  { label: 'phone', icon: 'mobile-button', size: 'small' },
  { label: 'tablet', icon: 'tablet-button', size: 'medium' },
  { label: 'large screen', icon: 'desktop', size: 'large' },
]

const PreviewFooter = React.forwardRef(({ sizeClass, setSizeClass, mode }, ref) => (
  <div className="template-preview-footer" ref={ref}>
    { mode.match('builder') ? <TemplatePasteBar /> : null }
    <Grid className="template-preview-size-classes" align="center" gap={3} rowGap={0} templateColumns="auto auto auto">
      { sizeOptions.map(({ label, icon, size }) => {
        const selected = size === sizeClass
        return (
          <Button
            key={size}
            theme="menu"
            style={{ padding: '5px 8px', background: selected ? null : 'transparent', borderColor: selected ? null : 'transparent' }}
            icon={{ name: icon, size: 4, color: selected ? 'blue-600' : 'neutral-300' }}
            label={label}
            onClick={() => setSizeClass(size)}
          />
        )
      })}
    </Grid>
  </div>
))

PreviewFooter.propTypes = {
  mode: PropTypes.string.isRequired,
  sizeClass: PropTypes.string.isRequired,
  setSizeClass: PropTypes.func.isRequired,
}

const BuilderBreadcrumbs = ({
  config, setShowDrawerMenu, mode, name,
}) => {
  const state = useTemplateState({ config, root: './' })
  const {
    section, sectionUrl,
    element, elementType,
  } = state
  const viewingSharedSection = mode === 'section-builder' || section?.sharedSectionId
  // Only shared sections get names
  let sectionName = 'Section'
  if (viewingSharedSection) sectionName = section?.variables.config.name || 'Section'
  const crumbs = (() => {
    const urls = []
    if (section && mode !== 'section-builder') urls.push({ to: './', text: name })
    if (element) urls.push({ to: sectionUrl, text: sectionName })
    return urls
  })()
  const crumbText = (() => {
    if (element) return elementType
    if (section) return sectionName
    return name
  })()
  return (
    <Grid
      className="level-template-config-menu"
      templateColumns="auto 1fr auto min-content"
      valign="center"
      style={{
        position: 'sticky', top: 0, zIndex: 3, background: 'white', padding: '11px 20px',
      }}
    >
      <Breadcrumbs
        crumbs={crumbs}
        backIcon={false}
      >
        { crumbText }
      </Breadcrumbs>
      <span />
      <Shelf gap={4}>
        <Button label="add template items" theme="primary-green" icon="plus" onClick={() => setShowDrawerMenu(true)} />
      </Shelf>
    </Grid>
  )
}

const PreviewEmailTemplate = ({ mode, ...props }) => {
  const [sizeClass, setSizeClass] = useLocalStorage('template-preview-size', 'large')
  const footerRef = React.useRef()
  const { Project } = useProject()

  return (
    <ScrollPanel
      footer={(
        <PreviewFooter
          mode={mode}
          sizeClass={sizeClass}
          setSizeClass={setSizeClass}
          ref={footerRef}
        />
      )}
      data-media-width={sizeClass}
      style={{ position: 'relative' }}
    >
      { Project && mode !== 'section-builder' ? (
        <TemplateConfigEmailSettings
          {...props}
          mode={mode}
        />
      ) : null }
      { mode.match(/builder/) ? <BuilderBreadcrumbs mode={mode} {...props} /> : null }
      <Template {...props} mode={mode} footerRef={footerRef} />
    </ScrollPanel>
  )
}

PreviewEmailTemplate.propTypes = {
  ...Template.propTypes,
}

export {
  PreviewEmailTemplate,
  PreviewFooter,
}
