import React from 'react'
import PropTypes from 'prop-types'
import { Shelf } from '../Shelf'
import { Link } from '../Link'
import { Text } from '../Text'

import './breadcrumbs.scss'

const defaultBackIcon = {
  name: 'arrow-left', color: 'neutral-400', size: 1, gap: 0,
}

const breadCrumbIcon = (icon) => {
  if (typeof icon === 'string') {
    return {
      ...defaultBackIcon,
      name: icon,
    }
  }
  if (typeof icon === 'object') {
    return { ...defaultBackIcon, ...icon }
  }
  if (typeof icon === 'undefined') {
    return defaultBackIcon
  }
  return icon || null
}

const Breadcrumbs = ({
  crumbs, children, size, backIcon,
}) => (
  <nav className="level-breadcrumbs">
    <Shelf gap={5} tag="ul" nowrap>
      {crumbs.map(({ to, ...props }, index) => (
        <li key={to}>{index ? (
          <Link to={to} size={size} {...props} />
        ) : (
          <Link
            to={to}
            size={size}
            icon={breadCrumbIcon(backIcon)}
            {...props}
          />
        )}
        </li>
      ))}
      { children ? (
        <Text tag="li" size={size} className="level-breadcrumbs-current">{ children }</Text>
      ) : null }
    </Shelf>
  </nav>
)

Breadcrumbs.propTypes = {
  crumbs: PropTypes.array,
  children: PropTypes.node,
  size: PropTypes.number,
  backIcon: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.bool]),
}

Breadcrumbs.defaultProps = {
  crumbs: [],
  children: undefined,
  size: undefined,
  backIcon: undefined,
}

export { Breadcrumbs }
