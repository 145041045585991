import React from 'react'
import './divider.scss'
import PropTypes from 'prop-types'
import {
  validSizes, flushStyle, spaceToSize, toColor,
} from '../../helpers'

const Divider = ({
  flush, theme, space, color, style: styleProp,
}) => {
  const style = {
    color: toColor(color),
    ...styleProp,
    ...flushStyle({ flush }),
    padding: spaceToSize(space),
  }
  return <div className="level-divider" data-theme={theme} style={style} />
}

Divider.propTypes = {
  style: PropTypes.object,
  theme: PropTypes.oneOf(['default', 'double']),
  flush: PropTypes.oneOf(validSizes),
  space: spaceToSize.propTypes.space,
  color: toColor.propTypes.color,
}

Divider.defaultProps = {
  flush: undefined,
  space: undefined,
  style: {},
  theme: 'default',
  color: 'neutral-50',
}

export { Divider }
