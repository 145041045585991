import React from 'react'
import PropTypes from 'prop-types'

import { Stack } from '../Stack'
import { Text, P } from '../Text'
import { Body } from '../Body'

const NotFound = ({ title, text, children }) => (
  <Body>
    <Stack gap={6} className="level-not-found">
      <Text tag="h2">{ title }</Text>
      { text ? <P>{ text }</P> : null }
      { children }
    </Stack>
  </Body>
)

NotFound.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
}

NotFound.defaultProps = {
  title: 'Not Found',
  text: null,
  children: null,
}

export {
  NotFound,
}
