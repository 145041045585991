import React from 'react'
import {
  Routes,
  Route,
  Navigate,
} from 'react-router-dom'

import {
  Main, Body, Card, Stack,
  Input, Form, ButtonShelf, SubmitButton, Text, P,
  NavTabs, Divider, Button, SettingsGrid, CheckSwitch,
  useDialog,
} from '@level'

import {
  useProject, useTeam, useProjects, useFormQuery, useUpdateProjectQuery,
} from '@app/hooks'
import { IntegrationsIndex } from './integrations'

import { ApiTokensTable, createApiTokenQuery } from './apiTokens'
import { EventWebhooks } from './webhooks'

const deleteProjectQuery = ({ Project }) => `mutation {
  Project: deleteProject(id: "${Project.id}") {
    id
    team { slug }
  }
}`

const SettingsPanel = () => {
  const { Project } = useProject()
  const { refresh } = useProjects()
  const updateProjectQuery = useUpdateProjectQuery()

  const { onSubmit: updateProject } = useFormQuery({
    query: updateProjectQuery,
    onSuccess: refresh,
    redirect: ({ data }) => (
      (data.Project.slug !== Project.slug)
        ? `../../../${data.Project.slug}/settings` : null
    ),
  })

  return (
    <SettingsGrid>
      <Text tag="h3">Project Name &amp; Slug</Text>
      <Card>
        <Form onSubmit={updateProject} reset={Project.id}>
          <Stack gap={5}>
            <Input name="name" defaultValue={Project?.name} required />
            <Input name="slug" defaultValue={Project?.slug} required />
            <ButtonShelf>
              <SubmitButton text="Update Project Name" />
            </ButtonShelf>
          </Stack>
        </Form>
      </Card>
    </SettingsGrid>
  )
}

const DeleteProject = () => {
  const { Team } = useTeam()
  const { Project } = useProject()
  const { refresh } = useProjects()
  const sendDialog = useDialog()
  const { onSubmit: deleteProject } = useFormQuery({
    query: deleteProjectQuery,
    queryProps: { Project },
    onSuccess: () => refresh(),
    redirect: `/teams/${Team.slug}/`, // Back to /Teams/teamId/
  })

  const confirmDelete = (...args) => {
    sendDialog({
      onConfirm: () => deleteProject(...args),
      title: `Delete "${Project.name}" project?`,
      content: 'Deleting a project is a big deal. Are you sure?',
      confirmText: 'Delete Project',
      destructive: true,
    })
  }

  return (
    <SettingsGrid>
      <Text tag="h3">Delete Project</Text>
      <Card>
        <Form onSubmit={confirmDelete} reset={Project.id}>
          <Stack gap={5}>
            <P>
              Deleting a project will remove access for all users.
              API tokens will be deleted and any email sent through this project will cease.
            </P>
            <ButtonShelf valign="center">
              <SubmitButton text="Delete Project" theme="destructive" />
            </ButtonShelf>
          </Stack>
        </Form>
      </Card>
    </SettingsGrid>
  )
}

const ProjectSettings = () => {
  const { Project, refresh } = useProject()
  const { isProviderTeam } = useTeam()
  const sendDialog = useDialog()

  const { onSubmit } = useFormQuery({
    query: createApiTokenQuery,
    queryProps: { Project },
    // Refresh project list before redirecting or you'll hit a not-found
    onSuccess: () => refresh(),
    successMessage: false,
    redirect: () => '../',
  })

  const addToken = () => sendDialog({
    onConfirm: onSubmit,
    title: 'Add an API token for your project',
    confirmText: 'Generate Token',
    content: (
      <Stack gap={5}>
        <Input label="Token name" name="name" placeholder="enveloop-api-prod" required />
        <CheckSwitch name="testMode" label="Sandbox" />
      </Stack>
    ),
  })

  return Project ? (
    <Stack gap={11}>
      <SettingsGrid>
        <Stack gap={6}>
          <Text tag="h3">Api Tokens</Text>
          <Text>
            Add both production and sandbox tokens for your projects.
            Once added, you can call your project from inside your app.
          </Text>
        </Stack>
        <Stack gap={6}>
          <ApiTokensTable />
          { !isProviderTeam ? <Button style={{ alignSelf: 'end' }} text="Add Token" onClick={addToken} /> : null }
        </Stack>
      </SettingsGrid>
      <Divider flush={8} />
      <EventWebhooks />
      <Divider flush={8} />
      <SettingsPanel />

      { !isProviderTeam ? (
        <>
          <Divider flush={8} />
          <DeleteProject />
        </>
      ) : null }
    </Stack>
  ) : null
}

const ProjectSettingsIndex = () => (
  <Main>
    <div style={{ backgroundColor: '#fff' }}>
      <Stack gap={5}>
        <Text tag="h2" space={[6, 8, 0]}>Project Settings</Text>
        <NavTabs
          space={8}
          navItems={[
            { text: 'General', to: './general' },
            { text: 'Integrations', to: './integrations' },
          ]}
          border
        />
      </Stack>
    </div>
    <Body scroll>
      <Routes>
        <Route index element={<Navigate to="general" replace />} />
        <Route path="general" element={<ProjectSettings />} />
        <Route path="integrations/*" element={<IntegrationsIndex />} />
      </Routes>
    </Body>
  </Main>
)

export {
  ProjectSettingsIndex,
}
