module.exports = {
  sizes: {
    0: '0px',
    1: '1px',
    2: '2px',
    3: '4px',
    4: '8px',
    5: '12px',
    6: '16px',
    7: '20px',
    8: '24px',
    9: '32px',
    10: '40px',
    11: '48px',
    12: '64px',
  },
}
