import React from 'react'
import PropTypes from 'prop-types'

import { useSearchState } from '../../hooks'
import { PopoverPill } from '../Pill'
import { titleize, camelToTitle } from '../../helpers/text'

const FilterPill = ({
  name, display, clear, ...rest
}) => {
  const [params, setParams] = useSearchState()
  const clearKeys = Array.isArray(clear) ? clear : [clear || name]
  const onClear = () => { setParams.clear(clearKeys) }

  if (Object.keys(params).find((k) => clearKeys.includes(k)) && params[name]) {
    const displayText = display || titleize(params[name])
    return (
      <PopoverPill
        param={titleize(name)}
        value={displayText}
        onClear={onClear}
        {...rest}
      />
    )
  }
  return null
}

FilterPill.propTypes = {
  name: PropTypes.string,
  display: PropTypes.string,
  clear: PropTypes.array,
}

FilterPill.defaultProps = {
  name: undefined,
  display: undefined,
  clear: undefined,
}

const DateFilterPill = (props) => {
  const [params] = useSearchState()

  const display = () => {
    if (!params?.date) return null
    if (params.date === 'range') return 'Custom Range'
    return camelToTitle(params.date)
  }

  if (params.date === 'all') return null

  return (
    <FilterPill
      type="date"
      display={display()}
      {...props}
    />
  )
}

export {
  FilterPill,
  DateFilterPill,
}
