import React from 'react'
import PropTypes from 'prop-types'

import {
  MenuLink as ReachMenuLink,
  MenuList,
} from '@reach/menu-button'

import { Link } from '../Link'

const MenuLink = ({
  text,
  destructive,
  children,
  ...rest
}) => (
  <ReachMenuLink as={Link} {...rest} data-destructive={destructive}>
    { children || text }
  </ReachMenuLink>
)

MenuLink.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  destructive: PropTypes.bool,
  children: PropTypes.node,
}

MenuLink.defaultProps = {
  className: 'level-menu-item level-menu-link',
  text: null,
  children: null,
  destructive: null,
}

MenuList.defaultProps = {
  className: 'level-menu-list',
}

export {
  MenuLink,
  MenuList,
}
