import React from 'react'
import PropTypes from 'prop-types'
import { useFormRef } from '../../hooks/useForm'
import { useUuid } from '../../hooks/useUuid'
import { Stack } from '../Stack'
import { Shelf, Spacer } from '../Shelf'
import { Text } from '../Text'
import { Button } from '../Button'
import { camelToTitle } from '../../helpers/text'
import { passthroughCallback } from '../../helpers/function'
import { Icon } from '../Icon'

import { FileDrop, ImageFilePreview } from '../FileDrop'
import { useDrop } from '../../hooks/useDropZone'

import './file-input.scss'

const deriveLabel = ({ label, name }) => (label === true ? camelToTitle(name) : label)

const Label = ({ label, name, inputId }) => {
  const labelText = deriveLabel({ label, name })
  return label ? (
    <label
      className="level-label"
      htmlFor={inputId}
    >{labelText}
    </label>
  ) : null
}

const fileTypes = {
  image: /image/,
}

Label.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  inputId: PropTypes.string.isRequired,
}

const FileInput = ({
  name,
  className,
  label,
  required,
  onFocus: onFocusFn,
  onBlur: onBlurFn,
  onChange,
  defaultValue,
  type,
  id,
}) => {
  const { setValue, register, watch } = useFormRef()
  const inputRef = React.useRef()
  const inputValue = watch ? watch({ name }) : null
  const uuid = useUuid()
  const inputId = id || `file-input-${uuid}`
  const [focus, setFocus] = React.useState(null)

  const onFocus = () => {
    passthroughCallback(onFocusFn)
    setFocus(true)
  }
  const onBlur = () => {
    passthroughCallback(onBlurFn)
    setFocus(null)
  }

  const { ref, ...fields } = register ? register(name, {
    required, onChange, onFocus, onBlur,
  }) : {}

  React.useEffect(() => {
    if (inputValue?.length) {
      const matchesType = Array.from(inputValue).filter(
        (file) => file.type.match(fileTypes[type] || type),
      )
      if (type && !matchesType) {
        inputRef.current.value = null
        setValue(name, null, { shouldDirty: true })
      }
    }
  }, [inputValue])

  React.useEffect(() => {
    // If there dropped a file on
    if (defaultValue) {
      inputRef.current.files = defaultValue
      if (setValue) setValue(name, defaultValue, { shouldDirty: true })

    // If a file was removed, clear the input value
    } else if (inputRef.current?.files?.length) {
      inputRef.current.value = null
      if (setValue) setValue(name, null, { shouldDirty: true })
    }
  }, [defaultValue])

  const labelText = deriveLabel({ label, name })

  return (
    <label
      className={`level-file-input ${className}`}
      data-focus={focus}
      htmlFor={inputId}
    >
      { labelText }
      <input
        type="file"
        id={inputId}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onChange}
        name={name}
        {...fields}
        ref={(instance) => {
          if (ref) ref(instance)
          inputRef.current = instance
        }}
      />
    </label>
  )
}

FileInput.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  label: PropTypes.any,
  required: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  defaultValue: PropTypes.object,
  type: PropTypes.any,
  id: PropTypes.string,
}

FileInput.defaultProps = {
  className: null,
  label: true,
  required: false,
  onFocus: null,
  onBlur: null,
  onChange: null,
  defaultValue: undefined,
  type: null,
  id: null,
}

const DropInput = ({ label, name, required }) => {
  const [focus, setFocus] = React.useState(null)

  const onFocus = () => { setFocus(true) }
  const onBlur = () => { setFocus(null) }

  const {
    data, clear, setDrop, dragOver,
  } = useDrop()

  const uuid = useUuid()
  const inputId = `file-input-${uuid}`
  const onChange = React.useCallback((event) => {
    setDrop(event.target.files)
  }, [])

  return (
    <Stack gap={4}>
      { label ? <Label label={label} name={name} inputId={inputId} /> : null }
      <div
        className="level-drop-file-input"
        data-focus={focus}
        data-drag-item-over={dragOver}
        data-file-present={!!data}
      >
        <FileInput
          id={inputId}
          name={name}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={onChange}
          value={data}
          required={required}
          label={(
            <Shelf gap={4} align="center" valign="center">
              <Icon name="cloud-arrow-up" size={5} color="neutral-600" />
              <React.Fragment>
                <Text color="neutral-700">Drag file here or</Text>
                { ' ' }
                <Text className="choose-file-text" color="blue-600">choose a file</Text>
              </React.Fragment>
            </Shelf>
          )}
        />
        { data?.length ? (
          <Shelf gap={4} valign="center" nowrap>
            <ImageFilePreview file={data.item(0)} />
            <Text forceWrap>Filename: {data.item(0).name }</Text>
            <Spacer grow />
            <Button onClick={() => clear()} icon="x-light" theme="destructive-ghost" label="remove file" />
          </Shelf>
        ) : null }
      </div>
    </Stack>
  )
}

DropInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  required: PropTypes.bool,
}

DropInput.defaultProps = {
  label: true,
  required: false,
}

const FileInputDrop = ({ type, ...props }) => (
  <FileDrop type={type}>
    <DropInput {...props} />
  </FileDrop>
)

FileInputDrop.propTypes = {
  type: PropTypes.string,
}

FileInputDrop.defaultProps = {
  type: null,
}

export {
  FileInput,
  FileInputDrop,
}
