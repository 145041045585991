import React from 'react'

import { useProfile, AsUserBanner } from '@app/hooks'
import {
  Layout, Nav, Sidebar, Icon, Stack,
} from '@level'

import { TeamSwitcher } from '../teams/switcher'
import { InvitationsNotice } from '../invitations'
import { AccountNav } from './nav'

const AccountLayout = () => {
  const { invitations } = useProfile()

  return (
    <Layout
      root={<AsUserBanner />}
      sidebar={(
        <Sidebar
          header={(
            <>
              <Icon name="enveloop-logomark" className="level-sidebar-logo" fill="none" />
              <Nav>
                <Stack gap={6}>
                  { invitations?.length ? <InvitationsNotice /> : null }
                  <TeamSwitcher />
                </Stack>
              </Nav>
            </>
          )}
          footer={<AccountNav />}
        />
      )}
    />
  )
}

export {
  AccountLayout,
}
