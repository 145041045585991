import React from 'react'
import {
  useToast, mapToKey, includeKeys, camelize,
} from '@level'
import { useNavigate } from 'react-router-dom'
import { useQuery } from './useQuery'
import { uploadFile } from '../helpers'

const getErrorFields = (errors) => {
  const errs = mapToKey(errors, 'extensions')
  return (errs?.length)
    ? errs.reduce((all, { fields }) => all.concat(fields), [])
    : null
}
const getFormErrorMessage = (errorFields) => {
  if (!errorFields?.length) return 'There was an error processing your submission.'

  // If error contains elements which cannot be found in the DOM, it's probably a server error.
  // This means the specific error message can't be placed near the adjacent field and should be
  // added to the primary toast error message.
  const fieldsNotInDom = errorFields.filter(({ path }) => !document.querySelector(`[name=${path}]`))
  if (fieldsNotInDom.length) return fieldsNotInDom.map(({ message }) => message).join('. ')

  return `This form has ${(errorFields.length - fieldsNotInDom.length) < 2 ? 'an error' : 'some errors'}. Make your corrections and try again.`
}

// Adds errors to form from GraphQL's error response object
const setErrors = ({ errors, formRef }) => {
  errors.forEach((e = {}) => {
    // Destructure each error from our GraphQL error response
    const { path: initialPath, message, value } = e
    let path = initialPath

    if (path) {
      if (path.match(/validate[A-Z]+/)) {
        path = camelize(path.replace(/validate/, ''))
      }
      // If the inputs still have values which were invalidated on the server
      if (!value || formRef.getValues(path) === value) {
        // Show an error message if necessary
        if (!formRef.formState?.errors[path]) {
          formRef.setError(
            path,
            { type: 'manual', message },
            { shouldFocus: true },
          )
        }
      // If the value doesn't match, clear the error
      } else if (formRef.formState?.errors[path]) {
        formRef.clearErrors(path)
      }
    }
  })
}

// Determines redirect url if configured
const redirectUrl = ({ redirect, data, formData }) => (
  (typeof redirect === 'function') ? redirect({ formData, data }) : redirect
)

// Errors, Processing, and Success messages can be functions or strings.
// This converts them into an object that can trigger a toast message
const getMessage = (message, {
  data,
  errors,
  formData,
  defaults = {},
}) => {
  // Message will be false if sending this type of message has been disabled
  if (message === false) return false
  // If message is a function, pass it all the data we have
  const msg = (typeof message === 'function') ? message({ formData, data, errors }) : message
  // If msg is an object, return as is, this can set title, icon, timeout, message, etc.
  return (typeof msg === 'object') ? msg : { message: msg, ...defaults }
}

// Extract file inputs which should upload files from formData
const getUploadInputs = ({ upload, formData }) => {
  // Allow string or array
  const uploadKeys = upload ? [].concat(upload) : []
  // Get only formData where keys match uploadKeys
  return includeKeys(formData, uploadKeys)
}

// Upload each file and replace the form data with the result
const uploadFileInputs = async ({ upload, formData }) => {
  const files = getUploadInputs({ upload, formData })
  if (!Object.keys(files).length) return formData

  // Upload each file asynchronously
  await Promise.all([].concat(Object.keys(files)).map(
    async (key) => {
      const file = files[key]?.item(0)
      if (file) {
        try {
          const { token } = await uploadFile(file)
          if (!token) { throw new Error() }
          files[key] = token
        } catch (er) {
          throw new Error('File upload failed')
        }
      } else {
        files[key] = null
      }
    },
  ))

  // Override formData with new uploaded data
  return { ...formData, ...files }
}

// Allows a form to trigger a GraphQL query on submit.
// query: String or function returning a GraphQL query string
// OPTIONAL:
// upload: string or array of input names which contain files to be uploaded.
// beforeSumbit: optional function which can process formData before submitting. Must return object.
// onError, onSuccess: hooks for doing work with success or error data
// redirect: navigate on success to string (or function: ({ formData, data }) => string)
// [success/processing/error]Message: allow us to set props for the toast messages.
// logQuery: log the query string and execute
// testQuery: log the query string and do not execute
const useFormQuery = ({
  query,
  testQuery,
  logQuery,
  upload,
  delay,
  queryOptions = {},
  ...rest
}, dependencies = []) => {
  const [submitQuery] = useQuery(query, { delay, ...queryOptions })
  const navigate = useNavigate()
  const {
    sendSuccess,
    sendError,
    sendProcessing,
  } = useToast()

  const onSubmit = React.useCallback(async (props = {}) => {
    const { data: formDataObj = {}, formRef, hooks = {} } = props
    const {
      queryProps,
      beforeSubmit,
      onSuccess,
      onError,
      redirect,
      successMessage,
      processingMessage,
      errorMessage,
    } = { ...rest, ...hooks }

    let formData = formDataObj

    // If inputs need to be uploaded, do that, then construct an updated formdata object
    try {
      formData = await uploadFileInputs({ upload, formData: formDataObj })
    } catch (er) {
      sendError({ message: er.message })
      return {}
    }

    formData = { ...beforeSubmit ? beforeSubmit(formData) : formData, ...queryProps }

    const processingMsg = getMessage(processingMessage, { formData })
    if (processingMsg && successMessage !== false && !testQuery) sendProcessing(processingMsg)

    if (testQuery || logQuery) {
      // eslint-disable-next-line no-console
      console.log({ query: query(formData), formData })
      if (testQuery) return {}
    }

    const { data, errors } = await submitQuery(formData)

    if (data && !errors) {
      const successMsg = getMessage(successMessage, { data, errors, formData })
      if (successMsg) sendSuccess(successMsg)
      if (onSuccess) await onSuccess({ data, formData })
      if (redirect) {
        const url = redirectUrl({ redirect, data, formData })
        if (url) navigate(url)
      }
    }

    if (errors?.length) {
      const errorFields = getErrorFields(errors)
      if (formRef && errorFields?.length) setErrors({ errors: errorFields, formRef })
      if (onError) onError(errorFields || errors)
      const errorMsg = getMessage(errorMessage, {
        data,
        errors,
        formData,
        defaults: {
          title: errorFields?.length ? 'Form Error' : 'Server Error',
          message: getFormErrorMessage(errorFields),
          replace: false,
        },
      })
      if (errorMsg) sendError(errorMsg)
    }

    return { data, errors }
  }, [submitQuery, rest?.queryProps, rest?.successMessage, ...dependencies])

  // This should be set as a form's onSubmit function to trigger the query.
  return {
    onSubmit,
  }
}

export {
  useFormQuery,
}
