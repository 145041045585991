import React from 'react'
import PropTypes from 'prop-types'
import { ScrollPanel } from '../ScrollPanel'

import {
  gapSize,
  alignItemsStyle,
  alignments,
  valignments,
  spaceToSize,
} from '../../helpers'

import { useThrottle } from '../../hooks/useThrottle'

import './grid.scss'

const sizeProp = PropTypes.oneOfType([PropTypes.number, PropTypes.string])
const getSize = (prop) => {
  if (typeof prop === 'string') return prop
  return `${prop}px`
}

const autoColumns = ({ children, auto }) => (auto ? (
  Array(React.Children.toArray(children).length).fill(typeof auto === 'string' ? auto : '1fr').join(' ')
) : null)

const gridTemplateColumns = ({
  fit,
  fill,
  auto,
  columnMin,
  columnMax,
  columns,
  templateColumns,
  children,
}) => {
  if (columns === 'auto') return { gridAutoFlow: 'column' }
  if (templateColumns) return { gridTemplateColumns: templateColumns }
  if (columns) return { gridTemplateColumns: Array(columns).fill('1fr').join(' ') }
  if (auto) return { gridTemplateColumns: autoColumns({ children, auto, columns }) }
  if (fit || fill) {
    let type = ''
    if (fit) type = 'auto-fit,'
    if (fill) type = 'auto-fill,'
    return {
      gridTemplateColumns: `repeat(${type} minmax(${getSize(columnMin)}, ${getSize(columnMax)}))`,
    }
  }
  return {}
}

const Grid = React.forwardRef((props, ref) => {
  const {
    children,
    gap,
    rowGap,
    space,
    fit,
    fill,
    auto,
    columnMin,
    columnMax,
    columns,
    templateColumns,
    valign,
    align,
    templateAreas,
    tag: Tag,
    gridMin,
    style,
    className,
    inline,
    maxHeight,
    templateRows,
    ...rest
  } = props

  const gridRef = ref || React.useRef()
  const [gridWidth, setGridWidth] = React.useState(0)

  const scaleGrid = useThrottle(() => {
    setGridWidth(gridRef.current.clientWidth)
  }, [])

  // Ensure grid width is set before render
  React.useLayoutEffect(() => {
    if (gridMin) setGridWidth(gridRef.current.clientWidth)
  }, [gridMin])

  React.useEffect(() => {
    if (gridMin) window.addEventListener('resize', scaleGrid)
    return () => window.removeEventListener('resize', scaleGrid)
  }, [gridMin])

  const gridStyle = React.useMemo(() => {
    const base = {
      ...gapSize(props),
      ...alignItemsStyle(props),
    }
    if (!gridMin || gridMin <= gridWidth) {
      return {
        gridTemplateRows: templateRows,
        gridTemplateAreas: templateAreas,
        ...base,
        ...gridTemplateColumns({
          fit,
          fill,
          auto,
          columnMin,
          columnMax,
          columns,
          templateColumns,
          children,
        }),
      }
    }
    return base
  }, [gridWidth, templateColumns, templateAreas])

  if (!children) return null

  const gridProps = {
    className: `level-grid ${className || ''}`,
    children,
    style: {
      display: inline ? 'inline-grid' : 'grid',
      position: 'relative',
      padding: space ? spaceToSize(space) : space,
      ...gridStyle,
      ...style,
      overflowY: null,
    },
    ref: gridRef,
    ...rest,
  }

  if (maxHeight) {
    return <ScrollPanel as={Tag} {...gridProps} data-max-height={maxHeight || null} />
  }

  return (
    <Tag {...gridProps} />
  )
})

Grid.propTypes = {
  align: PropTypes.oneOf(alignments),
  valign: PropTypes.oneOf(valignments),
  templateColumns: PropTypes.string,
  templateRows: PropTypes.string,
  templateAreas: PropTypes.string,
  gap: gapSize.propTypes.gap,
  rowGap: gapSize.propTypes.gap,
  space: spaceToSize.propTypes.space,
  fit: PropTypes.bool,
  fill: PropTypes.bool,
  auto: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  columnMin: sizeProp,
  columnMax: sizeProp,
  columns: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  children: PropTypes.node,
  tag: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string]),
  gridMin: PropTypes.number,
  style: PropTypes.object,
  inline: PropTypes.bool,
  className: PropTypes.string,
  maxHeight: PropTypes.bool,
}

Grid.defaultProps = {
  align: 'start',
  tag: 'div',
  columnMax: '1fr',
  columnMin: 'min-content',
  style: {},
  valign: undefined,
  columns: undefined,
  inline: undefined,
  templateAreas: undefined,
  templateColumns: undefined,
  templateRows: undefined,
  auto: undefined,
  fit: undefined,
  fill: undefined,
  gap: undefined,
  rowGap: undefined,
  space: undefined,
  children: undefined,
  gridMin: undefined,
  className: undefined,
  maxHeight: undefined,
}

export {
  Grid,
}
