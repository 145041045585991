import React from 'react'
import PropTypes from 'prop-types'

import {
  ElementWrapper,
  elementStyle,
} from './helpers'

import { EditInPlace } from './EditInPlace'
import { renderMarkdownToReact } from '../../../../helpers'

const Heading = (props) => {
  const {
    sharedSectionId,
    varPath,
    element: { config },
    mode,
  } = props

  // extract content or use placeholder text
  let textContent = `{{{ ${varPath}.text }}}`
  if (mode.includes('builder')) textContent = renderMarkdownToReact(config.text, { type: 'text' })

  const style = elementStyle(props, Heading.config)

  return (
    <ElementWrapper {...props} style={style}>
      { !sharedSectionId ? (
        <EditInPlace
          color={style.color}
          configVar="text"
          placeholder="Heading Text"
          {...props}
        >{ textContent }
        </EditInPlace>
      ) : textContent }
    </ElementWrapper>
  )
}

Heading.propTypes = {
  ...ElementWrapper.propTypes,
  varPath: PropTypes.string.isRequired,
}
Heading.defaultProps = ElementWrapper.defaultProps

Heading.type = 'Heading'

// User configurable variables
Heading.config = {
  text: null,
  color: null,
  align: null,
  padding: '0 0 0 0',
  fontSize: '24px',
  lineHeight: '110%',
  fontFamily: null,
  fontWeight: 700,
  backgroundColor: null,
}

Heading.configLayout = [
  'color',
  'backgroundColor',
  'fontSize',
  'fontWeight',
  'lineHeight',
  'fontFamily',
  '---',
  'align',
  'padding',
]

export { Heading }
