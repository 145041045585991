import React from 'react'
import { Portal } from '@reach/portal'
import { CSSTransition } from 'react-transition-group'
import { useDelayValue } from '../../hooks/useDelayValue'

import './loading-bar.scss'

const LoadingContext = React.createContext()

const useLoading = (options = {}) => {
  const { loading = null } = options
  const { start, stop, isLoading } = React.useContext(LoadingContext)

  React.useEffect(() => {
    if (loading) start()
    else stop()
    return () => stop()
  }, [loading])

  return {
    start, stop, isLoading,
  }
}

const LoadingProvider = (props) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const start = React.useCallback(() => { setIsLoading(true) }, [])
  const stop = React.useCallback(() => { setIsLoading(false) }, [])

  const value = React.useMemo(() => ({
    start, stop, isLoading,
  }), [start, stop, isLoading])

  return <LoadingContext.Provider value={value} {...props} />
}

const LoadingBar = () => {
  const { isLoading } = useLoading()
  const loading = useDelayValue(isLoading)

  return (
    <Portal>
      <div className="level-loading-bar-container">
        <CSSTransition
          in={loading}
          timeout={{
            enter: 10,
            exit: 500,
          }}
          classNames="loading"
        >
          <div className="level-loading-bar">
            <div className="level-loading-bar-amount" />
          </div>
        </CSSTransition>
      </div>
    </Portal>
  )
}

export {
  LoadingBar,
  LoadingProvider,
  useLoading,
}
