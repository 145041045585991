import {
  useDebounce,
  toParams, capitalize,
} from '@level'

import { useQuery } from './useQuery'

const verifyProviderQuery = ({ type, provider, config }) => {
  const providerType = capitalize(type)
  const allowedFrom = {
    mailProvider: 'domains emails',
    smsProvider: 'numbers',
  }

  return `provider: verify${providerType}(${type}: { 
    provider: ${provider},
    config: { ${toParams(config)} },
  }) {
    valid
    allowedFrom { ${allowedFrom[type]} }
  }`
}

const allowedFromSmsOptions = ({ allowedFrom, defaultFrom, isLoading }) => {
  const { numbers } = allowedFrom || {}

  if (isLoading) return [{ label: 'Loading…', value: defaultFrom || '' }]
  if (numbers?.length) {
    return numbers.map((number) => ({
      value: number,
      label: (numbers.length > 1 && defaultFrom === number) ? `${number} (default)` : number,
    }))
  }

  return null
}

const useVerifyProvider = () => {
  const [verify, { data, isLoading }] = useQuery(verifyProviderQuery)
  const debouncedVerify = useDebounce(verify, [], 400)

  return {
    verify,
    debouncedVerify,
    isLoading,
    ...(data?.provider || {}),
  }
}

export {
  useVerifyProvider,
  allowedFromSmsOptions,
}
