import React from 'react'
import {
  Routes,
  Route,
  Navigate,
} from 'react-router-dom'

import {
  NotFound, ClipboardProvider,
} from '@level'
import {
  useUpdateNotice, actAsUserRoute,
  ActAsUser,
} from '@app/hooks'
import { AuthProvider, AuthIndex } from './auth'
import { TeamsIndex } from './teams'
import { AccountIndex } from './account'
import { AdminIndex } from './admin'
import { Invitation } from './invitations/Invitation'
import { VerifyIdentity } from './teams/mailSettings/verified'
import { Try } from './try'
import { TemplateView } from './templates/templateView'

const AppIndex = () => {
  useUpdateNotice()

  return (
    <AuthProvider>
      <ClipboardProvider>
        <Routes>
          <Route path="/">
            <Route path={actAsUserRoute} element={<ActAsUser />} />
            <Route path="try/*" element={<Try />} />
            <Route path="account/*" element={<AccountIndex />} />
            <Route path="admin/*" element={<AdminIndex />} />
            <Route path="teams/*" element={<TeamsIndex />} />
            <Route path="template/*" element={<TemplateView />} />
            <Route path="auth/*" element={<AuthIndex />} />
            <Route path="invitation/*" element={<Invitation />} />
            <Route path="invites/*" element={<Navigate to="/account" />} />
            <Route path="verifysuccess/*" element={<VerifyIdentity success />} />
            <Route path="verifyfailure/*" element={<VerifyIdentity />} />
            <Route index element={<Navigate to="teams" />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </ClipboardProvider>
    </AuthProvider>
  )
}

export {
  AppIndex,
}
