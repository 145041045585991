import React from 'react'
import PropTypes from 'prop-types'

import { SliderInput } from '../../../../SliderInput'
import { HiddenInput } from '../../../../Input'
import { Button } from '../../../../Button'
import { useFormRef } from '../../../../../hooks/useForm'

import { Shelf } from '../../../../Shelf'
import { Column } from '../../../../Column'

// Input allows you to drag a slider to set column spacing,
// or select auto to inherit from section padding.
const ColumnSpaceInput = ({ value, name, ...rest }) => {
  const [auto, setAuto] = React.useState(value === '' || value === null)
  const { setValue } = useFormRef()

  const syncPadding = () => {
    setValue(name, '', { shouldDirty: true })
    setValue(`${name}-slider`, 0)
    setAuto(true)
  }

  const onChange = ({ target }) => {
    setValue(name, target.value, { shouldDirty: true })
    if (auto) { setAuto(false) }
  }

  return (
    <>
      <HiddenInput id="" name={name} defaultValue={value} />
      <Shelf gap={5} valign="bottom">
        <Column grow>
          <SliderInput
            step={5}
            type="range"
            name={`${name}-slider`}
            label="Column Space"
            defaultValue={auto ? 0 : value}
            displayValue={(v) => (auto ? 'auto' : `${v}px`)}
            onChange={onChange}
            {...rest}
          />
        </Column>
        <Button
          theme="menu"
          icon={{
            name: 'wand-magic-sparkles',
            color: auto ? 'blue-primary' : null,
          }}
          onClick={syncPadding}
          label="Auto (sync to padding)"
        />
      </Shelf>
    </>
  )
}

ColumnSpaceInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
}

ColumnSpaceInput.defaultProps = {
  value: '',
}

export {
  ColumnSpaceInput,
}
