import React from 'react'
import PropTypes from 'prop-types'

import { Editor } from '@level'

import { curlSnippet } from './curl'
import { jsSnippet } from './js'
import { rubySnippet } from './ruby'
import { pythonSnippet } from './python'
import { nodeSnippet } from './node'

const getSnippet = ({
  type, token, data,
}) => {
  const apiUrl = `${process.env.API_BASE_URL}/messages`

  // simplifies access to language specific snippets
  const snippet = {
    js: jsSnippet,
    node: nodeSnippet,
    ruby: rubySnippet,
    python: pythonSnippet,
    curl: curlSnippet,
  }[type]

  return snippet({
    apiUrl, token, data,
  })
}

const Snippet = ({
  data, type, token,
}) => {
  const oldToken = React.useRef(token)
  const editorText = React.useRef()

  const content = React.useMemo(() => {
    if (editorText.current && token !== oldToken.current) {
      editorText.current = editorText.current.replace(oldToken.current, token)
      oldToken.current = token
      return editorText.current
    }
    oldToken.current = token
    editorText.current = getSnippet({ type, token, data })
    return editorText.current
  }, [data, type, token])

  return (
    <Editor content={content} onChange={(c) => { editorText.current = c }} lang={type} />
  )
}

Snippet.propTypes = {
  data: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  token: PropTypes.string,
}

Snippet.defaultProps = {
  token: 'DEMO_TOKEN',
}

export { Snippet }
