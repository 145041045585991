import React from 'react'
import { Link } from '../Link'

const HeaderNavBack = (props) => (
  <Link
    className="level-header-nav-back"
    to=".."
    icon={{
      name: 'arrow-left',
      color: 'neutral-200',
    }}
    {...props}
  />
)

export {
  HeaderNavBack,
}
