import React from 'react'
import { useGraphQlQuery } from '@level'
import { useHandleErrors } from './useHandleErrors'

import { useAuthedHeaders } from './Auth'

const apiUrl = `${process.env.API_BASE_URL}/graphql`

const useQuery = (query, opts = {}) => {
  let dependencies = opts?.dependencies || [query]
  const delay = opts?.delay || 0
  const test = opts?.test || false
  const log = opts?.log || false
  const headerOptions = opts?.headers || {}

  if (Array.isArray(opts)) dependencies = opts

  const headers = {
    ...useAuthedHeaders(),
    ...headerOptions,
  }
  const handleErrors = useHandleErrors()

  // Destructure useLevelQuery so we can set fetch's auth token
  const [fetch, ...rest] = useGraphQlQuery({
    query,
    url: apiUrl,
    handleErrors,
    log,
    test,
  }, dependencies)

  // Wrap fetch with a callback
  // This refreshes the auth token with `getToken` on each execution
  // Otherwise a request may be triggered with a stale token and fail auth
  const authedFetch = React.useCallback(async (options = {}) => {
    headers.Delay = delay || null

    return fetch({
      ...options,
      headers,
    })
  }, [fetch, headers.Authorization])

  // Return authed fetch and queryState matching useLevelQuery's API
  return [authedFetch, ...rest]
}

const usePublicQuery = (query, dependencies = []) => (
  useGraphQlQuery({ query, url: apiUrl }, dependencies)
)

export {
  useQuery,
  usePublicQuery,
}
