import React from 'react'
import PropTypes from 'prop-types'

import {
  ElementWrapper,
  elementStyle,
} from './helpers'

import { EditInPlace } from './EditInPlace'
import { RoundEmailButton } from '../../../../helpers/email'
import { renderMarkdownToReact } from '../../../../helpers'

const Button = (props) => {
  const {
    sharedSectionId,
    varPath,
    element: { config },
    mode,
  } = props

  const {
    padding,
    cornerStyle,
    backgroundColor,
    buttonPadding,
    fontFamily,
    ...style
  } = elementStyle(props, Button.config)

  const parentStyle = {
    fontFamily,
    padding,
  }

  let url = `{{ ${varPath}.url }}`
  let buttonText = `{{{ ${varPath}.text }}}`

  if (mode.includes('builder')) {
    url = config.url
    buttonText = renderMarkdownToReact(config.text, { type: 'text' })
  }

  if (cornerStyle === 'rounded') {
    style.borderRadius = `${Math.round(Number.parseInt(style.fontSize || '16', 10) * 0.4)}px`
  }
  if (cornerStyle === 'round') {
    style.borderRadius = `${Math.round(Number.parseInt(style.fontSize || '16', 10) * 1.8)}px`
  }

  // Direct text editing in builder mode is tough if this is a link.
  // When a user selects text with a mouse it will try to drag the link instead.
  // Thus I'm setting it to not be a link in builder mode.
  const Tag = mode.match(/builder/) ? 'div' : 'a'

  return (
    <ElementWrapper {...props} padding={padding} style={parentStyle}>
      <RoundEmailButton
        cornerStyle={cornerStyle}
        fill={backgroundColor}
        color={style.color}
        fontSize={style.fontSize}
        mode={mode}
        href={url}
        text={buttonText || ''}
      />
      <Tag
        href={url}
        style={{
          ...Button.style,
          ...style,
          padding: buttonPadding,
          textAlign: 'center',
          backgroundColor,
        }}
      >
        { !sharedSectionId ? (
          <EditInPlace
            color={style.color}
            configVar="text"
            placeholder="Button Text"
            {...props}
          >{buttonText}
          </EditInPlace>
        ) : buttonText}
      </Tag>
    </ElementWrapper>
  )
}

Button.propTypes = {
  ...ElementWrapper.propTypes,
  varPath: PropTypes.string.isRequired,
}
Button.defaultProps = ElementWrapper.defaultProps

// Default inline styling goes here
Button.style = {
  textDecoration: 'none',
  display: 'inline-block',
  margin: '0',
  msoHide: 'all',
}

Button.type = 'Button'

// User configurable variables
Button.config = {
  text: null,
  url: null,
  color: null,
  backgroundColor: null,
  align: null,
  minWidth: '0%',
  buttonPadding: '10px 25px 10px 25px',
  padding: '0 0 0 0',
  fontSize: '16px',
  lineHeight: '110%',
  fontFamily: null,
  fontWeight: 400,
  cornerStyle: 'rounded',
}

Button.configLayout = [
  'url',
  '---',
  'color',
  'backgroundColor',
  '---',
  'fontSize',
  'lineHeight',
  'fontWeight',
  'fontFamily',
  '---',
  'cornerStyle',
  'minWidth',
  'buttonPadding',
  '---',
  'align',
  'padding',
]

const cornerStyles = [
  { value: 'square' },
  { value: 'rounded' },
  { value: 'round' },
]

export {
  Button,
  cornerStyles,
}
