import React from 'react'
import { Stack, Button, useToast } from '@level'
import { useWebSocket } from './useWebSocket'

const useUpdateNotice = () => {
  const { sendSystemUpdate } = useToast()
  const visible = React.useRef()

  const handler = React.useCallback((msg) => {
    const { message } = msg.data
    const systemMessage = (
      <Stack gap={6}>
        <div>{ message }</div>
        <Button onClick={() => { window.location.reload(true) }}>Update Now </Button>
      </Stack>
    )
    if (!visible.current) {
      sendSystemUpdate({
        message: systemMessage,
        onDismiss: () => { visible.current = false },
      })
      visible.current = true
    }
  }, [])

  useWebSocket({ channel: 'notices', event: 'deploy', handler })
}

export {
  useUpdateNotice,
}
