import React from 'react'
import PropTypes from 'prop-types'

import { typeRequiredUnless } from '../../helpers/react'
import { Text } from '../Text'
import './pill.scss'

const Pill = ({
  text, uppercase, children, ...props
}) => (
  <Text
    className="level-pill"
    data-uppercase={uppercase || null}
    {...props}
  >{text || children }
  </Text>
)

Pill.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: typeRequiredUnless({ type: PropTypes.node, present: 'text' }),
  theme: PropTypes.string,
  uppercase: PropTypes.bool,
}

Pill.defaultProps = {
  text: undefined,
  children: undefined,
  theme: 'default',
  uppercase: false,
}

export {
  Pill,
}

export * from './PopoverPill'
