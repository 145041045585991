import React from 'react'
import PropTypes from 'prop-types'
import { Link } from '../../../Link'

const SectionLink = ({ id, ...props }) => (
  <Link
    label="Edit section"
    className="level-template-section-link"
    to={`./section/${id}`}
    replace
    {...props}
  />
)

SectionLink.propTypes = {
  id: PropTypes.string.isRequired,
}

export {
  SectionLink,
}
