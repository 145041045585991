import React from 'react'
import PropTypes from 'prop-types'
import { flexRender } from '@tanstack/react-table'
import { Loading } from '../Loading'

const TableRow = ({
  row, index, children, nextSelected, selectRow, ...rest
}) => (
  <tr
    data-selected={row.getIsSelected()}
    data-row-theme={row.original.rowTheme || 'default'}
    data-is-selectable={row.getCanSelect()}
    data-next-selected={nextSelected}
    // Allow click if it did not occur within a button or link element
    onClick={row.getCanSelect() ? ({ target }) => { if (!target.closest('button, a, [role=menuitem]')) selectRow(row) } : null}
    data-row-index={index}
    data-row-id={row.id}
    key={row.id}
    {...rest}
  >
    { children || row.getVisibleCells().map((cell) => (
      <td
        key={cell.id}
        data-cell-id={cell.column.id}
        style={{ textAlign: cell.column.columnDef.align, ...(cell.column.columnDef || {}) }}
      >
        { cell.isPlaceholder ? null : flexRender(cell.column.columnDef.cell, cell.getContext())}
      </td>
    )) }
  </tr>
)

TableRow.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.any,
    getCanSelect: PropTypes.func,
    toggleSelected: PropTypes.func,
    getVisibleCells: PropTypes.func,
    getIsSelected: PropTypes.func,
    original: PropTypes.object,
  }).isRequired,
  selectRow: PropTypes.func.isRequired,
  nextSelected: PropTypes.bool,
  index: PropTypes.any,
  children: PropTypes.node,
}

TableRow.defaultProps = {
  index: null,
  children: null,
  nextSelected: null,
}

const LoadingRow = ({ table }) => {
  const { virtualizer, getRowModel } = table
  if (!virtualizer) return null
  if (!virtualizer.hasMore) return null

  const virtualRows = virtualizer.getVirtualItems()
  const lastRowIndex = virtualRows?.[virtualRows.length - 1]?.index
  const { rows } = getRowModel()
  const rowCount = rows.length - 1

  if (rowCount && rowCount === lastRowIndex) {
    const columns = rows[0].getVisibleCells().length
    return <tr><td colSpan={columns} style={{ textAlign: 'center' }}><Loading text="Loading more…" /></td></tr>
  }

  return null
}

LoadingRow.propTypes = {
  table: PropTypes.shape({
    virtualizer: PropTypes.object,
    getRowModel: PropTypes.func,
  }).isRequired,
}

export {
  TableRow,
  LoadingRow,
}
