import React from 'react'
import PropTypes from 'prop-types'

import { spaceToSize } from '../../helpers/space'

const CardHeader = ({ space, style: styleProp, children }) => {
  const style = { ...styleProp, padding: spaceToSize(space) }
  return (
    <div className="level-card-header" style={style}>{ children }</div>
  )
}

CardHeader.propTypes = {
  style: PropTypes.object,
  space: spaceToSize.propTypes.space,
  children: PropTypes.node.isRequired,
}

CardHeader.defaultProps = {
  space: 0,
  style: {},
}

export {
  CardHeader,
}
