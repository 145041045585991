import React from 'react'
import PropTypes from 'prop-types'
import { Outlet } from 'react-router-dom'

import './drawer.scss'

const Drawer = ({ children }) => (
  <div className="level-layout-drawer">
    { children || <Outlet /> }
  </div>
)

Drawer.propTypes = {
  children: PropTypes.node,
}

Drawer.defaultProps = {
  children: null,
}

export {
  Drawer,
}
