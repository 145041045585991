import React from 'react'
import {
  Routes,
  Route,
  Navigate,
} from 'react-router-dom'

import { NotFound } from '@level'
import { useProfile, useTeam } from '@app/hooks'
import { ProjectsIndex } from '../projects/index'
import { TeamDashboard } from './dashboard'
import { TeamSettings } from './settings'
import { TeamLayout } from './sidebar'
import { AccountLayout } from '../account/layout'
import { Authed } from '../auth'
import { MailSettings } from './mailSettings'
import { Monitoring } from './monitoring'
import { TeamDisabled } from './disabled'

const Index = () => {
  const { Teams } = useProfile()

  if (Teams.length === 0) {
    return <Navigate to="/account/new" />
  }
  if (Teams.length === 1) {
    return <Navigate to={`./${Teams[0].slug}`} />
  }
  return <AccountLayout />
}

const TeamRoutes = () => {
  const { Team: { disabled } } = useTeam()

  // If team is disabled all sub paths the settings pages
  return disabled ? (
    <Routes>
      <Route path="*" element={<TeamSettings />} />
    </Routes>
  ) : (
    <Routes>
      <Route index element={<Navigate to="./dashboard" replace />} />
      <Route path="dashboard" element={<TeamDashboard />} />
      <Route path="mail-settings" element={<MailSettings />} />
      <Route path="monitoring/*" element={<Monitoring />} />
      <Route path="projects/*" element={<ProjectsIndex />} />
      <Route path="settings/*" element={<TeamSettings />} />
      <Route path="not-found" element={<NotFound title="Project Not Found" />} />
      <Route path="disabled" element={<TeamDisabled />} />
      <Route path="*" element={<Navigate to=".." />} />
    </Routes>
  )
}

const TeamsIndex = () => (
  <Authed>
    <Routes>
      <Route index element={<Index />} />
      <Route path=":teamId/*" element={<TeamLayout><TeamRoutes /></TeamLayout>} />
    </Routes>
  </Authed>
)

export {
  TeamsIndex,
}
