import React from 'react'

import { useRadioFilter } from '../../hooks'
import { Form } from '../Form'
import { RadioInput } from '../Input'
import { Stack } from '../Stack'
import { filterPropTypes } from './propTypes'

const RadioFilter = (options) => {
  const { inputs, onChange } = useRadioFilter(options)
  return (
    <Form onChange={onChange} className="level-filter-form">
      <Stack gap={5}>
        {inputs.map(({ key, ...rest }) => (
          <RadioInput key={key} {...rest} />
        ))}
      </Stack>
    </Form>
  )
}

RadioFilter.propTypes = {
  ...filterPropTypes,
}

export { RadioFilter }
