import React from 'react'
import PropTypes from 'prop-types'

import { useQuery, useTeam, useTeamChannel } from '@app/hooks'

import {
  Card, Stack, Text, Button, Shelf,
  Icon, Grid, useDialog, Divider, Link,
  displayNumber, toParams, sortBy,
} from '@level'

import './plan.scss'

const ChangePlanButton = ({ plan, currentPlan, ...props }) => (
  <Button text={currentPlan.id === 'developer' ? 'Upgrade Plan' : 'Change Plan'} theme="primary" {...props} />
)

ChangePlanButton.propTypes = {
  plan: PropTypes.object.isRequired,
  currentPlan: PropTypes.object.isRequired,
}

const getCheckoutSessionQuery = ({ Team: { slug: team }, stripeProductId }) => `mutation {
  session: createStripeCheckoutSession(${toParams({ team, stripeProductId })}) { url }
}`

const CheckoutButton = ({ plan, currentPlan }) => {
  const { Team } = useTeam()
  const [getCheckoutSession] = useQuery(getCheckoutSessionQuery)

  const onClick = async () => {
    const { data } = await getCheckoutSession({ Team, stripeProductId: plan.stripeProductId })
    if (data?.session.url) {
      window.location.href = data.session.url
    }
  }

  return <ChangePlanButton plan={plan} currentPlan={currentPlan} onClick={onClick} grow />
}

CheckoutButton.propTypes = {
  plan: PropTypes.object.isRequired,
  currentPlan: PropTypes.object.isRequired,
}

const cancelSubscriptionQuery = ({ Team: { slug: team } }) => `mutation {
  team: cancelStripeSubscription(${toParams({ team })}) { slug, name }
}`

const CancelButton = () => {
  const { Team, refresh } = useTeam()
  const [cancelSubscription] = useQuery(cancelSubscriptionQuery)
  const sendDialog = useDialog()

  const clickCancel = () => sendDialog({
    onConfirm: async () => {
      const { data } = await cancelSubscription({ Team })
      if (data?.team) {
        refresh()
      }
    },
    title: 'Downgrade Your Plan?',
    content: 'Are you sure you want to downgrade?',
    confirmText: 'Downgrade Plan',
    cancelText: 'Nevermind',
  })

  return <Button size={3} text="Downgrade Plan" onClick={clickCancel} grow />
}

const PlanButton = ({ plan }) => {
  const { Team: { plan: currentPlan } } = useTeam()

  // Current Plan
  if (plan.id === currentPlan.id) return null

  // Paid plan via Stripe
  if (plan.stripeProductId) return <CheckoutButton plan={plan} currentPlan={currentPlan} />

  // Not a paid plan
  return <CancelButton />
}

PlanButton.propTypes = {
  plan: PropTypes.object.isRequired,
}

const PricingTiers = ({ tiers }) => {
  if (!tiers) return null

  // turn object into array
  const tierArray = Object.keys(tiers).map((messages) => ({
    messagesStart: parseInt(messages, 10),
    price: tiers[messages],
  }))

  for (let i = 0; i < tierArray.length; i += 1) {
    const { messagesStart } = tierArray[i]
    const messagesEnd = tierArray[i + 1] ? tierArray[i + 1].messagesStart : '∞'
    tierArray[i].messagesStart = messagesStart + 1
    tierArray[i].messagesEnd = messagesEnd
  }

  return (
    <Stack gap={8}>
      <Stack gap={4}>
        <Text tag="h4">Send more, save more</Text>
        <Text>Our Pro Plan offers volume discounts, so the more you send, the more you save.</Text>
      </Stack>
      <table width="100%" data-theme="simple" data-spacing="small">
        <thead className="level-table-head">
          <tr>
            <th>Volume</th>
            <th>Per Message</th>
          </tr>
        </thead>
        <tbody className="level-table-body">
          { tierArray.map(({ messagesStart, messagesEnd, price }) => (
            <tr key={messagesStart}>
              <td><Text><strong>{displayNumber(messagesStart)} </strong>-<strong> {typeof messagesEnd === 'string' ? messagesEnd : displayNumber(messagesEnd)}</strong></Text></td>
              <td><Text>{price === 0 ? 'Included' : `$${price}`}</Text></td>
            </tr>
          ))}
        </tbody>
      </table>
    </Stack>
  )
}

PricingTiers.propTypes = {
  tiers: PropTypes.object.isRequired,
}

const Feature = (props) => (
  <Text icon={{ name: 'check', color: 'green-600', size: 3 }} iconGap="6" {...props} />
)

const ProInfo = ({ plan }) => {
  const {
    id, basePrice, includedMessages,
    additionalMessages, pricingTiers, logRetention,
  } = plan

  return (
    <React.Fragment>
      <Divider flush={9} />
      <Grid templateColumns="9fr 5fr" gap={9} gridMin={600} valign={id === 'friend' ? 'center' : null}>
        <Stack>
          <Card className="price-card" space={9}>
            <Stack gap={9}>
              <Stack gap={2}>
                <Text className="price-small">Starting at</Text>
                <Text className="price-cost">${basePrice}{basePrice !== 0 ? <span className="price-denominator">/per month</span> : null}</Text>
              </Stack>
              <Stack gap={6}>
                <Text className="price-small">What’s included?</Text>
                <Shelf gap={12}>
                  <Stack gap={4}>
                    <Feature>{!additionalMessages ? 'Up to' : null } <strong>{displayNumber(includedMessages)}</strong> messages {additionalMessages ? 'included' : null }</Feature>
                    <Feature><strong>{logRetention}</strong> of message logs</Feature>
                    <Feature>Email</Feature>
                    <Feature>SMS</Feature>
                  </Stack>
                  <Stack gap={4}>
                    <Feature>Unlimited templates</Feature>
                    <Feature>Dedicated IPs available</Feature>
                    <Feature>Customer support</Feature>
                    { id === 'friend' ? (
                      <Feature>Cheers and fist bumps</Feature>
                    ) : (
                      <Feature>Removal of Enveloop Footer</Feature>
                    )}
                  </Stack>
                </Shelf>
              </Stack>
            </Stack>
          </Card>
        </Stack>
        { id === 'friend'
          ? (
            <Shelf align="center">
              <Icon name="frienvelooper-badge" size={10} style={{ width: '50%', height: 'auto' }} />
            </Shelf>
          )
          : null}
        { pricingTiers ? <PricingTiers tiers={pricingTiers} /> : null}
      </Grid>
    </React.Fragment>
  )
}

ProInfo.propTypes = {
  plan: PropTypes.object.isRequired,
}

const PlanCard = ({
  text,
  plan,
  currentPlan,
  children,
}) => {
  const { id, name } = plan
  const subdued = currentPlan.id !== 'developer' && plan.id === 'developer'
  const isCurrent = currentPlan.id === plan.id

  return (
    <Card className={subdued ? 'pricing-card-subdued' : null} space={9}>
      <Stack gap={9}>
        <Shelf align="split" gap={8} valign="center" nowrap>
          <Stack gap={4}>
            <Shelf gap={4} valign="center">
              <Text tag={subdued ? 'h5' : 'h3'}>{name}</Text>
              { id === 'pro' && currentPlan.id === 'developer' ? <span><Text className="pricing-card-badge">Recommended</Text></span> : null }
              { isCurrent ? <Text style={{ alignSelf: 'end' }} color="neutral-500" size={1}>(current plan)</Text> : null }
              { subdued ? '—' : null }
              { subdued ? text : null }
            </Shelf>
            { subdued ? null : text }
          </Stack>
          { id !== 'friend' ? (
            <span>
              <PlanButton
                plan={plan}
                style={{ whiteSpace: 'nowrap' }}
              />
            </span>
          ) : null}
        </Shelf>
        { children }
      </Stack>
    </Card>
  )
}

PlanCard.propTypes = {
  text: PropTypes.node.isRequired,
  plan: PropTypes.object.isRequired,
  currentPlan: PropTypes.object.isRequired,
  children: PropTypes.node,
}

PlanCard.defaultProps = {
  children: undefined,
}

const Plan = (props) => {
  const { plan: { id }, isCurrent } = props
  if (id === 'developer') {
    return (
      <PlanCard
        text={(
          isCurrent ? (
            <Text>
              You are currently on our free developer plan which includes up
              to <strong>100 emails</strong> per month.
              Upgrade to get the most out of Enveloop including SMS!
            </Text>

          ) : (
            <Text>
              Our free developer plan includes up to <strong>100 emails</strong> per month.
            </Text>
          )
        )}
        {...props}
      />
    )
  }
  if (id === 'pro') {
    return (
      <PlanCard
        text={
          isCurrent
            ? <Text><strong>Way to go pro!</strong> You have access to all Enveloop features.</Text>
            : <Text>Get access to all Enveloop features and easily scale as you grow.</Text>
        }
        {...props}
      >
        <ProInfo {...props} />
      </PlanCard>
    )
  }
  if (id === 'friend') {
    return (
      <PlanCard text={<Text>Thanks for being amazing!</Text>} {...props}>
        <ProInfo {...props} />
      </PlanCard>
    )
  }
  return null
}

Plan.propTypes = {
  plan: PropTypes.object.isRequired,
  isCurrent: PropTypes.bool.isRequired,
}

const plansQuery = `query { 
  plans { id name public logRetention stripeProductId watermark includedMessages basePrice additionalMessages pricingTiers allowSms } 
}`

const TeamPlan = () => {
  const { Team: { plan: currentPlan }, refresh: refreshTeam } = useTeam()
  useTeamChannel({ event: 'planUpdated', handler: refreshTeam })

  const [getPlans, { data }] = useQuery(plansQuery)
  const plans = data?.plans
    ? data.plans
      .filter((plan) => plan.public || plan.id === currentPlan.id)
      .sort(sortBy('includedMessages'))
    : null

  React.useEffect(() => {
    getPlans()
  }, [currentPlan.id])

  if (!plans) return null

  if (currentPlan.id === 'friend') {
    return (
      <Plan
        plan={currentPlan}
        currentPlan={currentPlan}
        isCurrent
      />
    )
  }

  return (
    <Stack gap={6}>
      <Stack gap={6} style={currentPlan.id !== 'developer' ? { flexDirection: 'column-reverse' } : null}>
        { plans.map((plan) => (
          <Plan
            currentPlan={currentPlan}
            key={plan.id}
            plan={plan.id === currentPlan.id ? { ...plan, currentPlan } : plan}
            isCurrent={plan.id === currentPlan.id}
          />
        ))}
      </Stack>
      <Text size={0} style={{ padding: '10px 0 0 10px' }}>Looking for a custom plan? <Link to="mailto:support@enveloop.com">Reach out to us</Link> and we can help!</Text>
    </Stack>
  )
}

export {
  TeamPlan,
}
