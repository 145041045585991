import React from 'react'
import PropTypes from 'prop-types'

import { NavList } from '../NavList'
import { Nav } from '../Nav'
import { useMaxSize } from '../../hooks/useMaxSize'

import './sidebar.scss'

const Sidebar = ({
  navItems, header, footer, children,
}) => {
  const { contentRef, height, footerRef } = useMaxSize()
  return (
    <div className="level-sidebar">
      { header ? (
        <div className="level-sidebar-header">
          { header }
        </div>
      ) : null }
      <div className="level-sidebar-nav" style={{ height, overflowY: 'auto' }} ref={contentRef}>
        { navItems ? (
          <Nav>
            <NavList items={navItems} />
          </Nav>
        ) : null }
        { children }
      </div>
      { footer ? (
        <div className="level-sidebar-footer" ref={footerRef}>
          { footer }
        </div>
      ) : null }
    </div>
  )
}

Sidebar.propTypes = {
  navItems: PropTypes.array,
  children: PropTypes.node,
  header: PropTypes.node,
  footer: PropTypes.node,
}

Sidebar.defaultProps = {
  navItems: null,
  children: null,
  header: null,
  footer: null,
}

export { Sidebar }
