import React from 'react'
import PropTypes from 'prop-types'
import { EmailTemplate, getEmailTemplateVars } from './Email'
import { SMSTemplate, getSmsTemplateVars } from './SMS'

const Template = (props) => {
  const { type } = props
  if (type === 'email') return <EmailTemplate {...props} />
  if (type === 'sms') return <SMSTemplate {...props} />
  return null
}

Template.propTypes = {
  type: PropTypes.oneOf(['email', 'sms']).isRequired,
  mode: PropTypes.oneOf(['preview', 'preview-html', 'builder', 'section-builder', 'render']),
}

Template.defaultProps = {
  mode: 'preview',
}

Template.getMustacheTypes = ({ template, ...rest }) => {
  if (template.type === 'email') {
    return EmailTemplate.getMustacheTypes({ template, ...rest })
  }
  return {}
}

Template.getTemplateVars = ({ template, ...rest }) => {
  if (template.type === 'email') {
    return getEmailTemplateVars({ template, ...rest })
  }
  return getSmsTemplateVars({ template, ...rest })
}

export {
  Template,
}
