import React from 'react'
import PropTypes from 'prop-types'
import { ButtonShelf } from '../ButtonShelf'
import { Button } from '../Button'
import { Text } from '../Text'
import { Shelf } from '../Shelf'

import { timeAgoOrDate } from '../../helpers/date'
import { useLiveDate } from '../../hooks/useLiveDate'
import { useNetworkStatus } from '../../hooks/useNetworkStatus'

const PublishButtons = ({
  publish, revert, published, publishedAt,
}) => {
  const date = useLiveDate({ date: publishedAt, format: timeAgoOrDate })
  const { online } = useNetworkStatus()
  return (
    <ButtonShelf>
      <Button text="Publish" theme={published ? 'default' : 'primary-green'} onClick={publish} disabled={published || !online} />
      <Text color="neutral-400" theme="note">
        <Shelf gap={3} nowrap>
          { publishedAt ? `published: ${date}` : null }
          { publishedAt && !published ? ' – ' : null }
          { publishedAt && !published ? (
            <Button text="Revert Changes?" theme="wrapper-link" onClick={revert} disabled={!online} />
          ) : null }
        </Shelf>
      </Text>
    </ButtonShelf>
  )
}

PublishButtons.propTypes = {
  publish: PropTypes.func,
  revert: PropTypes.func,
  published: PropTypes.bool,
  publishedAt: PropTypes.string,
}

PublishButtons.defaultProps = {
  publish: undefined,
  revert: undefined,
  published: undefined,
  publishedAt: undefined,
}

export {
  PublishButtons,
}
