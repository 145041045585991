import React from 'react'
import PropTypes from 'prop-types'
import { Routes, Route } from 'react-router-dom'

import { Block } from '../../../../Block'
import { Divider } from '../../../../Divider'
import { Text } from '../../../../Text'
import { Grid } from '../../../../Grid'
import { Stack } from '../../../../Stack'
import { Input } from '../../../../Input'
import { RadioPanel } from '../../../../RadioPanel'
import { Form } from '../../../../Form'
import { Card } from '../../../../Card'

import { excludeKeys } from '../../../../../helpers/object'

import { useSection } from '../helpers'
import { ThemeConfig, ConfigureTheme } from '../theme'
import { useTemplateConfig } from '../useTemplateConfig'
import {
  configDefaults, configInputLayout,
  sectionTypes,
} from '../defaults'

import { ConfigForm } from '../Form'

const sectionTypeLabels = {
  normal: 'None',
  iterable: 'Iterable',
  conditional: 'Conditional',
}

const SectionType = ({ section, updateSection }) => {
  const currentType = section.variables.config.type
  const onChangeType = ({ type, variable }) => {
    // The input passes back proportions separated by columns.
    // `100%` is a single column. `50%:50%` is two columns, `33%:34%:33%` is three columns, etc
    // By splitting on the colon we can get the widths, and the number of columns.
    updateSection({ section, update: { type, variable } })
  }
  const types = React.useMemo(() => (
    sectionTypes.map((o) => ({ value: o, label: sectionTypeLabels[o] }))
  ), [])

  const defaultVariable = React.useMemo(() => (
    (section.variables.config.variableStart || '').replace(/[}{#]/g, '')
  ), [])
  const desc = (() => {
    if (currentType === 'iterable') return 'This section repeats for each item in this variable.'
    if (currentType === 'conditional') return 'Render this section if this variable is present.'
    return ''
  })()

  return (
    <Form onChangeInput={onChangeType}>
      <Stack gap={6}>
        <RadioPanel label="Section Data" name="type" defaultValue={currentType} items={types} />
        { section.variables.config.type !== 'normal' ? (
          <Card space={5}>
            <Input
              name="variable"
              defaultValue={defaultVariable}
              label="Section Variable"
              descriptionAfter={desc}
            />
          </Card>
        ) : null }
      </Stack>
    </Form>
  )
}

const SectionColumns = ({ section, updateSection }) => {
  const { id, columns } = section.variables
  const { length: columnCount } = columns
  const columnLayout = columns.map(({ config }) => (config.width || `${100.0 / columnCount}%`)).join(':')
  const columnConfig = { columnLayout }

  const onChangeColumns = (config) => {
    updateSection({ section, update: { columnLayout: config.columnLayout } })
  }

  return (
    <ConfigForm
      id={`${id}-columns`}
      config={columnConfig}
      onChange={onChangeColumns}
      reset={id}
      theme={section.variables._theme}
    />
  )
}

SectionColumns.propTypes = {
  updateSection: PropTypes.func.isRequired,
  section: PropTypes.object.isRequired,
}

const SectionConfigForm = ({ section, sharedSection, updateSection }) => {
  const onConfigChange = (config) => updateSection({ section, update: { config } })
  const updateTheme = ({ _theme, localOnly }) => {
    updateSection({ section, update: { _theme }, localOnly })
  }

  const { config } = section.variables
  let sectionConfig = { ...configDefaults.section, ...config }
  if (section.variables.columns.length < 2) {
    sectionConfig = excludeKeys(sectionConfig, ['valign', 'columnSpace'])
  }
  const configLayout = configInputLayout('section', section)
  return (
    <Routes>
      <Route
        index
        element={(
          <>
            { sharedSection ? (
              <>
                <ThemeConfig
                  config={section}
                  theme={section.variables._theme}
                />
                <Divider />
              </>
            ) : null }
            <Stack gap={6} space={[7, 7, 0, 7]}>
              <SectionType section={section} updateSection={updateSection} />
              <SectionColumns section={section} updateSection={updateSection} />
            </Stack>
            <ConfigForm
              id={`${section.variables.id}-style`}
              reset={section.variables.id}
              config={sectionConfig}
              onChange={onConfigChange}
              configLayout={configLayout}
              theme={section.variables._theme}
              sharedSection={sharedSection ? section : null}
              space={7}
            />
          </>
        )}
      />
      <Route
        path="theme"
        element={(
          <ConfigureTheme
            config={section}
            theme={sharedSection ? section.variables._theme : null}
            onSubmit={updateTheme}
          />
        )}
      />
    </Routes>
  )
}

SectionConfigForm.propTypes = {
  section: PropTypes.shape({
    variables: PropTypes.object.isRequired,
  }).isRequired,
  updateSection: PropTypes.func.isRequired,
  sectionConfig: PropTypes.object,
}

SectionConfigForm.defaultProps = {
  sectionConfig: {},
}

const SectionConfig = () => {
  const section = useSection()
  const { updateSection } = useTemplateConfig()

  return (
    <Grid templateRows="min-content min-content min-content min-content auto" align="normal" maxHeight>
      <Block space={7}>
        <Text tag="h3">Section</Text>
      </Block>
      <Divider />
      <SectionConfigForm
        section={section}
        updateSection={updateSection}
      />
    </Grid>
  )
}

export {
  SectionConfig,
  SectionConfigForm,
}
