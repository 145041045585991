import React from 'react'
import PropTypes from 'prop-types'

import { useLocalStorage } from 'beautiful-react-hooks'
import { tokenSessionRemaining, tokenAge } from '@level'
import { useQuery, AuthContext, useAuth } from '@app/hooks'

const AuthManager = ({ children }) => {
  const {
    token, setToken, signOut, sessionActive, sessionAge,
  } = useAuth()
  const [refresh, { data, errors }] = useQuery('mutation { token: refreshToken { jwt } }', { token })

  React.useEffect(() => {
    if (data?.token) window.setTimeout(() => setToken(data.token.jwt), 100)
  }, [data?.token])

  React.useEffect(() => {
    let handle
    if (sessionActive) {
      // Set a timeout to prevent it from expiring during an active session
      handle = window.setTimeout(() => refresh(), sessionActive / 2)
    } else if (token) signOut()

    return () => {
      window.clearTimeout(handle)
    }
  }, [sessionActive])

  // If token cannot be refreshed
  React.useEffect(() => { if (errors) signOut() }, [errors])

  // If a session is longer than 2 minutes, refresh a token when page loads to keep token fresh.
  React.useEffect(() => {
    if (sessionActive && 120 < sessionAge / 1000) {
      refresh()
    }
  }, [])

  return children
}

// Sets/gets auth token with local storage
// Provides verified token
const AuthProvider = ({ children }) => {
  const [token, setToken] = useLocalStorage('enveloop-auth', '')
  const [asUser, setAsUser] = useLocalStorage('as-user')
  const sessionActive = token ? tokenSessionRemaining(token) : null
  const sessionAge = token ? tokenAge(token) : null

  const signOut = React.useCallback(() => {
    setToken('')
  })

  const value = React.useMemo(() => ({
    token,
    setToken,
    signOut,
    sessionActive,
    sessionAge,
    asUser,
    setAsUser,
  }), [token, asUser])

  return (
    <AuthContext.Provider value={value}>
      <AuthManager>
        { children }
      </AuthManager>
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export {
  useAuth,
  AuthProvider,
}
